export class KeyWord {
    public static profileImages = [
        '../assets/images/profile-img.png', '../assets/images/profile-img-2.png',
        '../assets/images/profile-img-3.png', '../assets/images/profile-img-4.png',
        '../assets/images/profile-img-5.png'
    ];
    public static flags = [
        '../assets/images/australia.png', '../assets/images/bahamas.png',
        '../assets/images/bolivia.png', '../assets/images/comoros.png',
        '../assets/images/china.png', '../assets/images/ethiopia.png',
        '../assets/images/greece.png', '../assets/images/italy.png',
        '../assets/images/kuwait.png', '../assets/images/uk.png'
    ];
    public static countries = ['Australia', 'Bahamas', 'Bolivia', 'Comoros',
        'China', 'Ethiopia', 'Greece', 'Italy', 'Kuwait', 'Uk'];
    public static deviceType = ['Mobile', 'Desktop'];
    public static keywords = ['keto diet', 'cardamom', 'lemon verbana', 'Rock', 'Paper', 'Scissors'];
    public static editorAuditCountry = [

        { "country": "United States", "code": ":us" },
        { "country": "New York, US", "code": "New York:us" },
        { "country": "United Kingdom", "code": ":gb" },
        { "country": "London, UK", "code": "London:gb" },
        { "country": "India", "code": ":in" },
        { "country": "Mumbai, IN", "code": "Mumbai:in" },
        { "country": "Åland Islands", "code": ":ax" },
        { "country": "Albania", "code": ":al" },
        { "country": "Algeria", "code": ":dz" },
        { "country": "American Samoa", "code": ":as" },
        { "country": "Andorra", "code": ":ad" },
        { "country": "Angola", "code": ":ao" },
        { "country": "Anguilla", "code": ":ai" },
        { "country": "Antarctica", "code": ":aq" },
        { "country": "Antigua & Barbuda", "code": ":ag" },
        { "country": "Argentina", "code": ":ar" },
        { "country": "Armenia", "code": ":am" },
        { "country": "Aruba", "code": ":aw" },
        { "country": "Ascension Island", "code": ":ac" },
        { "country": "Australia", "code": ":au" },
        { "country": "Sydney, AU", "code": "Sydney:au" },
        { "country": "Gold Cost, AU", "code": "Gold Cost:au" },
        { "country": "Melbourne, AU", "code": "Melbourne:au" },
        { "country": "Newcastle-Maitland, AU", "code": "Newcastle-Maitland:au" },
        { "country": "Canberra-Queanbeyan, AU", "code": "Canberra-Queanbeyan:au" },
        { "country": "Brisbane, AU", "code": "Brisbane:au" },
        { "country": "Central Coast, AU", "code": "Central Coast:au" },
        { "country": "Sunshine Coast, AU", "code": "Sunshine Coast:au" },
        { "country": "Wollongong, AU", "code": "Wollongong:au" },
        { "country": "Hobart, AU", "code": "Hobart:au" },
        { "country": "Perth, AU", "code": "Perth:au" },
        { "country": "Adelaide, AU", "code": "Adelaide:au" },
        { "country": "Geelong, AU", "code": "Geelong:au" },
        { "country": "Townsville, AU", "code": "Townsville:au" },
        { "country": "Cairns, AU", "code": "Cairns:au" },
        { "country": "Darwin, AU", "code": "Darwin:au" },
        { "country": "Toowoomba, AU", "code": "Toowoomba:au" },
        { "country": "Austria", "code": ":at" },
        { "country": "Azerbaijan", "code": ":az" },
        { "country": "Bahamas", "code": ":bs" },
        { "country": "Bahrain", "code": ":bh" },
        { "country": "Bangladesh", "code": ":bd" },
        { "country": "Barbados", "code": ":bb" },
        { "country": "Belarus", "code": ":by" },
        { "country": "Belgium", "code": ":be" },
        { "country": "Belize", "code": ":bz" },
        { "country": "Benin", "code": ":bj" },
        { "country": "Bermuda", "code": ":bm" },
        { "country": "Bhutan", "code": ":bt" },
        { "country": "Bolivia", "code": ":bo" },
        { "country": "Bosnia & Herzegovina", "code": ":ba" },
        { "country": "Botswana", "code": ":bw" },
        { "country": "Bouvet Island", "code": ":bv" },
        { "country": "Brazil", "code": ":br" },
        { "country": "São Paulo, BR", "code": "São Paulo:br" },
        { "country": "Rio de Janeiro, BR", "code": "Rio de Janeiro:br" },
        { "country": "Salvador, BR", "code": "Salvador:br" },
        { "country": "Brasília, BR", "code": "Brasília:br" },
        { "country": "Fortaleza, BR", "code": "Fortaleza:br" },
        { "country": "Belo Horizonte, BR", "code": "Belo Horizonte:br" },
        { "country": "Manaus, BR", "code": "Manaus:br" },
        { "country": "Curitiba, BR", "code": "Curitiba:br" },
        { "country": "Recife, BR", "code": "Recife:br" },
        { "country": "Porto Alegre, BR", "code": "Porto Alegre:br" },
        { "country": "Belém, BR", "code": "Belém:br" },
        { "country": "Goiânia, BR", "code": "Goiânia:br" },
        { "country": "Guarulhos, BR", "code": "Guarulhos:br" },
        { "country": "Campinas, BR", "code": "Campinas:br" },
        { "country": "São Luís, BR", "code": "São Luís:br" },
        { "country": "São Gonçalo, BR", "code": "São Gonçalo:br" },
        { "country": "Maceio, BR", "code": "Maceio:br" },
        { "country": "Duque de Caxias, BR", "code": "Duque de Caxias:br" },
        { "country": "Teresina, BR", "code": "Teresina:br" },
        { "country": "Natal, BR", "code": "Natal:br" },
        { "country": "Nova Iguaçu, BR", "code": "Nova Iguaçu:br" },
        { "country": "Campo Grande, BR", "code": "Campo Grande:br" },
        { "country": "São Bernardo do Campo, BR", "code": "São Bernardo do Campo:br" },
        { "country": "João Pessoa, BR", "code": "João Pessoa:br" },
        { "country": "Santo André, BR", "code": "Santo André:br" },
        { "country": "Osasco, BR", "code": "Osasco:br" },
        { "country": "Jaboatão dos Guararapes, BR", "code": "Jaboatão dos Guararapes:br" },
        { "country": "São José dos Campos, BR", "code": "São José dos Campos:br" },
        { "country": "Ribeirão Preto, BR", "code": "Ribeirão Preto:br" },
        { "country": "Uberlândia, BR", "code": "Uberlândia:br" },
        { "country": "Contagem, BR", "code": "Contagem:br" },
        { "country": "Sorocaba, BR", "code": "Sorocaba:br" },
        { "country": "Aracaju, BR", "code": "Aracaju:br" },
        { "country": "Feira de Santana, BR", "code": "Feira de Santana:br" },
        { "country": "Cuiaba, BR", "code": "Cuiaba:br" },
        { "country": "Juiz de Fora, BR", "code": "Juiz de Fora:br" },
        { "country": "Joinville, BR", "code": "Joinville:br" },
        { "country": "Londrina, BR", "code": "Londrina:br" },
        { "country": "Niteroi, BR", "code": "Niteroi:br" },
        { "country": "Ananindeua, BR", "code": "Ananindeua:br" },
        { "country": "Belford Roxo, BR", "code": "Belford Roxo:br" },
        { "country": "Campos dos Goytacazes, BR", "code": "Campos dos Goytacazes:br" },
        { "country": "São João de Meriti, BR", "code": "São João de Meriti:br" },
        { "country": "Aparecida de Goiânia, BR", "code": "Aparecida de Goiânia:br" },
        { "country": "Caxias do Sul, BR", "code": "Caxias do Sul:br" },
        { "country": "Porto Velho, BR", "code": "Porto Velho:br" },
        { "country": "Florianopolis, BR", "code": "Florianopolis:br" },
        { "country": "Santos, BR", "code": "Santos:br" },
        { "country": "Maua, BR", "code": "Maua:br" },
        { "country": "British Indian Ocean Territory", "code": ":io" },
        { "country": "British Virgin Islands", "code": ":vg" },
        { "country": "Brunei", "code": ":bn" },
        { "country": "Bulgaria", "code": ":bg" },
        { "country": "Burkina Faso", "code": ":bf" },
        { "country": "Burundi", "code": ":bi" },
        { "country": "Cambodia", "code": ":kh" },
        { "country": "Cameroon", "code": ":cm" },
        { "country": "Canada", "code": ":ca" },
        { "country": "Toronto, CA", "code": "Toronto:ca" },
        { "country": "Montreal, CA", "code": "Montreal:ca" },
        { "country": "Vancouver, CA", "code": "Vancouver:ca" },
        { "country": "Calgary, CA", "code": "Calgary:ca" },
        { "country": "Edmonton, CA", "code": "Edmonton:ca" },
        { "country": "Ottawa-Gatineau, CA", "code": "Ottawa-Gatineau:ca" },
        { "country": "Winnipeg, CA", "code": "Winnipeg:ca" },
        { "country": "Quebec City, CA", "code": "Quebec City:ca" },
        { "country": "Hamilton, CA", "code": "Hamilton:ca" },
        { "country": "Kitchener-Cambridge-Waterloo, CA", "code": "Kitchener-Cambridge-Waterloo:ca" },
        { "country": "London, CA", "code": "London:ca" },
        { "country": "Halifax, CA", "code": "Halifax:ca" },
        { "country": "St. Catharines-Niagara, CA", "code": "St. Catharines-Niagara:ca" },
        { "country": "Oshawa, CA", "code": "Oshawa:ca" },
        { "country": "Victoria, CA", "code": "Victoria:ca" },
        { "country": "Windsor, CA", "code": "Windsor:ca" },
        { "country": "Saskatoon, CA", "code": "Saskatoon:ca" },
        { "country": "Regina, CA", "code": "Regina:ca" },
        { "country": "Sherbrooke, CA", "code": "Sherbrooke:ca" },
        { "country": "Kelowna, CA", "code": "Kelowna:ca" },
        { "country": "Barrie, CA", "code": "Barrie:ca" },
        { "country": "St. John's, CA", "code": "St. John's:ca" },
        { "country": "Abbotsford-Mission, CA", "code": "Abbotsford-Mission:ca" },
        { "country": "Kingston, CA", "code": "Kingston:ca" },
        { "country": "Greater Sudbury, CA", "code": "Greater Sudbury:ca" },
        { "country": "Guelph, CA", "code": "Guelph:ca" },
        { "country": "Saguenay, CA", "code": "Saguenay:ca" },
        { "country": "Trois-Rivières, CA", "code": "Trois-Rivières:ca" },
        { "country": "Moncton, CA", "code": "Moncton:ca" },
        { "country": "Brantford, CA", "code": "Brantford:ca" },
        { "country": "Peterborough, CA", "code": "Peterborough:ca" },
        { "country": "Saint John, CA", "code": "Saint John:ca" },
        { "country": "Lethbridge, CA", "code": "Lethbridge:ca" },
        { "country": "Thunder Bay, CA", "code": "Thunder Bay:ca" },
        { "country": "Nanaimo, CA", "code": "Nanaimo:ca" },
        { "country": "Kamloops, CA", "code": "Kamloops:ca" },
        { "country": "Chilliwack, CA", "code": "Chilliwack:ca" },
        { "country": "Belleville, CA", "code": "Belleville:ca" },
        { "country": "Fredericton, CA", "code": "Fredericton:ca" },
        { "country": "Chatham-Kent, CA", "code": "Chatham-Kent:ca" },
        { "country": "Red Deer, CA", "code": "Red Deer:ca" },
        { "country": "Cape Verde", "code": ":cv" },
        { "country": "Caribbean Netherlands", "code": ":bq" },
        { "country": "Cayman Islands", "code": ":ky" },
        { "country": "Central African Republic", "code": ":cf" },
        { "country": "Chad", "code": ":td" },
        { "country": "Chile", "code": ":cl" },
        { "country": "Christmas Island", "code": ":cx" },
        { "country": "Cocos (Keeling) Islands", "code": ":cc" },
        { "country": "Colombia", "code": ":co" },
        { "country": "Comoros", "code": ":km" },
        { "country": "Congo (DRC)", "code": ":cd" },
        { "country": "Congo (Republic)", "code": ":cg" },
        { "country": "Cook Islands", "code": ":ck" },
        { "country": "Costa Rica", "code": ":cr" },
        { "country": "Côte d'Ivoire", "code": ":ci" },
        { "country": "Croatia", "code": ":hr" },
        { "country": "Curaçao", "code": ":cw" },
        { "country": "Cyprus", "code": ":cy" },
        { "country": "Czech Republic", "code": ":cz" },
        { "country": "Denmark", "code": ":dk" },
        { "country": "Djibouti", "code": ":dj" },
        { "country": "Dominica", "code": ":dm" },
        { "country": "Dominican Republic", "code": ":do" },
        { "country": "Ecuador", "code": ":ec" },
        { "country": "Egypt", "code": ":eg" },
        { "country": "El Salvador", "code": ":sv" },
        { "country": "Equatorial Guinea", "code": ":gq" },
        { "country": "Eritrea", "code": ":er" },
        { "country": "Estonia", "code": ":ee" },
        { "country": "Ethiopia", "code": ":et" },
        { "country": "Falkland Islands (Islas Malvinas)", "code": ":fk" },
        { "country": "Faroe Islands", "code": ":fo" },
        { "country": "Fiji", "code": ":fj" },
        { "country": "Finland", "code": ":fi" },
        { "country": "France", "code": ":fr" },
        { "country": "Paris, FR", "code": "Paris:fr" },
        { "country": "Marseille, FR", "code": "Marseille:fr" },
        { "country": "Lyon, FR", "code": "Lyon:fr" },
        { "country": "Toulouse, FR", "code": "Toulouse:fr" },
        { "country": "Nice, FR", "code": "Nice:fr" },
        { "country": "Nantes, FR", "code": "Nantes:fr" },
        { "country": "Strasbourg, FR", "code": "Strasbourg:fr" },
        { "country": "Montpellier, FR", "code": "Montpellier:fr" },
        { "country": "Bordeaux, FR", "code": "Bordeaux:fr" },
        { "country": "Lille, FR", "code": "Lille:fr" },
        { "country": "Rennes, FR", "code": "Rennes:fr" },
        { "country": "Reims, FR", "code": "Reims:fr" },
        { "country": "Le Havre, FR", "code": "Le Havre:fr" },
        { "country": "Saint-Étienne, FR", "code": "Saint-Étienne:fr" },
        { "country": "Toulon, FR", "code": "Toulon:fr" },
        { "country": "Grenoble, FR", "code": "Grenoble:fr" },
        { "country": "Dijon, FR", "code": "Dijon:fr" },
        { "country": "Nîmes, FR", "code": "Nîmes:fr" },
        { "country": "Angers, FR", "code": "Angers:fr" },
        { "country": "Villeurbanne, FR", "code": "Villeurbanne:fr" },
        { "country": "Le Mans, FR", "code": "Le Mans:fr" },
        { "country": "Saint-Denis, FR", "code": "Saint-Denis:fr" },
        { "country": "Aix-en-Provence, FR", "code": "Aix-en-Provence:fr" },
        { "country": "Clermont-Ferrand, FR", "code": "Clermont-Ferrand:fr" },
        { "country": "Brest, FR", "code": "Brest:fr" },
        { "country": "Limoges, FR", "code": "Limoges:fr" },
        { "country": "Tours, FR", "code": "Tours:fr" },
        { "country": "Amiens, FR", "code": "Amiens:fr" },
        { "country": "Perpignan, FR", "code": "Perpignan:fr" },
        { "country": "Metz, FR", "code": "Metz:fr" },
        { "country": "Besançon, FR", "code": "Besançon:fr" },
        { "country": "Boulogne-Billancourt, FR", "code": "Boulogne-Billancourt:fr" },
        { "country": "Orléans, FR", "code": "Orléans:fr" },
        { "country": "Mulhouse, FR", "code": "Mulhouse:fr" },
        { "country": "Rouen, FR", "code": "Rouen:fr" },
        { "country": "Saint-Denis, FR", "code": "Saint-Denis:fr" },
        { "country": "Caen, FR", "code": "Caen:fr" },
        { "country": "Argenteuil, FR", "code": "Argenteuil:fr" },
        { "country": "Saint-Paul, FR", "code": "Saint-Paul:fr" },
        { "country": "Montreuil, FR", "code": "Montreuil:fr" },
        { "country": "Nancy, FR", "code": "Nancy:fr" },
        { "country": "French Guiana", "code": ":gf" },
        { "country": "French Polynesia", "code": ":pf" },
        { "country": "French Southern Territories", "code": ":tf" },
        { "country": "Gabon", "code": ":ga" },
        { "country": "Gambia", "code": ":gm" },
        { "country": "Georgia", "code": ":ge" },
        { "country": "Germany", "code": ":de" },
        { "country": "Berlin, DE", "code": "Berlin:de" },
        { "country": "Hamburg, DE", "code": "Hamburg:de" },
        { "country": "Munich (München), DE", "code": "Munich (München):de" },
        { "country": "Cologne (Köln), DE", "code": "Cologne (Köln):de" },
        { "country": "Frankfurt am Main, DE", "code": "Frankfurt am Main:de" },
        { "country": "Stuttgart, DE", "code": "Stuttgart:de" },
        { "country": "Düsseldorf, DE", "code": "Düsseldorf:de" },
        { "country": "Dortmund, DE", "code": "Dortmund:de" },
        { "country": "Essen, DE", "code": "Essen:de" },
        { "country": "Leipzig, DE", "code": "Leipzig:de" },
        { "country": "Bremen, DE", "code": "Bremen:de" },
        { "country": "Dresden, DE", "code": "Dresden:de" },
        { "country": "Hanover (Hannover), DE", "code": "Hanover (Hannover):de" },
        { "country": "Nuremberg (Nürnberg), DE", "code": "Nuremberg (Nürnberg):de" },
        { "country": "Duisburg, DE", "code": "Duisburg:de" },
        { "country": "Bochum, DE", "code": "Bochum:de" },
        { "country": "Wuppertal, DE", "code": "Wuppertal:de" },
        { "country": "Bielefeld, DE", "code": "Bielefeld:de" },
        { "country": "Bonn, DE", "code": "Bonn:de" },
        { "country": "Ghana", "code": ":gh" },
        { "country": "Gibraltar", "code": ":gi" },
        { "country": "Greece", "code": ":gr" },
        { "country": "Greenland", "code": ":gl" },
        { "country": "Grenada", "code": ":gd" },
        { "country": "Guadeloupe", "code": ":gp" },
        { "country": "Guam", "code": ":gu" },
        { "country": "Guatemala", "code": ":gt" },
        { "country": "Guernsey", "code": ":gg" },
        { "country": "Guinea", "code": ":gn" },
        { "country": "Guinea-Bissau", "code": ":gw" },
        { "country": "Guyana", "code": ":gy" },
        { "country": "Haiti", "code": ":ht" },
        { "country": "Heard & McDonald Islands", "code": ":hm" },
        { "country": "Honduras", "code": ":hn" },
        { "country": "Hong Kong", "code": ":hk" },
        { "country": "Hong Kong, HK", "code": "Hong Kong:hk" },
        { "country": "Kowloon, HK", "code": "Kowloon:hk" },
        { "country": "Tsuen Wan, HK", "code": "Tsuen Wan:hk" },
        { "country": "Yuen Long Kau Hui, HK", "code": "Yuen Long Kau Hui:hk" },
        { "country": "Tung Chung, HK", "code": "Tung Chung:hk" },
        { "country": "Hungary", "code": ":hu" },
        { "country": "Iceland", "code": ":is" },
        { "country": "Mumbai, IN", "code": "Mumbai:in" },
        { "country": "New Delhi, IN", "code": "New Delhi:in" },
        { "country": "Bangalore, IN", "code": "Bangalore:in" },
        { "country": "Hyderabad, IN", "code": "Hyderabad:in" },
        { "country": "Ahmedabad, IN", "code": "Ahmedabad:in" },
        { "country": "Chennai, IN", "code": "Chennai:in" },
        { "country": "Kolkata, IN", "code": "Kolkata:in" },
        { "country": "Surat, IN", "code": "Surat:in" },
        { "country": "Pune, IN", "code": "Pune:in" },
        { "country": "Jaipur, IN", "code": "Jaipur:in" },
        { "country": "Lucknow, IN", "code": "Lucknow:in" },
        { "country": "Kanpur, IN", "code": "Kanpur:in" },
        { "country": "Nagpur, IN", "code": "Nagpur:in" },
        { "country": "Indore, IN", "code": "Indore:in" },
        { "country": "Thane, IN", "code": "Thane:in" },
        { "country": "Bhopal, IN", "code": "Bhopal:in" },
        { "country": "Visakhapatnam, IN", "code": "Visakhapatnam:in" },
        { "country": "Pimpri-Chinchwad, IN", "code": "Pimpri-Chinchwad:in" },
        { "country": "Patna, IN", "code": "Patna:in" },
        { "country": "Vadodara, IN", "code": "Vadodara:in" },
        { "country": "Ghaziabad, IN", "code": "Ghaziabad:in" },
        { "country": "Ludhiana, IN", "code": "Ludhiana:in" },
        { "country": "Agra, IN", "code": "Agra:in" },
        { "country": "Nashik, IN", "code": "Nashik:in" },
        { "country": "Ranchi, IN", "code": "Ranchi:in" },
        { "country": "Faridabad, IN", "code": "Faridabad:in" },
        { "country": "Meerut, IN", "code": "Meerut:in" },
        { "country": "Rajkot, IN", "code": "Rajkot:in" },
        { "country": "Kalyan-Dombivli, IN", "code": "Kalyan-Dombivli:in" },
        { "country": "Vasai-Virar, IN", "code": "Vasai-Virar:in" },
        { "country": "Varanasi, IN", "code": "Varanasi:in" },
        { "country": "Srinagar, IN", "code": "Srinagar:in" },
        { "country": "Aurangabad, IN", "code": "Aurangabad:in" },
        { "country": "Dhanbad, IN", "code": "Dhanbad:in" },
        { "country": "Gurgaon, IN", "code": "Gurgaon:in" },
        { "country": "Amritsar, IN", "code": "Amritsar:in" },
        { "country": "Navi Mumbai, IN", "code": "Navi Mumbai:in" },
        { "country": "Allahabad, IN", "code": "Allahabad:in" },
        { "country": "Howrah, IN", "code": "Howrah:in" },
        { "country": "Gwalior, IN", "code": "Gwalior:in" },
        { "country": "Jabalpur, IN", "code": "Jabalpur:in" },
        { "country": "Coimbatore, IN", "code": "Coimbatore:in" },
        { "country": "Vijayawada, IN", "code": "Vijayawada:in" },
        { "country": "Jodhpur, IN", "code": "Jodhpur:in" },
        { "country": "Madurai, IN", "code": "Madurai:in" },
        { "country": "Raipur, IN", "code": "Raipur:in" },
        { "country": "Kota, IN", "code": "Kota:in" },
        { "country": "Salem, IN", "code": "Salem:in" },
        { "country": "Chandigarh, IN", "code": "Chandigarh:in" },
        { "country": "Guwahati, IN", "code": "Guwahati:in" },
        { "country": "Solapur, IN", "code": "Solapur:in" },
        { "country": "Hubli-Dharwad, IN", "code": "Hubli-Dharwad:in" },
        { "country": "Mysore, IN", "code": "Mysore:in" },
        { "country": "Tiruchirappalli, IN", "code": "Tiruchirappalli:in" },
        { "country": "Bareilly, IN", "code": "Bareilly:in" },
        { "country": "Aligarh, IN", "code": "Aligarh:in" },
        { "country": "Tiruppur, IN", "code": "Tiruppur:in" },
        { "country": "Moradabad, IN", "code": "Moradabad:in" },
        { "country": "Jalandhar, IN", "code": "Jalandhar:in" },
        { "country": "Bhubaneswar, IN", "code": "Bhubaneswar:in" },
        { "country": "Warangal, IN", "code": "Warangal:in" },
        { "country": "Mira-Bhayandar, IN", "code": "Mira-Bhayandar:in" },
        { "country": "Jalgaon, IN", "code": "Jalgaon:in" },
        { "country": "Thiruvananthapuram, IN", "code": "Thiruvananthapuram:in" },
        { "country": "Bhiwandi, IN", "code": "Bhiwandi:in" },
        { "country": "Saharanpur, IN", "code": "Saharanpur:in" },
        { "country": "Gorakhpur, IN", "code": "Gorakhpur:in" },
        { "country": "Guntur, IN", "code": "Guntur:in" },
        { "country": "Bikaner, IN", "code": "Bikaner:in" },
        { "country": "Amravati, IN", "code": "Amravati:in" },
        { "country": "Noida, IN", "code": "Noida:in" },
        { "country": "Jamshedpur, IN", "code": "Jamshedpur:in" },
        { "country": "Bhilai, IN", "code": "Bhilai:in" },
        { "country": "Cuttack, IN", "code": "Cuttack:in" },
        { "country": "Firozabad, IN", "code": "Firozabad:in" },
        { "country": "Kochi, IN", "code": "Kochi:in" },
        { "country": "Bhavnagar, IN", "code": "Bhavnagar:in" },
        { "country": "Dehradun, IN", "code": "Dehradun:in" },
        { "country": "Durgapur, IN", "code": "Durgapur:in" },
        { "country": "Asansol, IN", "code": "Asansol:in" },
        { "country": "Nellore, IN", "code": "Nellore:in" },
        { "country": "Rourkela, IN", "code": "Rourkela:in" },
        { "country": "Nanded, IN", "code": "Nanded:in" },
        { "country": "Kolhapur, IN", "code": "Kolhapur:in" },
        { "country": "Ajmer, IN", "code": "Ajmer:in" },
        { "country": "Akola, IN", "code": "Akola:in" },
        { "country": "Gulbarga, IN", "code": "Gulbarga:in" },
        { "country": "Jamnagar, IN", "code": "Jamnagar:in" },
        { "country": "Ujjain, IN", "code": "Ujjain:in" },
        { "country": "Loni, IN", "code": "Loni:in" },
        { "country": "Siliguri, IN", "code": "Siliguri:in" },
        { "country": "Jhansi, IN", "code": "Jhansi:in" },
        { "country": "Ulhasnagar, IN", "code": "Ulhasnagar:in" },
        { "country": "Jammu, IN", "code": "Jammu:in" },
        { "country": "Sangli-Miraj & Kupwad, IN", "code": "Sangli-Miraj & Kupwad:in" },
        { "country": "Mangalore, IN", "code": "Mangalore:in" },
        { "country": "Erode, IN", "code": "Erode:in" },
        { "country": "Belgaum, IN", "code": "Belgaum:in" },
        { "country": "Kurnool, IN", "code": "Kurnool:in" },
        { "country": "Ambattur, IN", "code": "Ambattur:in" },
        { "country": "Rajahmundry, IN", "code": "Rajahmundry:in" },
        { "country": "Indonesia", "code": ":id" },
        { "country": "Iraq", "code": ":iq" },
        { "country": "Ireland", "code": ":ie" },
        { "country": "Isle of Man", "code": ":im" },
        { "country": "Israel", "code": ":il" },
        { "country": "Italy", "code": ":it" },
        { "country": "Rome, IT", "code": "Rome:it" },
        { "country": "Milan, IT", "code": "Milan:it" },
        { "country": "Naples, IT", "code": "Naples:it" },
        { "country": "Turin, IT", "code": "Turin:it" },
        { "country": "Palermo, IT", "code": "Palermo:it" },
        { "country": "Genoa, IT", "code": "Genoa:it" },
        { "country": "Bologna, IT", "code": "Bologna:it" },
        { "country": "Florence, IT", "code": "Florence:it" },
        { "country": "Bari, IT", "code": "Bari:it" },
        { "country": "Catania, IT", "code": "Catania:it" },
        { "country": "Verona, IT", "code": "Verona:it" },
        { "country": "Venice, IT", "code": "Venice:it" },
        { "country": "Messina, IT", "code": "Messina:it" },
        { "country": "Padua, IT", "code": "Padua:it" },
        { "country": "Trieste, IT", "code": "Trieste:it" },
        { "country": "Brescia, IT", "code": "Brescia:it" },
        { "country": "Parma, IT", "code": "Parma:it" },
        { "country": "Taranto, IT", "code": "Taranto:it" },
        { "country": "Jamaica", "code": ":jm" },
        { "country": "Japan", "code": ":jp" },
        { "country": "Tokyo, JP", "code": "Tokyo:jp" },
        { "country": "Yokohama, JP", "code": "Yokohama:jp" },
        { "country": "Osaka, JP", "code": "Osaka:jp" },
        { "country": "Nagoya, JP", "code": "Nagoya:jp" },
        { "country": "Sapporo, JP", "code": "Sapporo:jp" },
        { "country": "Kobe, JP", "code": "Kobe:jp" },
        { "country": "Kyoto, JP", "code": "Kyoto:jp" },
        { "country": "Fukuoka, JP", "code": "Fukuoka:jp" },
        { "country": "Kawasaki, JP", "code": "Kawasaki:jp" },
        { "country": "Saitama, JP", "code": "Saitama:jp" },
        { "country": "Hiroshima, JP", "code": "Hiroshima:jp" },
        { "country": "Yono, JP", "code": "Yono:jp" },
        { "country": "Sendai, JP", "code": "Sendai:jp" },
        { "country": "Kitakyushu, JP", "code": "Kitakyushu:jp" },
        { "country": "Chiba, JP", "code": "Chiba:jp" },
        { "country": "Sakai, JP", "code": "Sakai:jp" },
        { "country": "Shizuoka, JP", "code": "Shizuoka:jp" },
        { "country": "Kumamoto, JP", "code": "Kumamoto:jp" },
        { "country": "Okayama, JP", "code": "Okayama:jp" },
        { "country": "Hamamatsu, JP", "code": "Hamamatsu:jp" },
        { "country": "Hachioji, JP", "code": "Hachioji:jp" },
        { "country": "Honcho, JP", "code": "Honcho:jp" },
        { "country": "Kagoshima, JP", "code": "Kagoshima:jp" },
        { "country": "Niigata, JP", "code": "Niigata:jp" },
        { "country": "Himeji, JP", "code": "Himeji:jp" },
        { "country": "Matsudo, JP", "code": "Matsudo:jp" },
        { "country": "Nishinomiya-hama, JP", "code": "Nishinomiya-hama:jp" },
        { "country": "Kawaguchi, JP", "code": "Kawaguchi:jp" },
        { "country": "Kanazawa, JP", "code": "Kanazawa:jp" },
        { "country": "Utsunomiya, JP", "code": "Utsunomiya:jp" },
        { "country": "Oita, JP", "code": "Oita:jp" },
        { "country": "Matsuyama, JP", "code": "Matsuyama:jp" },
        { "country": "Amagasaki, JP", "code": "Amagasaki:jp" },
        { "country": "Kurashiki, JP", "code": "Kurashiki:jp" },
        { "country": "Yokosuka, JP", "code": "Yokosuka:jp" },
        { "country": "Nagasaki, JP", "code": "Nagasaki:jp" },
        { "country": "Hirakata, JP", "code": "Hirakata:jp" },
        { "country": "Jersey", "code": ":je" },
        { "country": "Jordan", "code": ":jo" },
        { "country": "Kazakhstan", "code": ":kz" },
        { "country": "Kenya", "code": ":ke" },
        { "country": "Kiribati", "code": ":ki" },
        { "country": "Kosovo", "code": ":xk" },
        { "country": "Kuwait", "code": ":kw" },
        { "country": "Kyrgyzstan", "code": ":kg" },
        { "country": "Laos", "code": ":la" },
        { "country": "Latvia", "code": ":lv" },
        { "country": "Lebanon", "code": ":lb" },
        { "country": "Lesotho", "code": ":ls" },
        { "country": "Liberia", "code": ":lr" },
        { "country": "Libya", "code": ":ly" },
        { "country": "Liechtenstein", "code": ":li" },
        { "country": "Lithuania", "code": ":lt" },
        { "country": "Luxembourg", "code": ":lu" },
        { "country": "Macau", "code": ":mo" },
        { "country": "Macedonia (FYROM)", "code": ":mk" },
        { "country": "Madagascar", "code": ":mg" },
        { "country": "Malawi", "code": ":mw" },
        { "country": "Malaysia", "code": ":my" },
        { "country": "Maldives", "code": ":mv" },
        { "country": "Mali", "code": ":ml" },
        { "country": "Malta", "code": ":mt" },
        { "country": "Marshall Islands", "code": ":mh" },
        { "country": "Martinique", "code": ":mq" },
        { "country": "Mauritania", "code": ":mr" },
        { "country": "Mauritius", "code": ":mu" },
        { "country": "Mayotte", "code": ":yt" },
        { "country": "Mexico", "code": ":mx" },
        { "country": "Micronesia", "code": ":fm" },
        { "country": "Moldova", "code": ":md" },
        { "country": "Monaco", "code": ":mc" },
        { "country": "Mongolia", "code": ":mn" },
        { "country": "Montenegro", "code": ":me" },
        { "country": "Montserrat", "code": ":ms" },
        { "country": "Morocco", "code": ":ma" },
        { "country": "Mozambique", "code": ":mz" },
        { "country": "Myanmar (Burma)", "code": ":mm" },
        { "country": "Namibia", "code": ":na" },
        { "country": "Nauru", "code": ":nr" },
        { "country": "Nepal", "code": ":np" },
        { "country": "Netherlands", "code": ":nl" },
        { "country": "New Caledonia", "code": ":nc" },
        { "country": "New Zealand", "code": ":nz" },
        { "country": "Nicaragua", "code": ":ni" },
        { "country": "Niger", "code": ":ne" },
        { "country": "Nigeria", "code": ":ng" },
        { "country": "Niue", "code": ":nu" },
        { "country": "Norfolk Island", "code": ":nf" },
        { "country": "Northern Mariana Islands", "code": ":mp" },
        { "country": "Norway", "code": ":no" },
        { "country": "Oman", "code": ":om" },
        { "country": "Pakistan", "code": ":pk" },
        { "country": "Palau", "code": ":pw" },
        { "country": "Palestine", "code": ":ps" },
        { "country": "Panama", "code": ":pa" },
        { "country": "Papua New Guinea", "code": ":pg" },
        { "country": "Paraguay", "code": ":py" },
        { "country": "Peru", "code": ":pe" },
        { "country": "Philippines", "code": ":ph" },
        { "country": "Pitcairn Islands", "code": ":pn" },
        { "country": "Poland", "code": ":pl" },
        { "country": "Portugal", "code": ":pt" },
        { "country": "Puerto Rico", "code": ":pr" },
        { "country": "Qatar", "code": ":qa" },
        { "country": "Réunion", "code": ":re" },
        { "country": "Romania", "code": ":ro" },
        { "country": "Russia", "code": ":ru" },
        { "country": "Moscow, RU", "code": "Moscow:ru" },
        { "country": "Saint Petersburg, RU", "code": "Saint Petersburg:ru" },
        { "country": "Novosibirsk, RU", "code": "Novosibirsk:ru" },
        { "country": "Yekaterinburg, RU", "code": "Yekaterinburg:ru" },
        { "country": "Kazan, RU", "code": "Kazan:ru" },
        { "country": "Nizhny Novgorod, RU", "code": "Nizhny Novgorod:ru" },
        { "country": "Chelyabinsk, RU", "code": "Chelyabinsk:ru" },
        { "country": "Samara, RU", "code": "Samara:ru" },
        { "country": "Omsk, RU", "code": "Omsk:ru" },
        { "country": "Rostov-on-Don, RU", "code": "Rostov-on-Don:ru" },
        { "country": "Ufa, RU", "code": "Ufa:ru" },
        { "country": "Krasnoyarsk, RU", "code": "Krasnoyarsk:ru" },
        { "country": "Voronezh, RU", "code": "Voronezh:ru" },
        { "country": "Perm, RU", "code": "Perm:ru" },
        { "country": "Volgograd, RU", "code": "Volgograd:ru" },
        { "country": "Krasnodar, RU", "code": "Krasnodar:ru" },
        { "country": "Saratov, RU", "code": "Saratov:ru" },
        { "country": "Tyumen, RU", "code": "Tyumen:ru" },
        { "country": "Tolyatti, RU", "code": "Tolyatti:ru" },
        { "country": "Izhevsk, RU", "code": "Izhevsk:ru" },
        { "country": "Barnaul, RU", "code": "Barnaul:ru" },
        { "country": "Ulyanovsk, RU", "code": "Ulyanovsk:ru" },
        { "country": "Irkutsk, RU", "code": "Irkutsk:ru" },
        { "country": "Khabarovsk, RU", "code": "Khabarovsk:ru" },
        { "country": "Makhachkala, RU", "code": "Makhachkala:ru" },
        { "country": "Yaroslavl, RU", "code": "Yaroslavl:ru" },
        { "country": "Vladivostok, RU", "code": "Vladivostok:ru" },
        { "country": "Orenburg, RU", "code": "Orenburg:ru" },
        { "country": "Tomsk, RU", "code": "Tomsk:ru" },
        { "country": "Kemerovo, RU", "code": "Kemerovo:ru" },
        { "country": "Novokuznetsk, RU", "code": "Novokuznetsk:ru" },
        { "country": "Ryazan, RU", "code": "Ryazan:ru" },
        { "country": "Naberezhnye Chelny, RU", "code": "Naberezhnye Chelny:ru" },
        { "country": "Astrakhan, RU", "code": "Astrakhan:ru" },
        { "country": "Kirov, RU", "code": "Kirov:ru" },
        { "country": "Penza, RU", "code": "Penza:ru" },
        { "country": "Balashikha, RU", "code": "Balashikha:ru" },
        { "country": "Lipetsk, RU", "code": "Lipetsk:ru" },
        { "country": "Rwanda", "code": ":rw" },
        { "country": "Samoa", "code": ":ws" },
        { "country": "San Marino", "code": ":sm" },
        { "country": "São Tomé & Príncipe", "code": ":st" },
        { "country": "Saudi Arabia", "code": ":sa" },
        { "country": "Senegal", "code": ":sn" },
        { "country": "Serbia", "code": ":rs" },
        { "country": "Seychelles", "code": ":sc" },
        { "country": "Sierra Leone", "code": ":sl" },
        { "country": "Singapore", "code": ":sg" },
        { "country": "Sint Maarten", "code": ":sx" },
        { "country": "Slovakia", "code": ":sk" },
        { "country": "Slovenia", "code": ":si" },
        { "country": "Solomon Islands", "code": ":sb" },
        { "country": "Somalia", "code": ":so" },
        { "country": "South Africa", "code": ":za" },
        { "country": "South Georgia & South Sandwich Islands", "code": ":gs" },
        { "country": "South Korea", "code": ":kr" },
        { "country": "South Sudan", "code": ":ss" },
        { "country": "Spain", "code": ":es" },
        { "country": "Oviedo-Gijón-Avilés, ES", "code": "Oviedo-Gijón-Avilés:es" },
        { "country": "Alicante-Elche(-Elda), ES", "code": "Alicante-Elche(-Elda):es" },
        { "country": "Murcia-Orihuela, ES", "code": "Murcia-Orihuela:es" },
        { "country": "Las Palmas, ES", "code": "Las Palmas:es" },
        { "country": "Madrid, ES", "code": "Madrid:es" },
        { "country": "Vigo, ES", "code": "Vigo:es" },
        { "country": "Barcelona, ES", "code": "Barcelona:es" },
        { "country": "Santander-Torrelavega, ES", "code": "Santander-Torrelavega:es" },
        { "country": "Castellón de la Plana, ES", "code": "Castellón de la Plana:es" },
        { "country": "Alzira-Xàtiva, ES", "code": "Alzira-Xàtiva:es" },
        { "country": "Cartagena, ES", "code": "Cartagena:es" },
        { "country": "Valencia(-Sagunto), ES", "code": "Valencia(-Sagunto):es" },
        { "country": "Seville, ES", "code": "Seville:es" },
        { "country": "Bilbao, ES", "code": "Bilbao:es" },
        { "country": "Málaga, ES", "code": "Málaga:es" },
        { "country": "Zaragoza, ES", "code": "Zaragoza:es" },
        { "country": "Palma, ES", "code": "Palma:es" },
        { "country": "Granada, ES", "code": "Granada:es" },
        { "country": "Santa Cruz de Tenerife-La Laguna, ES", "code": "Santa Cruz de Tenerife-La Laguna:es" },
        { "country": "Cádiz, ES", "code": "Cádiz:es" },
        { "country": "Tarragona(-Reus), ES", "code": "Tarragona(-Reus):es" },
        { "country": "A Coruña-Oleiros-Arteixo, ES", "code": "A Coruña-Oleiros-Arteixo:es" },
        { "country": "San Sebastián, ES", "code": "San Sebastián:es" },
        { "country": "Sri Lanka", "code": ":lk" },
        { "country": "St. Barthélemy", "code": ":bl" },
        { "country": "St. Helena", "code": ":sh" },
        { "country": "St. Kitts & Nevis", "code": ":kn" },
        { "country": "St. Lucia", "code": ":lc" },
        { "country": "St. Martin", "code": ":mf" },
        { "country": "St. Pierre & Miquelon", "code": ":pm" },
        { "country": "St. Vincent & Grenadines", "code": ":vc" },
        { "country": "Suriname", "code": ":sr" },
        { "country": "Svalbard & Jan Mayen", "code": ":sj" },
        { "country": "Swaziland", "code": ":sz" },
        { "country": "Sweden", "code": ":se" },
        { "country": "Switzerland", "code": ":ch" },
        { "country": "Taiwan", "code": ":tw" },
        { "country": "Tajikistan", "code": ":tj" },
        { "country": "Tanzania", "code": ":tz" },
        { "country": "Thailand", "code": ":th" },
        { "country": "Timor-Leste", "code": ":tl" },
        { "country": "Togo", "code": ":tg" },
        { "country": "Tokelau", "code": ":tk" },
        { "country": "Tonga", "code": ":to" },
        { "country": "Trinidad & Tobago", "code": ":tt" },
        { "country": "Tristan da Cunha", "code": ":ta" },
        { "country": "Tunisia", "code": ":tn" },
        { "country": "Turkey", "code": ":tr" },
        { "country": "Turkmenistan", "code": ":tm" },
        { "country": "Turks & Caicos Islands", "code": ":tc" },
        { "country": "Tuvalu", "code": ":tv" },
        { "country": "U.S. Outlying Islands", "code": ":um" },
        { "country": "U.S. Virgin Islands", "code": ":vi" },
        { "country": "Uganda", "code": ":ug" },
        { "country": "Ukraine", "code": ":ua" },
        { "country": "United Arab Emirates", "code": ":ae" },
        { "country": "London, GB", "code": "London:gb" },
        { "country": "Birmingham, GB", "code": "Birmingham:gb" },
        { "country": "Manchester, GB", "code": "Manchester:gb" },
        { "country": "Leeds-Bradford, GB", "code": "Leeds-Bradford:gb" },
        { "country": "Liverpool/Birkenhead, GB", "code": "Liverpool/Birkenhead:gb" },
        { "country": "Tyneside, GB", "code": "Tyneside:gb" },
        { "country": "Sheffield, GB", "code": "Sheffield:gb" },
        { "country": "Portsmouth-Southampton, GB", "code": "Portsmouth-Southampton:gb" },
        { "country": "Nottingham-Derby, GB", "code": "Nottingham-Derby:gb" },
        { "country": "Glasgow, GB", "code": "Glasgow:gb" },
        { "country": "Cardiff and South Wales valleys, GB", "code": "Cardiff and South Wales valleys:gb" },
        { "country": "Bristol, GB", "code": "Bristol:gb" },
        { "country": "Belfast, GB", "code": "Belfast:gb" },
        { "country": "Edinburgh, GB", "code": "Edinburgh:gb" },
        { "country": "Brighton-Worthing-Littlehampton, GB", "code": "Brighton-Worthing-Littlehampton:gb" },
        { "country": "Leicester, GB", "code": "Leicester:gb" },
        { "country": "Middlesbrough, GB", "code": "Middlesbrough:gb" },
        { "country": "Bournemouth-Poole, GB", "code": "Bournemouth-Poole:gb" },
        { "country": "Swansea, GB", "code": "Swansea:gb" },
        { "country": "Stoke, GB", "code": "Stoke:gb" },
        { "country": "Hull, GB", "code": "Hull:gb" },
        { "country": "Blackburn-Burnley, GB", "code": "Blackburn-Burnley:gb" },
        { "country": "Norwich, GB", "code": "Norwich:gb" },
        { "country": "Preston, GB", "code": "Preston:gb" },
        { "country": "Plymouth, GB", "code": "Plymouth:gb" },
        { "country": "Aberdeen, GB", "code": "Aberdeen:gb" },
        { "country": "Blackpool, GB", "code": "Blackpool:gb" },
        { "country": "Northampton, GB", "code": "Northampton:gb" },
        { "country": "Cambridge, GB", "code": "Cambridge:gb" },
        { "country": "Milton Keynes, GB", "code": "Milton Keynes:gb" },
        { "country": "Swindon, GB", "code": "Swindon:gb" },
        { "country": "Exeter, GB", "code": "Exeter:gb" },
        { "country": "Oxford, GB", "code": "Oxford:gb" },
        { "country": "Ipswich, GB", "code": "Ipswich:gb" },
        { "country": "York, GB", "code": "York:gb" },
        { "country": "Torbay, GB", "code": "Torbay:gb" },
        { "country": "Peterborough, GB", "code": "Peterborough:gb" },
        { "country": "Dundee, GB", "code": "Dundee:gb" },
        { "country": "Telford, GB", "code": "Telford:gb" },
        { "country": "Bedford, GB", "code": "Bedford:gb" },
        { "country": "Colchester, GB", "code": "Colchester:gb" },
        { "country": "Lincoln, GB", "code": "Lincoln:gb" },
        { "country": "Grimsby, GB", "code": "Grimsby:gb" },
        { "country": "Gloucester, GB", "code": "Gloucester:gb" },
        { "country": "Hastings-Bexhill, GB", "code": "Hastings-Bexhill:gb" },
        { "country": "Cheltenham, GB", "code": "Cheltenham:gb" },
        { "country": "New York, US", "code": "New York:us" },
        { "country": "Los Angeles, US", "code": "Los Angeles:us" },
        { "country": "Chicago, US", "code": "Chicago:us" },
        { "country": "Houston, US", "code": "Houston:us" },
        { "country": "Phoenix, US", "code": "Phoenix:us" },
        { "country": "Philadelphia, US", "code": "Philadelphia:us" },
        { "country": "San Antonio, US", "code": "San Antonio:us" },
        { "country": "San Diego, US", "code": "San Diego:us" },
        { "country": "Dallas, US", "code": "Dallas:us" },
        { "country": "San Jose, US", "code": "San Jose:us" },
        { "country": "Austin, US", "code": "Austin:us" },
        { "country": "Jacksonville, US", "code": "Jacksonville:us" },
        { "country": "Fort Worth, US", "code": "Fort Worth:us" },
        { "country": "Columbus, US", "code": "Columbus:us" },
        { "country": "Indianapolis, US", "code": "Indianapolis:us" },
        { "country": "Charlotte, US", "code": "Charlotte:us" },
        { "country": "San Francisco, US", "code": "San Francisco:us" },
        { "country": "Seattle, US", "code": "Seattle:us" },
        { "country": "Denver, US", "code": "Denver:us" },
        { "country": "Washington, US", "code": "Washington:us" },
        { "country": "Nashville, US", "code": "Nashville:us" },
        { "country": "Oklahoma City, US", "code": "Oklahoma City:us" },
        { "country": "El Paso, US", "code": "El Paso:us" },
        { "country": "Boston, US", "code": "Boston:us" },
        { "country": "Portland, US", "code": "Portland:us" },
        { "country": "Las Vegas, US", "code": "Las Vegas:us" },
        { "country": "Detroit, US", "code": "Detroit:us" },
        { "country": "Memphis, US", "code": "Memphis:us" },
        { "country": "Louisville, US", "code": "Louisville:us" },
        { "country": "Baltimore, US", "code": "Baltimore:us" },
        { "country": "Milwaukee, US", "code": "Milwaukee:us" },
        { "country": "Albuquerque, US", "code": "Albuquerque:us" },
        { "country": "Tucson, US", "code": "Tucson:us" },
        { "country": "Fresno, US", "code": "Fresno:us" },
        { "country": "Sacramento, US", "code": "Sacramento:us" },
        { "country": "Kansas City, US", "code": "Kansas City:us" },
        { "country": "Mesa, US", "code": "Mesa:us" },
        { "country": "Atlanta, US", "code": "Atlanta:us" },
        { "country": "Omaha, US", "code": "Omaha:us" },
        { "country": "Colorado Springs, US", "code": "Colorado Springs:us" },
        { "country": "Raleigh, US", "code": "Raleigh:us" },
        { "country": "Long Beach, US", "code": "Long Beach:us" },
        { "country": "Virginia Beach, US", "code": "Virginia Beach:us" },
        { "country": "Miami, US", "code": "Miami:us" },
        { "country": "Oakland, US", "code": "Oakland:us" },
        { "country": "Minneapolis, US", "code": "Minneapolis:us" },
        { "country": "Tulsa, US", "code": "Tulsa:us" },
        { "country": "Bakersfield, US", "code": "Bakersfield:us" },
        { "country": "Wichita, US", "code": "Wichita:us" },
        { "country": "Arlington, US", "code": "Arlington:us" },
        { "country": "Aurora, US", "code": "Aurora:us" },
        { "country": "Tampa, US", "code": "Tampa:us" },
        { "country": "New Orleans, US", "code": "New Orleans:us" },
        { "country": "Cleveland, US", "code": "Cleveland:us" },
        { "country": "Honolulu, US", "code": "Honolulu:us" },
        { "country": "Anaheim, US", "code": "Anaheim:us" },
        { "country": "Lexington, US", "code": "Lexington:us" },
        { "country": "Stockton, US", "code": "Stockton:us" },
        { "country": "Corpus Christi, US", "code": "Corpus Christi:us" },
        { "country": "Henderson, US", "code": "Henderson:us" },
        { "country": "Riverside, US", "code": "Riverside:us" },
        { "country": "Newark, US", "code": "Newark:us" },
        { "country": "Saint Paul, US", "code": "Saint Paul:us" },
        { "country": "Santa Ana, US", "code": "Santa Ana:us" },
        { "country": "Cincinnati, US", "code": "Cincinnati:us" },
        { "country": "Irvine, US", "code": "Irvine:us" },
        { "country": "Orlando, US", "code": "Orlando:us" },
        { "country": "Pittsburgh, US", "code": "Pittsburgh:us" },
        { "country": "St. Louis, US", "code": "St. Louis:us" },
        { "country": "Greensboro, US", "code": "Greensboro:us" },
        { "country": "Jersey City, US", "code": "Jersey City:us" },
        { "country": "Anchorage, US", "code": "Anchorage:us" },
        { "country": "Lincoln, US", "code": "Lincoln:us" },
        { "country": "Plano, US", "code": "Plano:us" },
        { "country": "Durham, US", "code": "Durham:us" },
        { "country": "Buffalo, US", "code": "Buffalo:us" },
        { "country": "Chandler, US", "code": "Chandler:us" },
        { "country": "Chula Vista, US", "code": "Chula Vista:us" },
        { "country": "Toledo, US", "code": "Toledo:us" },
        { "country": "Madison, US", "code": "Madison:us" },
        { "country": "Gilbert, US", "code": "Gilbert:us" },
        { "country": "Reno, US", "code": "Reno:us" },
        { "country": "Fort Wayne, US", "code": "Fort Wayne:us" },
        { "country": "North Las Vegas, US", "code": "North Las Vegas:us" },
        { "country": "St. Petersburg, US", "code": "St. Petersburg:us" },
        { "country": "Lubbock, US", "code": "Lubbock:us" },
        { "country": "Irving, US", "code": "Irving:us" },
        { "country": "Laredo, US", "code": "Laredo:us" },
        { "country": "Winston-Salem, US", "code": "Winston-Salem:us" },
        { "country": "Chesapeake, US", "code": "Chesapeake:us" },
        { "country": "Glendale, US", "code": "Glendale:us" },
        { "country": "Garland, US", "code": "Garland:us" },
        { "country": "Scottsdale, US", "code": "Scottsdale:us" },
        { "country": "Norfolk, US", "code": "Norfolk:us" },
        { "country": "Boise, US", "code": "Boise:us" },
        { "country": "Fremont, US", "code": "Fremont:us" },
        { "country": "Spokane, US", "code": "Spokane:us" },
        { "country": "Santa Clarita, US", "code": "Santa Clarita:us" },
        { "country": "Baton Rouge, US", "code": "Baton Rouge:us" },
        { "country": "Richmond, US", "code": "Richmond:us" },
        { "country": "Hialeah, US", "code": "Hialeah:us" },
        { "country": "San Bernardino, US", "code": "San Bernardino:us" },
        { "country": "Tacoma, US", "code": "Tacoma:us" },
        { "country": "Modesto, US", "code": "Modesto:us" },
        { "country": "Huntsville, US", "code": "Huntsville:us" },
        { "country": "Des Moines, US", "code": "Des Moines:us" },
        { "country": "Yonkers, US", "code": "Yonkers:us" },
        { "country": "Rochester, US", "code": "Rochester:us" },
        { "country": "Moreno Valley, US", "code": "Moreno Valley:us" },
        { "country": "Fayetteville, US", "code": "Fayetteville:us" },
        { "country": "Fontana, US", "code": "Fontana:us" },
        { "country": "Columbus, US", "code": "Columbus:us" },
        { "country": "Worcester, US", "code": "Worcester:us" },
        { "country": "Port St. Lucie, US", "code": "Port St. Lucie:us" },
        { "country": "Little Rock, US", "code": "Little Rock:us" },
        { "country": "Augusta, US", "code": "Augusta:us" },
        { "country": "Oxnard, US", "code": "Oxnard:us" },
        { "country": "Birmingham, US", "code": "Birmingham:us" },
        { "country": "Montgomery, US", "code": "Montgomery:us" },
        { "country": "Frisco, US", "code": "Frisco:us" },
        { "country": "Amarillo, US", "code": "Amarillo:us" },
        { "country": "Uruguay", "code": ":uy" },
        { "country": "Uzbekistan", "code": ":uz" },
        { "country": "Vanuatu", "code": ":vu" },
        { "country": "Vatican City", "code": ":va" },
        { "country": "Venezuela", "code": ":ve" },
        { "country": "Vietnam", "code": ":vn" },
        { "country": "Wallis & Futuna", "code": ":wf" },
        { "country": "Western Sahara", "code": ":eh" },
        { "country": "Yemen", "code": ":ye" },
        { "country": "Zambia", "code": ":zm" },
        { "country": "Zimbabwe", "code": ":zw" }
    ]
    public static editorAuditLanguages = [
        { "code": "en", "name": "English" },
        { "code": "ab", "name": "Arabic" },
        { "code": "ar", "name": "Armenian" },
        { "code": "ba", "name": "Basque" },
        { "code": "be", "name": "Bengali" },
        { "code": "br", "name": "Brazilian" },
        { "code": "bu", "name": "Bulgarian" },
        { "code": "ca", "name": "Catalan" },
        { "code": "cr", "name": "Croatian" },
        { "code": "cz", "name": "Czech" },
        { "code": "da", "name": "Danish" },
        { "code": "du", "name": "Dutch" },
        { "code": "fi", "name": "Finnish" },
        { "code": "fr", "name": "French" },
        { "code": "ga", "name": "Galician" },
        { "code": "ge", "name": "German" },
        { "code": "gr", "name": "Greek" },
        { "code": "he", "name": "Hebrew" },
        { "code": "hi", "name": "Hindi" },
        { "code": "hu", "name": "Hungarian" },
        { "code": "in", "name": "Indonesian" },
        { "code": "ir", "name": "Irish (Gaeilge)" },
        { "code": "it", "name": "Italian" },
        { "code": "ko", "name": "Korean" },
        { "code": "la", "name": "Latvian" },
        { "code": "li", "name": "Lithuanian" },
        { "code": "mr", "name": "Marathi" },
        { "code": "no", "name": "Norwegian" },
        { "code": "pe", "name": "Persian" },
        { "code": "pl", "name": "Polish" },
        { "code": "po", "name": "Portuguese" },
        { "code": "ro", "name": "Romanian" },
        { "code": "ru", "name": "Russian" },
        { "code": "sl", "name": "Slovak" },
        { "code": "sp", "name": "Spanish" },
        { "code": "sw", "name": "Swedish" },
        { "code": "th", "name": "Thai" },
        { "code": "tu", "name": "Turkish" },
        { "code": "uk", "name": "Ukrainian" },
        { "code": "ur", "name": "Urdu" },
        { "code": "vn", "name": "Vietnamese" }
    ]
    // public static selectedFlag = '../assets/images/australia.png';
    public static tagArray = ['Density', 'Kingdom', 'Flourine', 'Ice giant', 'Parsec', 'Gasoline', 'Prey', 'Filter Feeder', 'Electricity', 'Lab', 'Light-year', 'Windmil'];
    public static labelArray = ['Label 1', 'Label 2', 'Label 3'];
    public static statusArray = ['Completed', 'Not Completed'];
    public static nameData = ["Narrative review", "Systematic reviews", "Technical notes",
        "Editorials", "Clinic studies", "Research Paper", "Published in Scientific Journal"];
    public static keyWordArray = ["PAA", "Related"]
    public static topicData = [
        'Design', 'Uiux', 'Designer', 'Salary', 'Course', 'Learn',
        'Jobs', 'Online', 'Carrer', 'Graphic', 'Industrial Product ',
        'Exhibition', 'Fashion', 'Print', 'Stage', 'Animation',
        'Jewlry', 'Interaction', 'UI/UX', 'Visual Effects', 'Textile',
        'Architecture', 'Furniture', 'Drawing'
    ]
    public static H1BriefData = [
        {
            title: 'Simulation simple today', desc: 'Lorem ipsum dolor sit amet consectetur. Bibendum odio tellus gravida lectus dui. Ut nulla venenatis congue posuere. Tincidunt pulvinar vivamus donec elit bibendum aliquam eu. In mi euismod velit massa eu. Lorem et placerat urna elit magna sed. Ante porta habitasse id at in sagittis. Diam etiam odio ...',
            link: "https://www.myretail.com/products/smart-chasy-honor-watch-gs-pro-black-kan-b19-30052782"
        },
    ]
    public static PeopleAlsoAskTab = [
        'How do i look up a logo?', 'What are the 3 types of logo?', 'What are the 10 types of logo?', 'What are 50 most recognized brand logos?'
        , 'How long does it take to make a logo ?'
    ]
    public static TopCompititorTitle = [
        '16,352 logo icons - Iconfinder', 'All Logos | Brands of the World™'
        , 'The Logo Finder - Free Download Logo Vector Images'
    ]
    public static IncludedKeywords = [
        { keyWord: "Gasoline", isHAvailable: false },
        { keyWord: "Ice giant", isHAvailable: false },
        { keyWord: "Proton", isHAvailable: true },
        { keyWord: "Conservation", isHAvailable: false },
        { keyWord: "Equinox", isHAvailable: false },
        { keyWord: "Diesel", isHAvailable: false },
        { keyWord: "Nucear energy", isHAvailable: false },
        { keyWord: "Cell", isHAvailable: false },
        { keyWord: "Cosmos", isHAvailable: false },
        { keyWord: "Temperature", isHAvailable: false },
        { keyWord: "Kingdom", isHAvailable: false },
        { keyWord: "Terms", isHAvailable: false },
        { keyWord: "Planet", isHAvailable: false },
        { keyWord: "Flourine", isHAvailable: false },
        { keyWord: "Lead", isHAvailable: false },
        { keyWord: "Carbon Footprint", isHAvailable: true },
        { keyWord: "Solar Panel", isHAvailable: false },
        { keyWord: "Robotics", isHAvailable: false },
        { keyWord: "Corona", isHAvailable: true },
        { keyWord: "Battery", isHAvailable: false },
        { keyWord: "Saturn", isHAvailable: false },
        { keyWord: "Fact", isHAvailable: false },
        { keyWord: "Mineral", isHAvailable: true },
        { keyWord: "Earth", isHAvailable: false },
        { keyWord: "Neptune", isHAvailable: false },
        { keyWord: "Binary Star", isHAvailable: false },
        { keyWord: "Light Year", isHAvailable: false },
        { keyWord: "Diesel", isHAvailable: true },
        { keyWord: "Gasoline", isHAvailable: false },
        { keyWord: "Gasoline", isHAvailable: false },
        { keyWord: "Ice giant", isHAvailable: false },
        { keyWord: "Proton", isHAvailable: true },
        { keyWord: "Conservation", isHAvailable: false },
        { keyWord: "Equinox", isHAvailable: false },
        { keyWord: "Diesel", isHAvailable: false },
        { keyWord: "Nucear energy", isHAvailable: false },
        { keyWord: "Cell", isHAvailable: false },
        { keyWord: "Cosmos", isHAvailable: false },
        { keyWord: "Temperature", isHAvailable: false },
        { keyWord: "Kingdom", isHAvailable: false },
        { keyWord: "Terms", isHAvailable: false },
        { keyWord: "Planet", isHAvailable: false },
        { keyWord: "Flourine", isHAvailable: false },
    ];
    public static excludedKeywords = [
        { keyWord: "costco", isDisabled: false },
        { keyWord: "solitaire", isDisabled: false },
        { keyWord: "hotmail", isDisabled: true },
        { keyWord: "dominos", isDisabled: false },
        { keyWord: "craigslist", isDisabled: false },
        { keyWord: "amazon prime", isDisabled: false },
        { keyWord: "outlook", isDisabled: true },
        { keyWord: "etsy", isDisabled: false },
        { keyWord: "google maps", isDisabled: false },
        { keyWord: "google", isDisabled: true },
        { keyWord: "fb", isDisabled: false },
        { keyWord: "airbnb", isDisabled: false },
        { keyWord: "twitch", isDisabled: false },
        { keyWord: "nfl", isDisabled: false },
        { keyWord: "speed test", isDisabled: false },
        { keyWord: "facebook", isDisabled: false },
        { keyWord: "google scholar", isDisabled: false },
        { keyWord: "bing", isDisabled: false },
        { keyWord: "spotify", isDisabled: false },
        { keyWord: "youtube to mp3", isDisabled: false },
        { keyWord: "indeed", isDisabled: true },
        { keyWord: "united airlines", isDisabled: false },
        { keyWord: "msn", isDisabled: false },
        { keyWord: "twitter", isDisabled: false },
        { keyWord: "aol mail", isDisabled: true },
        { keyWord: "google docs", isDisabled: false },
        { keyWord: "instagram", isDisabled: false },
        { keyWord: "costco", isDisabled: false },
        { keyWord: "solitaire", isDisabled: false },
        { keyWord: "hotmail", isDisabled: true },
        { keyWord: "dominos", isDisabled: false },
        { keyWord: "craigslist", isDisabled: false },
        { keyWord: "amazon prime", isDisabled: false },
        { keyWord: "outlook", isDisabled: true },
        { keyWord: "etsy", isDisabled: false },
        { keyWord: "google maps", isDisabled: false },
        { keyWord: "home depot", isDisabled: false },
        { keyWord: "walgreens", isDisabled: false },
        { keyWord: "turbotax", isDisabled: false },
        { keyWord: "timer", isDisabled: false },
        { keyWord: "gmail", isDisabled: false },
        { keyWord: "verizon", isDisabled: false },
        { keyWord: "bed bath and beyond", isDisabled: false },
        { keyWord: "wells fargo", isDisabled: false },
        { keyWord: "amazon prime", isDisabled: false },
        { keyWord: "costco", isDisabled: false },
        { keyWord: "solitaire", isDisabled: false },
        { keyWord: "etsy", isDisabled: false },
        { keyWord: "hotmail", isDisabled: true },
        { keyWord: "craigslist", isDisabled: false },
        { keyWord: "outlook", isDisabled: true },
        { keyWord: "google maps", isDisabled: false },
        { keyWord: "dominos", isDisabled: false },
        { keyWord: "verizon", isDisabled: false },
        { keyWord: "capital one", isDisabled: false },
        { keyWord: "turbotax", isDisabled: false },
        { keyWord: "bank of america", isDisabled: false },
        { keyWord: "macys", isDisabled: false },
    ];
    public static TopicAndQuestionList = [
        { title: "She was the type of girl who wanted to live in a pink house?", type: "Competitors", isSelected: false },
        { title: "Erin accidentally created a new universe.", type: "Strell", isSelected: false },
        { title: "Too many prisons have become early coffins?", type: "People also ask", isSelected: false },
        { title: "It didn't take long for Gary to detect the robbers were amateurs?", type: "People also ask", isSelected: false },
        { title: "He found a leprechaun in his walnut shell.", type: "Competitors", isSelected: false },
        { title: "Her daily goal was to improve on yesterday.", type: "People also ask", isSelected: false },
        { title: "He had concluded that pigs must be able to fly in Hog Heaven.", type: "Competitors", isSelected: false },
        { title: "Peanut butter and jelly caused the elderly lady to think about her past.", type: "Strell", isSelected: false },
        { title: "She hadn't had her cup of coffee, and that made things all the worse.", type: "Competitors", isSelected: false },
        { title: "He found the chocolate covered roaches quite tasty?", type: "People also ask", isSelected: false },
    ]
    public static outlineTagArray = [
        { tag: "H3", desc: "What a big truck!", isVisible: false },
        { tag: "H3", desc: "She usually has to give in to her big sister. I just walked on the furriest lawn.", isVisible: false },
        { tag: "H3", desc: "How big was your contribution?", isVisible: false },
        { tag: "H3", desc: "it's not that big deal", isVisible: false },
    ]
    public static topCompititorTitle = ['KITO GROUP | Global Site', 'Kito (musician) - Wikipedia', 'Home – KITO - Lifting Expectations', 'Kito | Spotify', 'Stream Kito music | Listen to songs, albums, playlists for free on SoundCloud', 'KITO Weissenfels - Lifting Chains and Accessories', 'Home – KITO - Lifting Expectations', 'Kito on Apple Music', 'KITO USA | Design | Fabrication | Machining | Mechanical Assembly', 'Kito – SEISMIC TALENT AGENCY']
    public static StructureHeaderName = ['Words', 'Exact keywords', 'Number of Elements', 'Characters'];
    public static keywordHeaderName = ['Title tag keywords', 'H1 tag keywords', 'H2 tag keywords', 'Main content keywords'];
    public static titleHeaderName = ['People also ask', 'Top competitor title'];

    public static statusMenuArray = ['analyzed', 'not_analyzed'];
    public static intentMenuArray = ['high_intent', 'pre_purchase', 'post_purchase', 'comparison'];
    public static analyseMenuArray = ['High - Intent', 'Pre - Purchase', 'Post - Purchase', 'Comparision', 'Analyze Whole keyword report'];

    public static weakSpotMenuArray = ['At least 1 (in top 10)', 'At least 1 (in top 3)', 'At least 1 (with Low DA)', 'No weak spots'];
    public static formusMenuArray = ['At least 1 (in top 3)'];
    public static serpScoreMenuArray = ['High', 'Medium', 'Low'];
    public static termsVisiblity = ['Optimised terms', 'Partially optimised terms', 'Not used terms'];
    public static lckrCoutries = [
        { code: "us", name: 'United States' },
        { code: "af", name: 'Afghanistan' },
        { code: "al", name: 'Albania' },
        { code: "dz", name: 'Algeria' },
        { code: "as", name: 'American Samoa' },
        { code: "ad", name: 'Andorra' },
        { code: "ao", name: 'Angola' },
        { code: "ai", name: 'Anguilla' },
        { code: "aq", name: 'Antarctica' },
        { code: "ag", name: 'Antigua and Barbuda' },
        { code: "ar", name: 'Argentina' },
        { code: "am", name: 'Armenia' },
        { code: "aw", name: 'Aruba' },
        { code: "au", name: 'Australia' },
        { code: "at", name: 'Austria' },
        { code: "az", name: 'Azerbaijan' },
        { code: "bs", name: 'Bahamas' },
        { code: "bh", name: 'Bahrain' },
        { code: "bd", name: 'Bangladesh' },
        { code: "bb", name: 'Barbados' },
        { code: "by", name: 'Belarus' },
        { code: "be", name: 'Belgium' },
        { code: "bz", name: 'Belize' },
        { code: "bj", name: 'Benin' },
        { code: "bm", name: 'Bermuda' },
        { code: "bt", name: 'Bhutan' },
        { code: "bo", name: 'Bolivia' },
        { code: "ba", name: 'Bosnia and Herzegovina' },
        { code: "bw", name: 'Botswana' },
        { code: "bv", name: 'Bouvet Island' },
        { code: "br", name: 'Brazil' },
        { code: "io", name: 'British Indian Ocean Territory' },
        { code: "bn", name: 'Brunei Darussalam' },
        { code: "bg", name: 'Bulgaria' },
        { code: "bf", name: 'Burkina Faso' },
        { code: "bi", name: 'Burundi' },
        { code: "kh", name: 'Cambodia' },
        { code: "cm", name: 'Cameroon' },
        { code: "ca", name: 'Canada' },
        { code: "cv", name: 'Cape Verde' },
        { code: "ky", name: 'Cayman Islands' },
        { code: "cf", name: 'Central African Republic' },
        { code: "td", name: 'Chad' },
        { code: "cl", name: 'Chile' },
        { code: "cn", name: 'China' },
        { code: "cx", name: 'Christmas Island' },
        { code: "cc", name: 'Cocos (Keeling) Islands' },
        { code: "co", name: 'Colombia' },
        { code: "km", name: 'Comoros' },
        { code: "cg", name: 'Congo' },
        { code: "cd", name: 'Congo, the Democratic Republic of the' },
        { code: "ck", name: 'Cook Islands' },
        { code: "cr", name: 'Costa Rica' },
        { code: "ci", name: 'Cote Divoire' },
        { code: "hr", name: 'Croatia' },
        { code: "cu", name: 'Cuba' },
        { code: "cy", name: 'Cyprus' },
        { code: "cz", name: 'Czech Republic' },
        { code: "dk", name: 'Denmark' },
        { code: "dj", name: 'Djibouti' },
        { code: "dm", name: 'Dominica' },
        { code: "do", name: 'Dominican Republic' },
        { code: "ec", name: 'Ecuador' },
        { code: "eg", name: 'Egypt' },
        { code: "sv", name: 'El Salvador' },
        { code: "gq", name: 'Equatorial Guinea' },
        { code: "er", name: 'Eritrea' },
        { code: "ee", name: 'Estonia' },
        { code: "et", name: 'Ethiopia' },
        { code: "fk", name: 'Falkland Islands (Malvinas' },
        { code: "fo", name: 'Faroe Islands' },
        { code: "fj", name: 'Fiji' },
        { code: "fi", name: 'Finland' },
        { code: "fr", name: 'France' },
        { code: "gf", name: 'French Guiana' },
        { code: "pf", name: 'French Polynesia' },
        { code: "tf", name: 'French Southern Territories' },
        { code: "ga", name: 'Gabon' },
        { code: "gm", name: 'Gambia' },
        { code: "ge", name: 'Georgia' },
        { code: "de", name: 'Germany' },
        { code: "gh", name: 'Ghana' },
        { code: "gi", name: 'Gibraltar' },
        { code: "gr", name: 'Greece' },
        { code: "gl", name: 'Greenland' },
        { code: "gd", name: 'Grenada' },
        { code: "gp", name: 'Guadeloupe' },
        { code: "gu", name: 'Guam' },
        { code: "gt", name: 'Guatemala' },
        { code: "gn", name: 'Guinea' },
        { code: "gw", name: 'Guinea-Bissau' },
        { code: "gy", name: 'Guyana' },
        { code: "ht", name: 'Haiti' },
        { code: "hm", name: 'Heard Island and Mcdonald Islands' },
        { code: "va", name: 'Holy See (Vatican City State' },
        { code: "hn", name: 'Honduras' },
        { code: "hk", name: 'Hong Kong' },
        { code: "hu", name: 'Hungary' },
        { code: "is", name: 'Iceland' },
        { code: "in", name: 'India' },
        { code: "id", name: 'Indonesia' },
        { code: "ir", name: 'Iran, Islamic Republic of' },
        { code: "iq", name: 'Iraq' },
        { code: "ie", name: 'Ireland' },
        { code: "il", name: 'Israel' },
        { code: "it", name: 'Italy' },
        { code: "jm", name: 'Jamaica' },
        { code: "jp", name: 'Japan' },
        { code: "jo", name: 'Jordan' },
        { code: "kz", name: 'Kazakhstan' },
        { code: "ke", name: 'Kenya' },
        { code: "ki", name: 'Kiribati' },
        { code: "kp", name: 'Korea, Democratic Peoples Republic of' },
        { code: "kr", name: 'Korea, Republic of' },
        { code: "kw", name: 'Kuwait' },
        { code: "kg", name: 'Kyrgyzstan' },
        { code: "la", name: 'Lao Peoples Democratic Republic' },
        { code: "lv", name: 'Latvia' },
        { code: "lb", name: 'Lebanon' },
        { code: "ls", name: 'Lesotho' },
        { code: "lr", name: 'Liberia' },
        { code: "ly", name: 'Libyan Arab Jamahiriya' },
        { code: "li", name: 'Liechtenstein' },
        { code: "lt", name: 'Lithuania' },
        { code: "lu", name: 'Luxembourg' },
        { code: "mo", name: 'Macao' },
        { code: "mk", name: 'Macedonia, the Former Yugosalv Republic of' },
        { code: "mg", name: 'Madagascar' },
        { code: "mw", name: 'Malawi' },
        { code: "my", name: 'Malaysia' },
        { code: "mv", name: 'Maldives' },
        { code: "ml", name: 'Mali' },
        { code: "mt", name: 'Malta' },
        { code: "mh", name: 'Marshall Islands' },
        { code: "mq", name: 'Martinique' },
        { code: "mr", name: 'Mauritania' },
        { code: "mu", name: 'Mauritius' },
        { code: "yt", name: 'Mayotte' },
        { code: "mx", name: 'Mexico' },
        { code: "fm", name: 'Micronesia, Federated States of' },
        { code: "md", name: 'Moldova, Republic of' },
        { code: "mc", name: 'Monaco' },
        { code: "mn", name: 'Mongolia' },
        { code: "ms", name: 'Montserrat' },
        { code: "ma", name: 'Morocco' },
        { code: "mz", name: 'Mozambique' },
        { code: "mm", name: 'Myanmar' },
        { code: "na", name: 'Namibia' },
        { code: "nr", name: 'Nauru' },
        { code: "np", name: 'Nepal' },
        { code: "nl", name: 'Netherlands' },
        { code: "bq", name: 'Netherlands Antilles' },
        { code: "nc", name: 'New Caledonia' },
        { code: "nz", name: 'New Zealand' },
        { code: "ni", name: 'Nicaragua' },
        { code: "ne", name: 'Niger' },
        { code: "ng", name: 'Nigeria' },
        { code: "nu", name: 'Niue' },
        { code: "nf", name: 'Norfolk Island' },
        { code: "mp", name: 'Northern Mariana Islands' },
        { code: "no", name: 'Norway' },
        { code: "om", name: 'Oman' },
        { code: "pk", name: 'Pakistan' },
        { code: "pw", name: 'Palau' },
        { code: "ps", name: 'Palestinian Territory, Occupied' },
        { code: "pa", name: 'Panama' },
        { code: "pg", name: 'Papua New Guinea' },
        { code: "py", name: 'Paraguay' },
        { code: "pe", name: 'Peru' },
        { code: "ph", name: 'Philippines' },
        { code: "pn", name: 'Pitcairn' },
        { code: "pl", name: 'Poland' },
        { code: "pt", name: 'Portugal' },
        { code: "pr", name: 'Puerto Rico' },
        { code: "qa", name: 'Qatar' },
        { code: "re", name: 'Reunion' },
        { code: "ro", name: 'Romania' },
        { code: "ru", name: 'Russian Federation' },
        { code: "rw", name: 'Rwanda' },
        { code: "sh", name: 'Saint Helena' },
        { code: "kn", name: 'Saint Kitts and Nevis' },
        { code: "lc", name: 'Saint Lucia' },
        { code: "pm", name: 'Saint Pierre and Miquelon' },
        { code: "vc", name: 'Saint Vincent and the Grenadines' },
        { code: "ws", name: 'Samoa' },
        { code: "sm", name: 'San Marino' },
        { code: "st", name: 'Sao Tome and Principe' },
        { code: "sa", name: 'Saudi Arabia' },
        { code: "sn", name: 'Senegal' },
        { code: "rs", name: 'Serbia and Montenegro' },
        { code: "sc", name: 'Seychelles' },
        { code: "sl", name: 'Sierra Leone' },
        { code: "sg", name: 'Singapore' },
        { code: "sk", name: 'Slovakia' },
        { code: "si", name: 'Slovenia' },
        { code: "sb", name: 'Solomon Islands' },
        { code: "so", name: 'Somalia' },
        { code: "za", name: 'South Africa' },
        { code: "gs", name: 'South Georgia and the South Sandwich Islands' },
        { code: "es", name: 'Spain' },
        { code: "lk", name: 'Sri Lanka' },
        { code: "sd", name: 'Sudan' },
        { code: "sr", name: 'Suriname' },
        { code: "sj", name: 'Svalbard and Jan Mayen' },
        { code: "sz", name: 'Swaziland' },
        { code: "se", name: 'Sweden' },
        { code: "ch", name: 'Switzerland' },
        { code: "sy", name: 'Syrian Arab Republic' },
        { code: "tw", name: 'Taiwan, Province of China' },
        { code: "tj", name: 'Tajikistan' },
        { code: "tz", name: 'Tanzania, United Republic of' },
        { code: "th", name: 'Thailand' },
        { code: "tl", name: 'Timor-Leste' },
        { code: "tg", name: 'Togo' },
        { code: "tk", name: 'Tokelau' },
        { code: "to", name: 'Tonga' },
        { code: "tt", name: 'Trinidad and Tobago' },
        { code: "tn", name: 'Tunisia' },
        { code: "tr", name: 'Turkey' },
        { code: "tm", name: 'Turkmenistan' },
        { code: "tc", name: 'Turks and Caicos Islands' },
        { code: "tv", name: 'Tuvalu' },
        { code: "ug", name: 'Uganda' },
        { code: "ua", name: 'Ukraine' },
        { code: "ae", name: 'United Arab Emirates' },
        { code: "gb", name: 'United Kingdom' },
        { code: "um", name: 'United States Minor Outlying Islands' },
        { code: "uy", name: 'Uruguay' },
        { code: "uz", name: 'Uzbekistan' },
        { code: "vu", name: 'Vanuatu' },
        { code: "ve", name: 'Venezuela' },
        { code: "vn", name: 'Viet Nam' },
        { code: "vg", name: 'Virgin Islands, British' },
        { code: "vi", name: 'Virgin Islands, U.S' },
        { code: "wf", name: 'Wallis and Futuna' },
        { code: "eh", name: 'Western Sahara' },
        { code: "ye", name: 'Yemen' },
        { code: "zm", name: 'Zambia' },
        { code: "zw", name: 'Zimbabwe' }
    ]
    public static lckrLanguages = [
        { code: 'en', name: "English" },
        { code: 'nl', name: "Dutch" },
        { code: 'fr', name: "French" },
        { code: 'de', name: "German" },
        { code: 'it', name: "Italian" },
        { code: 'ja', name: "Japanese" },
        { code: 'es', name: "Spanish" },
        { code: 'vi', name: "Vietnamese" },
        { code: 'af', name: "Afrikaans" },
        { code: 'sq', name: "Albanian" },
        { code: 'ar', name: "Arabic" },
        { code: 'az', name: "Azerbaijani" },
        { code: 'eu', name: "Basque" },
        { code: 'be', name: "Belarusian" },
        { code: 'bn', name: "Bengali" },
        { code: 'bs', name: "Bosnian" },
        { code: 'bg', name: "Bulgarian" },
        { code: 'ca', name: "Catalan" },
        { code: 'zh', name: "Chinese" },
        { code: 'hr', name: "Croatian" },
        { code: 'cs', name: "Czech" },
        { code: 'da', name: "Danish" },
        { code: 'eo', name: "Esperanto" },
        { code: 'et', name: "Estonian" },
        { code: 'fo', name: "Faroese" },
        { code: 'fi', name: "Finnish" },
        { code: 'gl', name: "Galician" },
        { code: 'el', name: "Greek" },
        { code: 'gu', name: "Gujarati" },
        { code: 'he', name: "Hebrew" },
        { code: 'hi', name: "Hindi" },
        { code: 'hu', name: "Hungarian" },
        { code: 'is', name: "Icelandic" },
        { code: 'id', name: "Indonesian" },
        { code: 'ga', name: "Irish" },
        { code: 'jv', name: "Javanese" },
        { code: 'kn', name: "Kannada" },
        { code: 'ko', name: "Korean" },
        { code: 'la', name: "Latin" },
        { code: 'lv', name: "Latvian" },
        { code: 'lt', name: "Lithuanian" },
        { code: 'mk', name: "Macedonian" },
        { code: 'ms', name: "Malay" },
        { code: 'ml', name: "Malayalam" },
        { code: 'mt', name: "Maltese" },
        { code: 'mr', name: "Marathi" },
        { code: 'ne', name: "Nepali" },
        { code: 'no', name: "Norwegian" },
        { code: 'ol', name: "Polish" },
        { code: 'pt', name: "Portuguese" },
        { code: 'pa', name: "Punjabi" },
        { code: 'ro', name: "Romanian" },
        { code: 'ru', name: "Russian" },
        { code: 'sr', name: "Serbian" },
        { code: 'sk', name: "Slovak" },
        { code: 'sl', name: "Slovenian" },
        { code: 'sw', name: "Swahili" },
        { code: 'sv', name: "Swedish" },
        { code: 'su', name: "Sundanese" },
        { code: 'th', name: "Thai" },
        { code: 'tr', name: "Turkish" },
        { code: 'uk', name: "Ukrainian" },
        { code: 'cy', name: "Welsh" },
        { code: 'zu', name: "Zulu" },
    ]
    public static gscCountry = [
        {
            "code": "AFG",
            "name": "Afghanistan",
            "eu": false
        },
        {
            "code": "ALA",
            "name": "Åland Islands",
            "eu": false
        },
        {
            "code": "ALB",
            "name": "Albania",
            "eu": false
        },
        {
            "code": "DZA",
            "name": "Algeria",
            "eu": false
        },
        {
            "code": "ASM",
            "name": "American Samoa",
            "eu": false
        },
        {
            "code": "AND",
            "name": "Andorra",
            "eu": false
        },
        {
            "code": "AGO",
            "name": "Angola",
            "eu": false
        },
        {
            "code": "AIA",
            "name": "Anguilla",
            "eu": false
        },
        {
            "code": "ATA",
            "name": "Antarctica",
            "eu": false
        },
        {
            "code": "ATG",
            "name": "Antigua and Barbuda",
            "eu": false
        },
        {
            "code": "ARG",
            "name": "Argentina",
            "eu": false
        },
        {
            "code": "ARM",
            "name": "Armenia",
            "eu": false
        },
        {
            "code": "ABW",
            "name": "Aruba",
            "eu": false
        },
        {
            "code": "AUS",
            "name": "Australia",
            "eu": false
        },
        {
            "code": "AUT",
            "name": "Austria",
            "eu": true
        },
        {
            "code": "AZE",
            "name": "Azerbaijan",
            "eu": false
        },
        {
            "code": "BHS",
            "name": "Bahamas",
            "eu": false
        },
        {
            "code": "BHR",
            "name": "Bahrain",
            "eu": false
        },
        {
            "code": "BGD",
            "name": "Bangladesh",
            "eu": false
        },
        {
            "code": "BRB",
            "name": "Barbados",
            "eu": false
        },
        {
            "code": "BLR",
            "name": "Belarus",
            "eu": false
        },
        {
            "code": "BEL",
            "name": "Belgium",
            "eu": true
        },
        {
            "code": "BLZ",
            "name": "Belize",
            "eu": false
        },
        {
            "code": "BEN",
            "name": "Benin",
            "eu": false
        },
        {
            "code": "BMU",
            "name": "Bermuda",
            "eu": false
        },
        {
            "code": "BTN",
            "name": "Bhutan",
            "eu": false
        },
        {
            "code": "BOL",
            "name": "Bolivia, Plurinational State of",
            "eu": false
        },
        {
            "code": "BES",
            "name": "Bonaire, Sint Eustatius and Saba",
            "eu": false
        },
        {
            "code": "BIH",
            "name": "Bosnia and Herzegovina",
            "eu": false
        },
        {
            "code": "BWA",
            "name": "Botswana",
            "eu": false
        },
        {
            "code": "BVT",
            "name": "Bouvet Island",
            "eu": false
        },
        {
            "code": "BRA",
            "name": "Brazil",
            "eu": false
        },
        {
            "code": "IOT",
            "name": "British Indian Ocean Territory",
            "eu": false
        },
        {
            "code": "BRN",
            "name": "Brunei Darussalam",
            "eu": false
        },
        {
            "code": "BGR",
            "name": "Bulgaria",
            "eu": true
        },
        {
            "code": "BFA",
            "name": "Burkina Faso",
            "eu": false
        },
        {
            "code": "BDI",
            "name": "Burundi",
            "eu": false
        },
        {
            "code": "KHM",
            "name": "Cambodia",
            "eu": false
        },
        {
            "code": "CMR",
            "name": "Cameroon",
            "eu": false
        },
        {
            "code": "CAN",
            "name": "Canada",
            "eu": false
        },
        {
            "code": "CPV",
            "name": "Cape Verde",
            "eu": false
        },
        {
            "code": "CYM",
            "name": "Cayman Islands",
            "eu": false
        },
        {
            "code": "CAF",
            "name": "Central African Republic",
            "eu": false
        },
        {
            "code": "TCD",
            "name": "Chad",
            "eu": false
        },
        {
            "code": "CHL",
            "name": "Chile",
            "eu": false
        },
        {
            "code": "CHN",
            "name": "China",
            "eu": false
        },
        {
            "code": "CXR",
            "name": "Christmas Island",
            "eu": false
        },
        {
            "code": "CCK",
            "name": "Cocos (Keeling) Islands",
            "eu": false
        },
        {
            "code": "COL",
            "name": "Colombia",
            "eu": false
        },
        {
            "code": "COM",
            "name": "Comoros",
            "eu": false
        },
        {
            "code": "COG",
            "name": "Congo",
            "eu": false
        },
        {
            "code": "COD",
            "name": "Congo, the Democratic Republic of the",
            "eu": false
        },
        {
            "code": "COK",
            "name": "Cook Islands",
            "eu": false
        },
        {
            "code": "CRI",
            "name": "Costa Rica",
            "eu": false
        },
        {
            "code": "CIV",
            "name": "Côte d'Ivoire",
            "eu": false
        },
        {
            "code": "HRV",
            "name": "Croatia",
            "eu": true
        },
        {
            "code": "CUB",
            "name": "Cuba",
            "eu": false
        },
        {
            "code": "CUW",
            "name": "Curaçao",
            "eu": false
        },
        {
            "code": "CYP",
            "name": "Cyprus",
            "eu": true
        },
        {
            "code": "CZE",
            "name": "Czech Republic",
            "eu": true
        },
        {
            "code": "DNK",
            "name": "Denmark",
            "eu": true
        },
        {
            "code": "DJI",
            "name": "Djibouti",
            "eu": false
        },
        {
            "code": "DMA",
            "name": "Dominica",
            "eu": false
        },
        {
            "code": "DOM",
            "name": "Dominican Republic",
            "eu": false
        },
        {
            "code": "ECU",
            "name": "Ecuador",
            "eu": false
        },
        {
            "code": "EGY",
            "name": "Egypt",
            "eu": false
        },
        {
            "code": "SLV",
            "name": "El Salvador",
            "eu": false
        },
        {
            "code": "GNQ",
            "name": "Equatorial Guinea",
            "eu": false
        },
        {
            "code": "ERI",
            "name": "Eritrea",
            "eu": false
        },
        {
            "code": "EST",
            "name": "Estonia",
            "eu": true
        },
        {
            "code": "ETH",
            "name": "Ethiopia",
            "eu": false
        },
        {
            "code": "FLK",
            "name": "Falkland Islands (Malvinas)",
            "eu": false
        },
        {
            "code": "FRO",
            "name": "Faroe Islands",
            "eu": false
        },
        {
            "code": "FJI",
            "name": "Fiji",
            "eu": false
        },
        {
            "code": "FIN",
            "name": "Finland",
            "eu": true
        },
        {
            "code": "FRA",
            "name": "France",
            "eu": true
        },
        {
            "code": "GUF",
            "name": "French Guiana",
            "eu": false
        },
        {
            "code": "PYF",
            "name": "French Polynesia",
            "eu": false
        },
        {
            "code": "ATF",
            "name": "French Southern Territories",
            "eu": false
        },
        {
            "code": "GAB",
            "name": "Gabon",
            "eu": false
        },
        {
            "code": "GMB",
            "name": "Gambia",
            "eu": false
        },
        {
            "code": "GEO",
            "name": "Georgia",
            "eu": false
        },
        {
            "code": "DEU",
            "name": "Germany",
            "eu": true
        },
        {
            "code": "GHA",
            "name": "Ghana",
            "eu": false
        },
        {
            "code": "GIB",
            "name": "Gibraltar",
            "eu": false
        },
        {
            "code": "GRC",
            "name": "Greece",
            "eu": true
        },
        {
            "code": "GRL",
            "name": "Greenland",
            "eu": false
        },
        {
            "code": "GRD",
            "name": "Grenada",
            "eu": false
        },
        {
            "code": "GLP",
            "name": "Guadeloupe",
            "eu": false
        },
        {
            "code": "GUM",
            "name": "Guam",
            "eu": false
        },
        {
            "code": "GTM",
            "name": "Guatemala",
            "eu": false
        },
        {
            "code": "GGY",
            "name": "Guernsey",
            "eu": false
        },
        {
            "code": "GIN",
            "name": "Guinea",
            "eu": false
        },
        {
            "code": "GNB",
            "name": "Guinea-Bissau",
            "eu": false
        },
        {
            "code": "GUY",
            "name": "Guyana",
            "eu": false
        },
        {
            "code": "HTI",
            "name": "Haiti",
            "eu": false
        },
        {
            "code": "HMD",
            "name": "Heard Island and McDonald Islands",
            "eu": false
        },
        {
            "code": "VAT",
            "name": "Holy See (Vatican City State)",
            "eu": false
        },
        {
            "code": "HND",
            "name": "Honduras",
            "eu": false
        },
        {
            "code": "HKG",
            "name": "Hong Kong",
            "eu": false
        },
        {
            "code": "HUN",
            "name": "Hungary",
            "eu": true
        },
        {
            "code": "ISL",
            "name": "Iceland",
            "eu": false
        },
        {
            "code": "IND",
            "name": "India",
            "eu": false
        },
        {
            "code": "IDN",
            "name": "Indonesia",
            "eu": false
        },
        {
            "code": "IRN",
            "name": "Iran, Islamic Republic of",
            "eu": false
        },
        {
            "code": "IRQ",
            "name": "Iraq",
            "eu": false
        },
        {
            "code": "IRL",
            "name": "Ireland",
            "eu": true
        },
        {
            "code": "IMN",
            "name": "Isle of Man",
            "eu": false
        },
        {
            "code": "ISR",
            "name": "Israel",
            "eu": false
        },
        {
            "code": "ITA",
            "name": "Italy",
            "eu": true
        },
        {
            "code": "JAM",
            "name": "Jamaica",
            "eu": false
        },
        {
            "code": "JPN",
            "name": "Japan",
            "eu": false
        },
        {
            "code": "JEY",
            "name": "Jersey",
            "eu": false
        },
        {
            "code": "JOR",
            "name": "Jordan",
            "eu": false
        },
        {
            "code": "KAZ",
            "name": "Kazakhstan",
            "eu": false
        },
        {
            "code": "KEN",
            "name": "Kenya",
            "eu": false
        },
        {
            "code": "KIR",
            "name": "Kiribati",
            "eu": false
        },
        {
            "code": "PRK",
            "name": "Korea, Democratic People's Republic of",
            "eu": false
        },
        {
            "code": "KOR",
            "name": "Korea, Republic of",
            "eu": false
        },
        {
            "code": "KWT",
            "name": "Kuwait",
            "eu": false
        },
        {
            "code": "KGZ",
            "name": "Kyrgyzstan",
            "eu": false
        },
        {
            "code": "LAO",
            "name": "Lao People's Democratic Republic",
            "eu": false
        },
        {
            "code": "LVA",
            "name": "Latvia",
            "eu": true
        },
        {
            "code": "LBN",
            "name": "Lebanon",
            "eu": false
        },
        {
            "code": "LSO",
            "name": "Lesotho",
            "eu": false
        },
        {
            "code": "LBR",
            "name": "Liberia",
            "eu": false
        },
        {
            "code": "LBY",
            "name": "Libya",
            "eu": false
        },
        {
            "code": "LIE",
            "name": "Liechtenstein",
            "eu": false
        },
        {
            "code": "LTU",
            "name": "Lithuania",
            "eu": true
        },
        {
            "code": "LUX",
            "name": "Luxembourg",
            "eu": true
        },
        {
            "code": "MAC",
            "name": "Macao",
            "eu": false
        },
        {
            "code": "MKD",
            "name": "Macedonia, the former Yugoslav Republic of",
            "eu": false
        },
        {
            "code": "MDG",
            "name": "Madagascar",
            "eu": false
        },
        {
            "code": "MWI",
            "name": "Malawi",
            "eu": false
        },
        {
            "code": "MYS",
            "name": "Malaysia",
            "eu": false
        },
        {
            "code": "MDV",
            "name": "Maldives",
            "eu": false
        },
        {
            "code": "MLI",
            "name": "Mali",
            "eu": false
        },
        {
            "code": "MLT",
            "name": "Malta",
            "eu": true
        },
        {
            "code": "MHL",
            "name": "Marshall Islands",
            "eu": false
        },
        {
            "code": "MTQ",
            "name": "Martinique",
            "eu": false
        },
        {
            "code": "MRT",
            "name": "Mauritania",
            "eu": false
        },
        {
            "code": "MUS",
            "name": "Mauritius",
            "eu": false
        },
        {
            "code": "MYT",
            "name": "Mayotte",
            "eu": false
        },
        {
            "code": "MEX",
            "name": "Mexico",
            "eu": false
        },
        {
            "code": "FSM",
            "name": "Micronesia, Federated States of",
            "eu": false
        },
        {
            "code": "MDA",
            "name": "Moldova, Republic of",
            "eu": false
        },
        {
            "code": "MCO",
            "name": "Monaco",
            "eu": false
        },
        {
            "code": "MNG",
            "name": "Mongolia",
            "eu": false
        },
        {
            "code": "MNE",
            "name": "Montenegro",
            "eu": false
        },
        {
            "code": "MSR",
            "name": "Montserrat",
            "eu": false
        },
        {
            "code": "MAR",
            "name": "Morocco",
            "eu": false
        },
        {
            "code": "MOZ",
            "name": "Mozambique",
            "eu": false
        },
        {
            "code": "MMR",
            "name": "Myanmar",
            "eu": false
        },
        {
            "code": "NAM",
            "name": "Namibia",
            "eu": false
        },
        {
            "code": "NRU",
            "name": "Nauru",
            "eu": false
        },
        {
            "code": "NPL",
            "name": "Nepal",
            "eu": false
        },
        {
            "code": "NLD",
            "name": "Netherlands",
            "eu": true
        },
        {
            "code": "NCL",
            "name": "New Caledonia",
            "eu": false
        },
        {
            "code": "NZL",
            "name": "New Zealand",
            "eu": false
        },
        {
            "code": "NIC",
            "name": "Nicaragua",
            "eu": false
        },
        {
            "code": "NER",
            "name": "Niger",
            "eu": false
        },
        {
            "code": "NGA",
            "name": "Nigeria",
            "eu": false
        },
        {
            "code": "NIU",
            "name": "Niue",
            "eu": false
        },
        {
            "code": "NFK",
            "name": "Norfolk Island",
            "eu": false
        },
        {
            "code": "MNP",
            "name": "Northern Mariana Islands",
            "eu": false
        },
        {
            "code": "NOR",
            "name": "Norway",
            "eu": false
        },
        {
            "code": "OMN",
            "name": "Oman",
            "eu": false
        },
        {
            "code": "PAK",
            "name": "Pakistan",
            "eu": false
        },
        {
            "code": "PLW",
            "name": "Palau",
            "eu": false
        },
        {
            "code": "PSE",
            "name": "Palestinian Territory, Occupied",
            "eu": false
        },
        {
            "code": "PAN",
            "name": "Panama",
            "eu": false
        },
        {
            "code": "PNG",
            "name": "Papua New Guinea",
            "eu": false
        },
        {
            "code": "PRY",
            "name": "Paraguay",
            "eu": false
        },
        {
            "code": "PER",
            "name": "Peru",
            "eu": false
        },
        {
            "code": "PHL",
            "name": "Philippines",
            "eu": false
        },
        {
            "code": "PCN",
            "name": "Pitcairn",
            "eu": false
        },
        {
            "code": "POL",
            "name": "Poland",
            "eu": true
        },
        {
            "code": "PRT",
            "name": "Portugal",
            "eu": true
        },
        {
            "code": "PRI",
            "name": "Puerto Rico",
            "eu": false
        },
        {
            "code": "QAT",
            "name": "Qatar",
            "eu": false
        },
        {
            "code": "REU",
            "name": "Réunion",
            "eu": false
        },
        {
            "code": "ROU",
            "name": "Romania",
            "eu": true
        },
        {
            "code": "RUS",
            "name": "Russian Federation",
            "eu": false
        },
        {
            "code": "RWA",
            "name": "Rwanda",
            "eu": false
        },
        {
            "code": "BLM",
            "name": "Saint Barthélemy",
            "eu": false
        },
        {
            "code": "SHN",
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "eu": false
        },
        {
            "code": "KNA",
            "name": "Saint Kitts and Nevis",
            "eu": false
        },
        {
            "code": "LCA",
            "name": "Saint Lucia",
            "eu": false
        },
        {
            "code": "MAF",
            "name": "Saint Martin (French part)",
            "eu": false
        },
        {
            "code": "SPM",
            "name": "Saint Pierre and Miquelon",
            "eu": false
        },
        {
            "code": "VCT",
            "name": "Saint Vincent and the Grenadines",
            "eu": false
        },
        {
            "code": "WSM",
            "name": "Samoa",
            "eu": false
        },
        {
            "code": "SMR",
            "name": "San Marino",
            "eu": false
        },
        {
            "code": "STP",
            "name": "Sao Tome and Principe",
            "eu": false
        },
        {
            "code": "SAU",
            "name": "Saudi Arabia",
            "eu": false
        },
        {
            "code": "SEN",
            "name": "Senegal",
            "eu": false
        },
        {
            "code": "SRB",
            "name": "Serbia",
            "eu": false
        },
        {
            "code": "SYC",
            "name": "Seychelles",
            "eu": false
        },
        {
            "code": "SLE",
            "name": "Sierra Leone",
            "eu": false
        },
        {
            "code": "SGP",
            "name": "Singapore",
            "eu": false
        },
        {
            "code": "SXM",
            "name": "Sint Maarten (Dutch part)",
            "eu": false
        },
        {
            "code": "SVK",
            "name": "Slovakia",
            "eu": true
        },
        {
            "code": "SVN",
            "name": "Slovenia",
            "eu": true
        },
        {
            "code": "SLB",
            "name": "Solomon Islands",
            "eu": false
        },
        {
            "code": "SOM",
            "name": "Somalia",
            "eu": false
        },
        {
            "code": "ZAF",
            "name": "South Africa",
            "eu": false
        },
        {
            "code": "SGS",
            "name": "South Georgia and the South Sandwich Islands",
            "eu": false
        },
        {
            "code": "SSD",
            "name": "South Sudan",
            "eu": false
        },
        {
            "code": "ESP",
            "name": "Spain",
            "eu": true
        },
        {
            "code": "LKA",
            "name": "Sri Lanka",
            "eu": false
        },
        {
            "code": "SDN",
            "name": "Sudan",
            "eu": false
        },
        {
            "code": "SUR",
            "name": "Suriname",
            "eu": false
        },
        {
            "code": "SJM",
            "name": "Svalbard and Jan Mayen",
            "eu": false
        },
        {
            "code": "SWZ",
            "name": "Swaziland",
            "eu": false
        },
        {
            "code": "SWE",
            "name": "Sweden",
            "eu": true
        },
        {
            "code": "CHE",
            "name": "Switzerland",
            "eu": false
        },
        {
            "code": "SYR",
            "name": "Syrian Arab Republic",
            "eu": false
        },
        {
            "code": "TWN",
            "name": "Taiwan, Province of China",
            "eu": false
        },
        {
            "code": "TJK",
            "name": "Tajikistan",
            "eu": false
        },
        {
            "code": "TZA",
            "name": "Tanzania, United Republic of",
            "eu": false
        },
        {
            "code": "THA",
            "name": "Thailand",
            "eu": false
        },
        {
            "code": "TLS",
            "name": "Timor-Leste",
            "eu": false
        },
        {
            "code": "TGO",
            "name": "Togo",
            "eu": false
        },
        {
            "code": "TKL",
            "name": "Tokelau",
            "eu": false
        },
        {
            "code": "TON",
            "name": "Tonga",
            "eu": false
        },
        {
            "code": "TTO",
            "name": "Trinidad and Tobago",
            "eu": false
        },
        {
            "code": "TUN",
            "name": "Tunisia",
            "eu": false
        },
        {
            "code": "TUR",
            "name": "Turkey",
            "eu": false
        },
        {
            "code": "TKM",
            "name": "Turkmenistan",
            "eu": false
        },
        {
            "code": "TCA",
            "name": "Turks and Caicos Islands",
            "eu": false
        },
        {
            "code": "TUV",
            "name": "Tuvalu",
            "eu": false
        },
        {
            "code": "UGA",
            "name": "Uganda",
            "eu": false
        },
        {
            "code": "UKR",
            "name": "Ukraine",
            "eu": false
        },
        {
            "code": "ARE",
            "name": "United Arab Emirates",
            "eu": false
        },
        {
            "code": "GBR",
            "name": "United Kingdom",
            "eu": true
        },
        {
            "code": "USA",
            "name": "United States",
            "eu": false
        },
        {
            "code": "UMI",
            "name": "United States Minor Outlying Islands",
            "eu": false
        },
        {
            "code": "URY",
            "name": "Uruguay",
            "eu": false
        },
        {
            "code": "UZB",
            "name": "Uzbekistan",
            "eu": false
        },
        {
            "code": "VUT",
            "name": "Vanuatu",
            "eu": false
        },
        {
            "code": "VEN",
            "name": "Venezuela, Bolivarian Republic of",
            "eu": false
        },
        {
            "code": "VNM",
            "name": "Viet Nam",
            "eu": false
        },
        {
            "code": "VGB",
            "name": "Virgin Islands, British",
            "eu": false
        },
        {
            "code": "VIR",
            "name": "Virgin Islands, U.S.",
            "eu": false
        },
        {
            "code": "WLF",
            "name": "Wallis and Futuna",
            "eu": false
        },
        {
            "code": "ESH",
            "name": "Western Sahara",
            "eu": false
        },
        {
            "code": "YEM",
            "name": "Yemen",
            "eu": false
        },
        {
            "code": "ZMB",
            "name": "Zambia",
            "eu": false
        },
        {
            "code": "ZWE",
            "name": "Zimbabwe",
            "eu": false
        }
    ]
}