<!-- Add URL Modal -->
<div class="p-4" *ngIf="title === 'AddUrlModal'" style="width: 450px;">
  <div class="position-relative">
    <h1 class="text-center fw-bold heading">Import content from a URL</h1>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="position-relative pb-20">
    <input type="text" [formControl]="addUrl" (change)="getLinkPreviewDatas()" class="input w-100 pe-36"
      placeholder="Enter URL">
    <i class="bi bi-x-circle-fill clear-icon" (click)="this.addUrl.setValue('')" *ngIf="addUrl.value !== ''"
      role="button"></i>
  </div>
  <div *ngIf="submitted && addUrl.errors?.required" class="error-text">Please enter url</div>
  <div *ngIf="submitted && addUrl.errors?.pattern" class="error-text">Please enter valid url</div>
  <div class="d-flex mt-4">
    <button type="button" class="btn btn-outline-primary me-1 w-50" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary ms-1 w-50" [disabled]="addUrl.value == ''"
      (click)="onApply('url')">Apply</button>
  </div>
</div>

<div class="p-4" *ngIf="title === 'HardRefresh'" style="width:500px;">
  <div class="">
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()">
      <i class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i>
    </button>
    <div class="">
      <div class="">
        <div class="st-refresh-div">
          <div class="alignCenter">
            <h2 class="alignCenter">Hard Refresh</h2>
            <svg class="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="300" height="375"
              viewBox="0 0 520.52033 575.72373" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path
                d="M637.26637,285.81572a10.85509,10.85509,0,0,1,7.88971,12.92233,10.413,10.413,0,0,1-.545,1.633l27.7671,25.75113L653.52257,330.554l-23.47735-25.13314a10.825,10.825,0,0,1-5.53458-12.00093,10.35947,10.35947,0,0,1,12.47988-7.674Q637.12891,285.77895,637.26637,285.81572Z"
                transform="translate(-339.73984 -162.13813)" fill="#a0616a" />
              <path
                d="M663.213,315.06654l-10.82775,14.6383,27.13151,25.65122a15.61912,15.61912,0,0,0,14.73492,3.801h0a14.96552,14.96552,0,0,0,11.10269-14.157l.71809-59.0455a16.13613,16.13613,0,0,0-14.94676-16.40023h0a15.30328,15.30328,0,0,0-16.43023,14.0865q-.01824.23746-.02908.4754a15.69875,15.69875,0,0,0,.00893,1.69127l3.29941,42.70726Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M585.63363,549.85223l-55.04346-1.22168,21.17725-88.84375L520.7933,374.67645l.0791-.30176L543.7679,286.759l-26.15429-79.75635,50.55859-20.68554,29.81641,103.38281-.07373.271L575.17757,373.1867l30.99219,86.42823-.06494.28418Zm-52.52393-3.167,50.936,1.13184,20.04394-88.07715-31.00781-86.47217,22.82959-83.55322L566.86751,189.01141l-46.77343,19.13672,25.75781,78.54736-.07471.28467-22.89111,87.59863,30.96631,85.08887-.06983.291Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M653.82181,729.96408a48.67531,48.67531,0,0,1-39.25924-14.64528c-6.59053-6.94854-11.11322-15.89234-15.48708-24.54163-1.2732-2.51693-2.58928-5.11971-3.9282-7.61426a15.53411,15.53411,0,0,1,1.61173-17.33177,15.27246,15.27246,0,0,1,16.303-5.20878l.63822.18436,5.74416,22.41411c.85694-3.34324,2.69631-10.87591,3.85784-18.25612l.09757-.61813.572.25474a78.1326,78.1326,0,0,1,16.78623,10.11535,80.52869,80.52869,0,0,1,30.11215,60.64309l.0152.697Z"
                transform="translate(-339.73984 -162.13813)" fill="#f2f2f2" />
              <path
                d="M570.12985,200.22742a26.13241,26.13241,0,0,1-12.48,22.28,19.16943,19.16943,0,0,1-1.77,1,25.77973,25.77973,0,0,1-6.9,2.4q-1.005.195-2.04.3a24.17374,24.17374,0,0,1-2.97.18,25.93485,25.93485,0,0,1-8.36-1.37v-.01a21.238,21.238,0,0,1-2.54-1,26.12972,26.12972,0,0,1-15.23-22.42c-.02-.45-.03-.91-.03-1.36,0-.21,0-.43006.01-.64a26.1589,26.1589,0,0,1,52.31.64Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M597.12985,286.72742c0,.32995-.01.65-.03.98a19.03271,19.03271,0,0,1-.15,2,26.19365,26.19365,0,0,1-19.47,22.36c-.8.2-1.6.37-2.42.5a26.2471,26.2471,0,0,1-4.09.32,25.62741,25.62741,0,0,1-3.74-.27c-.68-.09-1.36-.22-2.02-.37a25.76219,25.76219,0,0,1-9.14-4.01995c-.57-.39-1.13-.81-1.66-1.25a26.14784,26.14784,0,0,1,1.29-41.48005v-.01a20.11762,20.11762,0,0,1,1.74-1.15,25.77892,25.77892,0,0,1,9.69-3.49c.68-.1,1.37-.17,2.06-.21.59-.05,1.18-.07,1.78-.07a26.04836,26.04836,0,0,1,6.35.78,23.391,23.391,0,0,1,2.38.72A26.13718,26.13718,0,0,1,597.12985,286.72742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M574.12985,373.22742a26.13009,26.13009,0,0,1-10.72,21.1,18.97351,18.97351,0,0,1-1.68,1.13,25.24973,25.24973,0,0,1-6.42,2.87q-.975.3-1.98.51a26.45116,26.45116,0,0,1-10.78-.01,23.87626,23.87626,0,0,1-2.55-.68,26.18059,26.18059,0,0,1-18.16-23.52c-.02-.47-.03-.93-.03-1.4,0-.2,0-.4.01-.6a26.12185,26.12185,0,0,1,12.86-21.93,22.07925,22.07925,0,0,1,2.41-1.26,26.02278,26.02278,0,0,1,10.88-2.37c.24,0,.48,0,.72.02a20.15711,20.15711,0,0,1,2.06.13,25.48773,25.48773,0,0,1,7.35,1.89c.63.26,1.24.54,1.84.86A26.13093,26.13093,0,0,1,574.12985,373.22742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M605.12985,459.67737v.05q0,.99-.09,1.95a26.132,26.132,0,0,1-18.91,23.2v.01a22.20579,22.20579,0,0,1-2.28.54,26.12732,26.12732,0,0,1-4.88.46,24.61668,24.61668,0,0,1-3.11-.19c-.67-.07-1.34-.18-2-.31a25.90968,25.90968,0,0,1-9.68-4.08,21.40108,21.40108,0,0,1-1.72-1.29,26.15127,26.15127,0,0,1-2.02-38.75v-.01c.51-.51,1.04-1,1.59-1.46a25.81527,25.81527,0,0,1,8.71-4.9c.64-.22,1.28-.41,1.94-.57a26.04544,26.04544,0,0,1,6.29-.76q.57,0,1.14.03c.78.03,1.56.1,2.32.2v.01h.01A26.1295,26.1295,0,0,1,605.12985,459.67737Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M584.12985,546.22742a26.15956,26.15956,0,0,1-52.28,1.43c-.03-.48-.04-.95-.04-1.43,0-.19,0-.38.01-.57a26.12361,26.12361,0,0,1,13.64-22.41,23.41028,23.41028,0,0,1,2.68005-1.26,25.89582,25.89582,0,0,1,9.83-1.92q.885,0,1.77.06c.7.05,1.39.12,2.07.22a25.43967,25.43967,0,0,1,6.99,2.07q.915.40494,1.8.9A26.1297,26.1297,0,0,1,584.12985,546.22742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M776.85983,181.5274c34.54326,68.68508-34.1762,59.09863-44.57918,58.23171-20.03949-1.66995-37.34407,3.73441-35.47686-42.01207.82009-20.09221,22.83673-35.60891,42.94567-35.60891S767.82487,163.56247,776.85983,181.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <polygon points="428.263 545.794 442.779 545.793 449.685 489.801 428.26 489.802 428.263 545.794"
                fill="#a0616a" />
              <path
                d="M787.34938,709.23878v-9.84126l-3.81631.23312-15.53489.92029-3.59539.22085-2.74865,32.95956-.14724,1.767h10.30746l.33134-1.75472,1.497-7.97607,3.86537,7.97607.84668,1.75472H805.682a6.17461,6.17461,0,0,0,6.13543-6.14771C812.971,723.9638,790.24531,712.3188,787.34938,709.23878Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <polygon points="362.977 545.794 348.461 545.793 341.555 489.801 362.98 489.802 362.977 545.794"
                fill="#a0616a" />
              <path
                d="M683.37028,709.23878v-9.84126l3.81632.23312,15.53488.92029,3.5954.22085,2.74864,32.95956.14725,1.767H698.90531l-.33134-1.75472-1.497-7.97607-3.86537,7.97607-.84668,1.75472H665.0377a6.17461,6.17461,0,0,1-6.13544-6.14771C657.74872,723.9638,680.47436,712.3188,683.37028,709.23878Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M681.05822,427.62509s-9.98527,55.897-4.60859,87.38825c.4102,7.51406-5.43576,30.08985-4.5898,56.51406,1.39053,43.4341,9.599,96.9371,7,109l28-2s.932-11.79615,2.49784-29.88849c.18726-2.16368-2.70271-4.77714-2.49784-7.11151.23909-2.72416,3.57611-5.19842,3.83768-8.12728q.32492-3.638.67165-7.46265c.18386-2.02725-2.7018-3.31543-2.50933-5.41007.23842-2.59472,3.55722-6.01277,3.808-8.69619,1.525-16.315,3.271-34.1192,5.139-51.58371,1.22722-11.4742,2.50706-22.80179,3.81137-33.46414.2829-2.31256-2.04328-5.01128-1.75837-7.256.29124-2.29451,3.1936-4.13334,3.48633-6.34574.41154-3.11029,4.10063-3.73312,4.51367-6.65426.33126-2.34269-2.61364-7.02024-2.2824-9.22308,3.24158-21.55753,6.46132-36.03013,9.24749-35.82614,2.29862.16829,4.94121,10.13642,7.6757,25.70712.345,1.96443,4.0118,4.20775,4.35921,6.3421.36053,2.215-2.59827,4.32717-2.23625,6.70663.38512,2.53131-1.14924,5.5979-.76375,8.29337.37242,2.604,2.66471,4.83186,3.03622,7.5683,4.17483,30.75042,8.25376,69.21526,11.36529,100.8841.2434,2.47728,3.36735,5.16044,3.59849,7.5476.24471,2.52726-2.40415,4.75267-2.17414,7.1629.314,3.29031,4.88972,6.80123,5.17414,9.8371.28149,3.00462-3.728,5.537-3.478,8.25154,1.55427,16.87209,2.478,27.74846,2.478,27.74846l28,1s-.96394-156.42855,3.64465-172.17416,1.53621-72.42987-9.98522-78.72815S681.05822,427.62509,681.05822,427.62509Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M720.594,244.04128l-3.40157,17.00787L682.85983,272.5274l12.30106,92.83671-13.24265,60.02438c-3.41565,3.957-4.72113,7.94305-2.64209,11.97564l112.58368,1.16327c2.12024-4.05035,2.84571-8.03936,0-12l-5-8-5-12,1-5-2.52944-10.87181-1.92315-6.80477-3.24635-11.48671,20.19344-93.40348-35.98448-13.943-6.46093-20.97638Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <circle cx="393.39355" cy="45.43746" r="28.08891" fill="#a0616a" />
              <path
                d="M769.62471,196.81344c-3.37029,3.333-6.0871,5.60161-11.20322,5.60161-12.9397,2.9595-25.25391,4.668-36.13039,3.56637a13.4584,13.4584,0,0,0-1.167-8.07568,10.219,10.219,0,0,1-4.20121,7.27274,43.41493,43.41493,0,0,1-8.91588-2.76343,18.59712,18.59712,0,0,1-11.10054-3.65976c-5.48024-23.63874,15.25507-31.73311,36.77458-29.01629a40.98137,40.98137,0,0,1,7.62751-.72824C757.34783,169.01076,767.72018,179.03769,769.62471,196.81344Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M566.3298,314.5274c-.06994.16-.15.31-.23.46l-11.37,22.45c-.84,1.67-1.61,3.36-2.31,5.09h-2.15a62.69379,62.69379,0,0,1,2.67-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M567.51981,256.5274h-2.2a65.62906,65.62906,0,0,0-8.49-13.67,67.94461,67.94461,0,0,1-8.87994-14.33h2.22a65.38433,65.38433,0,0,0,8.22,13.08A67.31,67.31,0,0,1,567.51981,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="231.11 268.389 228.89 268.389 215.37 240.389 217.6 240.389 231.11 268.389"
                fill="#3f3d56" />
              <path
                d="M572.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C572.5798,488.79736,572.63986,488.65741,572.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M582.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C582.5798,488.79736,582.63986,488.65741,582.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M576.3298,314.5274c-.07995.15-.15.31-.23.46l-11.37,22.45q-1.275,2.505-2.31,5.09h-2.16a65.36592,65.36592,0,0,1,2.68-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M577.50986,256.5274h-2.19a65.89031,65.89031,0,0,0-8.49-13.67,67.94461,67.94461,0,0,1-8.87994-14.33h2.21a66.34724,66.34724,0,0,0,8.23,13.08A67.26914,67.26914,0,0,1,577.50986,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="241.11 268.389 238.89 268.389 225.38 240.389 227.6 240.389 241.11 268.389"
                fill="#3f3d56" />
              <path
                d="M559.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C559.5798,488.79736,559.63986,488.65741,559.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M553.3298,314.5274c-.07995.16-.15.31-.23.46l-11.37,22.45q-1.275,2.505-2.3,5.09h-2.16a65.29282,65.29282,0,0,1,2.67-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M554.51981,256.5274h-2.19a65.67307,65.67307,0,0,0-8.5-13.67,68.19775,68.19775,0,0,1-8.87994-14.33h2.21a66.1753,66.1753,0,0,0,8.23,13.08A67.76919,67.76919,0,0,1,554.51981,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="204.6 240.389 218.11 268.389 215.89 268.389 202.37 240.389 204.6 240.389"
                fill="#3f3d56" />
              <path d="M405.73984,191.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="431.42417" y="200.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -342.5941, -155.14593)" fill="#3f3d56" />
              <path d="M424.73984,537.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="450.42417" y="546.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -347.58974, -154.83539)" fill="#3f3d56" />
              <path d="M412.73984,364.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="438.42417" y="373.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -345.09218, -155.02677)" fill="#3f3d56" />
              <path d="M753.97983,279.7074h-53a13,13,0,0,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <polygon points="348.585 128.696 233.117 127.217 233.146 125.217 348.614 126.696 348.585 128.696"
                fill="#3f3d56" />
              <path d="M763.97983,451.7074h-53a13,13,0,0,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="633.08057" y="409.87992" width="2.00021" height="102.42963"
                transform="translate(-175.86445 926.31109) rotate(-89.17239)" fill="#3f3d56" />
              <path
                d="M859.06948,737.55453l-278.75.30734a1.19068,1.19068,0,1,1,0-2.38137l278.75-.30733a1.19068,1.19068,0,1,1,0,2.38136Z"
                transform="translate(-339.73984 -162.13813)" fill="#cacaca" />
              <path
                d="M733.26637,291.81572a10.85509,10.85509,0,0,1,7.88971,12.92233,10.413,10.413,0,0,1-.545,1.633l27.7671,25.75113L749.52257,336.554l-23.47735-25.13314a10.825,10.825,0,0,1-5.53458-12.00093,10.35947,10.35947,0,0,1,12.47988-7.674Q733.12891,291.77895,733.26637,291.81572Z"
                transform="translate(-339.73984 -162.13813)" fill="#a0616a" />
              <path
                d="M759.213,321.06654l-10.82775,14.6383,27.13151,25.65122a15.61912,15.61912,0,0,0,14.73492,3.801h0a14.96552,14.96552,0,0,0,11.10269-14.157l.71809-59.0455a16.13613,16.13613,0,0,0-14.94676-16.40023h0a15.30328,15.30328,0,0,0-16.43023,14.0865q-.01824.23746-.02908.4754a15.69875,15.69875,0,0,0,.00893,1.69127l3.29941,42.70726Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
            </svg>
            <p class="uk-margin-remove-bottom">This option will see for top 10 competitor changes and reflect new
              changes on entire audit accordingly. This will cost you 1 credit.</p>
          </div>
          <div class="alignCenter">
            <button class="btn btn-primary" (click)="onApply('hardRefresh')">Refresh <span
                class="arrow-right"></span></button><br>
          </div>
        </div>
      </div>
    </div><br>
    <p class="" style="font-weight: 200; font-size: 12px; color: #555;">Note: You wont be able to see the current audit
      until the new audit is refreshed.</p>
  </div>
</div>

<div class="p-4" *ngIf="title === 'auditHardRefresh'" style="width:950px;">
  <div class="">
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    <div class="" style="display: flex;">
      <div class="">
        <div class="">
          <div class="alignCenter">
            <h2 class="uk-margin-remove">Hard Refresh</h2>
            <svg class="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="300" height="375"
              viewBox="0 0 520.52033 575.72373" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path
                d="M637.26637,285.81572a10.85509,10.85509,0,0,1,7.88971,12.92233,10.413,10.413,0,0,1-.545,1.633l27.7671,25.75113L653.52257,330.554l-23.47735-25.13314a10.825,10.825,0,0,1-5.53458-12.00093,10.35947,10.35947,0,0,1,12.47988-7.674Q637.12891,285.77895,637.26637,285.81572Z"
                transform="translate(-339.73984 -162.13813)" fill="#a0616a" />
              <path
                d="M663.213,315.06654l-10.82775,14.6383,27.13151,25.65122a15.61912,15.61912,0,0,0,14.73492,3.801h0a14.96552,14.96552,0,0,0,11.10269-14.157l.71809-59.0455a16.13613,16.13613,0,0,0-14.94676-16.40023h0a15.30328,15.30328,0,0,0-16.43023,14.0865q-.01824.23746-.02908.4754a15.69875,15.69875,0,0,0,.00893,1.69127l3.29941,42.70726Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M585.63363,549.85223l-55.04346-1.22168,21.17725-88.84375L520.7933,374.67645l.0791-.30176L543.7679,286.759l-26.15429-79.75635,50.55859-20.68554,29.81641,103.38281-.07373.271L575.17757,373.1867l30.99219,86.42823-.06494.28418Zm-52.52393-3.167,50.936,1.13184,20.04394-88.07715-31.00781-86.47217,22.82959-83.55322L566.86751,189.01141l-46.77343,19.13672,25.75781,78.54736-.07471.28467-22.89111,87.59863,30.96631,85.08887-.06983.291Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M653.82181,729.96408a48.67531,48.67531,0,0,1-39.25924-14.64528c-6.59053-6.94854-11.11322-15.89234-15.48708-24.54163-1.2732-2.51693-2.58928-5.11971-3.9282-7.61426a15.53411,15.53411,0,0,1,1.61173-17.33177,15.27246,15.27246,0,0,1,16.303-5.20878l.63822.18436,5.74416,22.41411c.85694-3.34324,2.69631-10.87591,3.85784-18.25612l.09757-.61813.572.25474a78.1326,78.1326,0,0,1,16.78623,10.11535,80.52869,80.52869,0,0,1,30.11215,60.64309l.0152.697Z"
                transform="translate(-339.73984 -162.13813)" fill="#f2f2f2" />
              <path
                d="M570.12985,200.22742a26.13241,26.13241,0,0,1-12.48,22.28,19.16943,19.16943,0,0,1-1.77,1,25.77973,25.77973,0,0,1-6.9,2.4q-1.005.195-2.04.3a24.17374,24.17374,0,0,1-2.97.18,25.93485,25.93485,0,0,1-8.36-1.37v-.01a21.238,21.238,0,0,1-2.54-1,26.12972,26.12972,0,0,1-15.23-22.42c-.02-.45-.03-.91-.03-1.36,0-.21,0-.43006.01-.64a26.1589,26.1589,0,0,1,52.31.64Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M597.12985,286.72742c0,.32995-.01.65-.03.98a19.03271,19.03271,0,0,1-.15,2,26.19365,26.19365,0,0,1-19.47,22.36c-.8.2-1.6.37-2.42.5a26.2471,26.2471,0,0,1-4.09.32,25.62741,25.62741,0,0,1-3.74-.27c-.68-.09-1.36-.22-2.02-.37a25.76219,25.76219,0,0,1-9.14-4.01995c-.57-.39-1.13-.81-1.66-1.25a26.14784,26.14784,0,0,1,1.29-41.48005v-.01a20.11762,20.11762,0,0,1,1.74-1.15,25.77892,25.77892,0,0,1,9.69-3.49c.68-.1,1.37-.17,2.06-.21.59-.05,1.18-.07,1.78-.07a26.04836,26.04836,0,0,1,6.35.78,23.391,23.391,0,0,1,2.38.72A26.13718,26.13718,0,0,1,597.12985,286.72742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M574.12985,373.22742a26.13009,26.13009,0,0,1-10.72,21.1,18.97351,18.97351,0,0,1-1.68,1.13,25.24973,25.24973,0,0,1-6.42,2.87q-.975.3-1.98.51a26.45116,26.45116,0,0,1-10.78-.01,23.87626,23.87626,0,0,1-2.55-.68,26.18059,26.18059,0,0,1-18.16-23.52c-.02-.47-.03-.93-.03-1.4,0-.2,0-.4.01-.6a26.12185,26.12185,0,0,1,12.86-21.93,22.07925,22.07925,0,0,1,2.41-1.26,26.02278,26.02278,0,0,1,10.88-2.37c.24,0,.48,0,.72.02a20.15711,20.15711,0,0,1,2.06.13,25.48773,25.48773,0,0,1,7.35,1.89c.63.26,1.24.54,1.84.86A26.13093,26.13093,0,0,1,574.12985,373.22742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M605.12985,459.67737v.05q0,.99-.09,1.95a26.132,26.132,0,0,1-18.91,23.2v.01a22.20579,22.20579,0,0,1-2.28.54,26.12732,26.12732,0,0,1-4.88.46,24.61668,24.61668,0,0,1-3.11-.19c-.67-.07-1.34-.18-2-.31a25.90968,25.90968,0,0,1-9.68-4.08,21.40108,21.40108,0,0,1-1.72-1.29,26.15127,26.15127,0,0,1-2.02-38.75v-.01c.51-.51,1.04-1,1.59-1.46a25.81527,25.81527,0,0,1,8.71-4.9c.64-.22,1.28-.41,1.94-.57a26.04544,26.04544,0,0,1,6.29-.76q.57,0,1.14.03c.78.03,1.56.1,2.32.2v.01h.01A26.1295,26.1295,0,0,1,605.12985,459.67737Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M584.12985,546.22742a26.15956,26.15956,0,0,1-52.28,1.43c-.03-.48-.04-.95-.04-1.43,0-.19,0-.38.01-.57a26.12361,26.12361,0,0,1,13.64-22.41,23.41028,23.41028,0,0,1,2.68005-1.26,25.89582,25.89582,0,0,1,9.83-1.92q.885,0,1.77.06c.7.05,1.39.12,2.07.22a25.43967,25.43967,0,0,1,6.99,2.07q.915.40494,1.8.9A26.1297,26.1297,0,0,1,584.12985,546.22742Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <path
                d="M776.85983,181.5274c34.54326,68.68508-34.1762,59.09863-44.57918,58.23171-20.03949-1.66995-37.34407,3.73441-35.47686-42.01207.82009-20.09221,22.83673-35.60891,42.94567-35.60891S767.82487,163.56247,776.85983,181.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <polygon points="428.263 545.794 442.779 545.793 449.685 489.801 428.26 489.802 428.263 545.794"
                fill="#a0616a" />
              <path
                d="M787.34938,709.23878v-9.84126l-3.81631.23312-15.53489.92029-3.59539.22085-2.74865,32.95956-.14724,1.767h10.30746l.33134-1.75472,1.497-7.97607,3.86537,7.97607.84668,1.75472H805.682a6.17461,6.17461,0,0,0,6.13543-6.14771C812.971,723.9638,790.24531,712.3188,787.34938,709.23878Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <polygon points="362.977 545.794 348.461 545.793 341.555 489.801 362.98 489.802 362.977 545.794"
                fill="#a0616a" />
              <path
                d="M683.37028,709.23878v-9.84126l3.81632.23312,15.53488.92029,3.5954.22085,2.74864,32.95956.14725,1.767H698.90531l-.33134-1.75472-1.497-7.97607-3.86537,7.97607-.84668,1.75472H665.0377a6.17461,6.17461,0,0,1-6.13544-6.14771C657.74872,723.9638,680.47436,712.3188,683.37028,709.23878Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M681.05822,427.62509s-9.98527,55.897-4.60859,87.38825c.4102,7.51406-5.43576,30.08985-4.5898,56.51406,1.39053,43.4341,9.599,96.9371,7,109l28-2s.932-11.79615,2.49784-29.88849c.18726-2.16368-2.70271-4.77714-2.49784-7.11151.23909-2.72416,3.57611-5.19842,3.83768-8.12728q.32492-3.638.67165-7.46265c.18386-2.02725-2.7018-3.31543-2.50933-5.41007.23842-2.59472,3.55722-6.01277,3.808-8.69619,1.525-16.315,3.271-34.1192,5.139-51.58371,1.22722-11.4742,2.50706-22.80179,3.81137-33.46414.2829-2.31256-2.04328-5.01128-1.75837-7.256.29124-2.29451,3.1936-4.13334,3.48633-6.34574.41154-3.11029,4.10063-3.73312,4.51367-6.65426.33126-2.34269-2.61364-7.02024-2.2824-9.22308,3.24158-21.55753,6.46132-36.03013,9.24749-35.82614,2.29862.16829,4.94121,10.13642,7.6757,25.70712.345,1.96443,4.0118,4.20775,4.35921,6.3421.36053,2.215-2.59827,4.32717-2.23625,6.70663.38512,2.53131-1.14924,5.5979-.76375,8.29337.37242,2.604,2.66471,4.83186,3.03622,7.5683,4.17483,30.75042,8.25376,69.21526,11.36529,100.8841.2434,2.47728,3.36735,5.16044,3.59849,7.5476.24471,2.52726-2.40415,4.75267-2.17414,7.1629.314,3.29031,4.88972,6.80123,5.17414,9.8371.28149,3.00462-3.728,5.537-3.478,8.25154,1.55427,16.87209,2.478,27.74846,2.478,27.74846l28,1s-.96394-156.42855,3.64465-172.17416,1.53621-72.42987-9.98522-78.72815S681.05822,427.62509,681.05822,427.62509Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M720.594,244.04128l-3.40157,17.00787L682.85983,272.5274l12.30106,92.83671-13.24265,60.02438c-3.41565,3.957-4.72113,7.94305-2.64209,11.97564l112.58368,1.16327c2.12024-4.05035,2.84571-8.03936,0-12l-5-8-5-12,1-5-2.52944-10.87181-1.92315-6.80477-3.24635-11.48671,20.19344-93.40348-35.98448-13.943-6.46093-20.97638Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
              <circle cx="393.39355" cy="45.43746" r="28.08891" fill="#a0616a" />
              <path
                d="M769.62471,196.81344c-3.37029,3.333-6.0871,5.60161-11.20322,5.60161-12.9397,2.9595-25.25391,4.668-36.13039,3.56637a13.4584,13.4584,0,0,0-1.167-8.07568,10.219,10.219,0,0,1-4.20121,7.27274,43.41493,43.41493,0,0,1-8.91588-2.76343,18.59712,18.59712,0,0,1-11.10054-3.65976c-5.48024-23.63874,15.25507-31.73311,36.77458-29.01629a40.98137,40.98137,0,0,1,7.62751-.72824C757.34783,169.01076,767.72018,179.03769,769.62471,196.81344Z"
                transform="translate(-339.73984 -162.13813)" fill="#2f2e41" />
              <path
                d="M566.3298,314.5274c-.06994.16-.15.31-.23.46l-11.37,22.45c-.84,1.67-1.61,3.36-2.31,5.09h-2.15a62.69379,62.69379,0,0,1,2.67-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M567.51981,256.5274h-2.2a65.62906,65.62906,0,0,0-8.49-13.67,67.94461,67.94461,0,0,1-8.87994-14.33h2.22a65.38433,65.38433,0,0,0,8.22,13.08A67.31,67.31,0,0,1,567.51981,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="231.11 268.389 228.89 268.389 215.37 240.389 217.6 240.389 231.11 268.389"
                fill="#3f3d56" />
              <path
                d="M572.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C572.5798,488.79736,572.63986,488.65741,572.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M582.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C582.5798,488.79736,582.63986,488.65741,582.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M576.3298,314.5274c-.07995.15-.15.31-.23.46l-11.37,22.45q-1.275,2.505-2.31,5.09h-2.16a65.36592,65.36592,0,0,1,2.68-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M577.50986,256.5274h-2.19a65.89031,65.89031,0,0,0-8.49-13.67,67.94461,67.94461,0,0,1-8.87994-14.33h2.21a66.34724,66.34724,0,0,0,8.23,13.08A67.26914,67.26914,0,0,1,577.50986,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="241.11 268.389 238.89 268.389 225.38 240.389 227.6 240.389 241.11 268.389"
                fill="#3f3d56" />
              <path
                d="M559.68985,488.5274h2.15c-.15.39-.3.77-.46,1.16l-11,26.84h-2.16l11.31-27.6C559.5798,488.79736,559.63986,488.65741,559.68985,488.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M553.3298,314.5274c-.07995.16-.15.31-.23.46l-11.37,22.45q-1.275,2.505-2.3,5.09h-2.16a65.29282,65.29282,0,0,1,2.67-6l11.16-22Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <path
                d="M554.51981,256.5274h-2.19a65.67307,65.67307,0,0,0-8.5-13.67,68.19775,68.19775,0,0,1-8.87994-14.33h2.21a66.1753,66.1753,0,0,0,8.23,13.08A67.76919,67.76919,0,0,1,554.51981,256.5274Z"
                transform="translate(-339.73984 -162.13813)" fill="#3f3d56" />
              <polygon points="204.6 240.389 218.11 268.389 215.89 268.389 202.37 240.389 204.6 240.389"
                fill="#3f3d56" />
              <path d="M405.73984,191.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="431.42417" y="200.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -342.5941, -155.14593)" fill="#3f3d56" />
              <path d="M424.73984,537.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="450.42417" y="546.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -347.58974, -154.83539)" fill="#3f3d56" />
              <path d="M412.73984,364.7074h-53a13,13,0,1,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="438.42417" y="373.09463" width="102.42963" height="2.00021"
                transform="matrix(0.9999, -0.01444, 0.01444, 0.9999, -345.09218, -155.02677)" fill="#3f3d56" />
              <path d="M753.97983,279.7074h-53a13,13,0,0,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <polygon points="348.585 128.696 233.117 127.217 233.146 125.217 348.614 126.696 348.585 128.696"
                fill="#3f3d56" />
              <path d="M763.97983,451.7074h-53a13,13,0,0,0,0,26h53a13,13,0,0,0,0-26Z"
                transform="translate(-339.73984 -162.13813)" fill="#5653ff" />
              <rect x="633.08057" y="409.87992" width="2.00021" height="102.42963"
                transform="translate(-175.86445 926.31109) rotate(-89.17239)" fill="#3f3d56" />
              <path
                d="M859.06948,737.55453l-278.75.30734a1.19068,1.19068,0,1,1,0-2.38137l278.75-.30733a1.19068,1.19068,0,1,1,0,2.38136Z"
                transform="translate(-339.73984 -162.13813)" fill="#cacaca" />
              <path
                d="M733.26637,291.81572a10.85509,10.85509,0,0,1,7.88971,12.92233,10.413,10.413,0,0,1-.545,1.633l27.7671,25.75113L749.52257,336.554l-23.47735-25.13314a10.825,10.825,0,0,1-5.53458-12.00093,10.35947,10.35947,0,0,1,12.47988-7.674Q733.12891,291.77895,733.26637,291.81572Z"
                transform="translate(-339.73984 -162.13813)" fill="#a0616a" />
              <path
                d="M759.213,321.06654l-10.82775,14.6383,27.13151,25.65122a15.61912,15.61912,0,0,0,14.73492,3.801h0a14.96552,14.96552,0,0,0,11.10269-14.157l.71809-59.0455a16.13613,16.13613,0,0,0-14.94676-16.40023h0a15.30328,15.30328,0,0,0-16.43023,14.0865q-.01824.23746-.02908.4754a15.69875,15.69875,0,0,0,.00893,1.69127l3.29941,42.70726Z"
                transform="translate(-339.73984 -162.13813)" fill="#e6e6e6" />
            </svg>
            <p class="uk-margin-remove-bottom">This option will see for top 10 competitor changes and reflect new
              changes on entire audit accordingly. This will cost you 1 credit.</p>
          </div>
          <form class="alignCenter">
            <button class="btn btn-primary">Refresh <span uk-icon="arrow-right"></span></button>
          </form>
        </div>
      </div>
      <div class="">
        <div class="">
          <div class="alignCenter">
            <h2 class="uk-margin-remove">Soft Refresh</h2>
            <svg class="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="350" height="375"
              viewBox="0 0 836.00447 437.46152" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="f59d61bb-6173-4011-886f-cab752b15cf5" data-name="Group 14">
                <path id="ea601028-2261-4201-a5c6-6d1fee0209f8-346" data-name="Path 284"
                  d="M994.40227,465.87775a10.13794,10.13794,0,0,1-2.678-15.312l-10.57-77.264,22.019,1.081,3.457,75.363a10.192,10.192,0,0,1-12.228,16.132Z"
                  transform="translate(-181.99777 -231.26924)" fill="#a0616a" />
                <path id="bab8e504-63c3-4a02-b094-c521f4d0da0a-347" data-name="Path 285"
                  d="M1006.19527,375.40676a4.138,4.138,0,0,1-1.681,1.189l-19.788,7.552a4.157,4.157,0,0,1-5.281-2.194l-17.529-39.393a16.46,16.46,0,0,1-.805-11.649,16.0099,16.0099,0,0,1,12.185-10.924,15.26906,15.26906,0,0,1,6.578.093,15.947,15.947,0,0,1,11.461,9.963l15.585,41.2A4.16588,4.16588,0,0,1,1006.19527,375.40676Z"
                  transform="translate(-181.99777 -231.26924)" fill="#ccc" />
                <path id="e3cfda6a-f51e-4c96-8af0-12d99ec512c5-348" data-name="Path 286"
                  d="M903.07927,654.46178h-13.691l-4.966-53.057,18.657.25Z" transform="translate(-181.99777 -231.26924)"
                  fill="#a0616a" />
                <path id="a90fb924-173c-4f4a-b9ae-ede5dff37993-349" data-name="Path 287"
                  d="M879.60925,650.55077h26.4v16.625h-43.028a16.625,16.625,0,0,1,16.625-16.625h.003Z"
                  transform="translate(-181.99777 -231.26924)" fill="#2f2e41" />
                <path id="f7d48866-455f-47a0-876b-9c2c9b7d2f2d-350" data-name="Path 288"
                  d="M966.73323,654.46278h-13.691l-6.513-52.808h20.206Z" transform="translate(-181.99777 -231.26924)"
                  fill="#a0616a" />
                <path id="ae80d259-143f-4a2f-8815-2d5111ab51c8-351" data-name="Path 289"
                  d="M943.26326,650.55077h26.4v16.625h-43.028a16.625,16.625,0,0,1,16.625-16.625h.003Z"
                  transform="translate(-181.99777 -231.26924)" fill="#2f2e41" />
                <path id="b7aaac11-5418-4b5b-a747-2fb27f89cd88-352" data-name="Path 290"
                  d="M968.45525,635.67377l-21.5-1.592a5.032,5.032,0,0,1-4.655-4.768l-4.794-99.088a3.909,3.909,0,0,0-7.733-.6l-20,97.611a5.03194,5.03194,0,0,1-5.324,4l-19.438-1.555a5.02495,5.02495,0,0,1-4.60883-5.40924q.00394-.04944.00885-.09876l18.033-180.326a5.025,5.025,0,0,1,6.068-4.41l68.574,14.907a5.038,5.038,0,0,1,3.957,5l-3.193,171.41192a5.028,5.028,0,0,1-5.013,4.932C968.71025,635.68775,968.58324,635.68275,968.45525,635.67377Z"
                  transform="translate(-181.99777 -231.26924)" fill="#2f2e41" />
                <circle id="eccc2a78-7d3a-4be1-aa48-fb8bb5d1e30a" data-name="Ellipse 40" cx="767.64546" cy="34.37753"
                  r="27.428" fill="#a0616a" />
                <path id="a0a57a86-6e42-4b50-b3a1-f8a38df428dd-353" data-name="Path 291"
                  d="M907.08727,457.75575a24.525,24.525,0,0,1-8.145-18.835c0-39.422,34.123-127.5,35.192-130.243.1-.662,1-.948,5.728-4.139s11.542-3.684,20.257-1.458a5,5,0,0,1,3.629,3.706l1.621,2.342a3.9,3.9,0,0,0,2.27,2.687c7.028,2.961,29.368,16.615,21.755,68-7.071,47.733-9.941,75.8-10.846,85.659a5.015,5.015,0,0,1-4.547,4.54c-3.446.308-9.36.719-16.4.719C941.26628,470.72877,918.86925,468.52876,907.08727,457.75575Z"
                  transform="translate(-181.99777 -231.26924)" fill="#ccc" />
                <path id="e2026d9f-0f48-4627-b939-bcbd235b248e-354" data-name="Path 292"
                  d="M874.31224,443.15074a10.13694,10.13694,0,0,1,6.309-14.207l34.276-70.047,17.682,13.167-39.122,64.505a10.192,10.192,0,0,1-19.143,6.582Z"
                  transform="translate(-181.99777 -231.26924)" fill="#a0616a" />
                <path id="aa5178ad-b05d-4950-85b1-a624717fe499-355" data-name="Path 293"
                  d="M934.51427,374.60176a4.13711,4.13711,0,0,1-2.058.051l-20.64-4.755a4.157,4.157,0,0,1-3.163-4.764l7.4-42.478a16.46109,16.46109,0,0,1,5.822-10.122,16.01107,16.01107,0,0,1,16.205-2.281,15.269,15.269,0,0,1,5.41,3.742,15.947,15.947,0,0,1,3.965,14.659l-10.01709,42.892a4.16594,4.16594,0,0,1-2.924,3.056Z"
                  transform="translate(-181.99777 -231.26924)" fill="#ccc" />
                <path id="f1455aca-238f-491a-b535-1c1a045e39f4-356" data-name="Path 294"
                  d="M943.22124,290.42876a11.82,11.82,0,0,1-3.171-10.482c.956-5,1.518-8.275,1.625-9.466h0c.364-4.073-3.754-7.371-7.828-7.9-.158-.021-.29-.034-.4-.043.1.1.215.206.313.3.774.727,1.737,1.632,1.508,2.566-.138.562-.652.961-1.571,1.218-6.372,1.783-11.28.146-15.445-5.155a12.31284,12.31284,0,0,1-2.351-5.135c-1.492-7.32,2.483-13.56,6.081-17.505,2.967-3.253,7.776-7.2,13.841-7.54,4.716-.263,10.325,2.451,12,7.342a9.112,9.112,0,0,1,7.1-4,16.374,16.374,0,0,1,9.111,2.534c13.3,7.618,19.015,25.766,12.481,39.63-4.11,8.721-13.015,15.213-23.239,16.943a11.61316,11.61316,0,0,1-1.937.164,11.29988,11.29988,0,0,1-8.125-3.467Z"
                  transform="translate(-181.99777 -231.26924)" fill="#2f2e41" />
              </g>
              <path id="f152a38b-6c6f-456c-96ce-4c801960ccf2-357" data-name="Path 336"
                d="M545.65724,426.83875a7.59994,7.59994,0,0,0-6.95-4.65h-138.725a7.524,7.524,0,0,0-7.52,7.52v175.5a7.524,7.524,0,0,0,7.52,7.52h138.73a7.53,7.53,0,0,0,7.52-7.52v-175.5a7.38549,7.38549,0,0,0-.57-2.87Zm-1.1,178.37a5.857,5.857,0,0,1-5.85,5.85h-138.725a5.851,5.851,0,0,1-5.85-5.849v-175.5a5.858,5.858,0,0,1,5.85-5.85h138.73a5.87906,5.87906,0,0,1,5.43,3.68,6.48627,6.48627,0,0,1,.22.66,5.80051,5.80051,0,0,1,.2,1.51Z"
                transform="translate(-181.99777 -231.26924)" fill="#3f3d56" />
              <path id="fabca9b7-6499-499c-b0ba-e512eedfa0e6-358" data-name="Path 337"
                d="M516.70723,463.62375H474.92128a3.343,3.343,0,1,1,0-6.686h41.78595a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#3f3d56" />
              <path id="b1b2178d-f6c7-4a19-857a-cc93cc70df69-359" data-name="Path 338"
                d="M516.70723,481.17377H474.92128a3.343,3.343,0,1,1,0-6.686h41.78595a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#3f3d56" />
              <path id="bd84b5e9-e9c5-4886-bcfd-3c769a5bd20c-360" data-name="Path 339"
                d="M453.48125,491.16675h-30.537a3.765,3.765,0,0,1-3.761-3.761v-36.036a3.765,3.765,0,0,1,3.761-3.761h30.537a3.765,3.765,0,0,1,3.761,3.761v36.036A3.765,3.765,0,0,1,453.48125,491.16675Z"
                transform="translate(-181.99777 -231.26924)" fill="#5653ff" />
              <path id="ad20b6ae-6256-4ad3-9ba5-bff6ef303c78-361" data-name="Path 340"
                d="M516.41725,517.10977h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="f5b31b5a-d389-4394-a0c3-430493f21651-362" data-name="Path 341"
                d="M516.41725,534.65976h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="e6b00d4f-f233-434b-82e7-f520ed55a592-363" data-name="Path 342"
                d="M516.41725,552.20975h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="f83e6214-6629-4e95-ad35-3c9b0c78a3d6-364" data-name="Path 343"
                d="M516.41725,569.75977h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b83f14e7-a754-4235-a578-aebf681d4a1c-365" data-name="Path 344"
                d="M516.41725,587.30976h-94.436a3.343,3.343,0,0,1,0-6.686h94.436a3.343,3.343,0,1,1,0,6.686Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a38c097c-1c01-4356-80b1-56a89c2c58fa-366" data-name="Path 349"
                d="M822.00223,668.73076h195a1,1,0,0,0,0-2h-195a1,1,0,0,0,0,2Z"
                transform="translate(-181.99777 -231.26924)" fill="#3f3d56" />
              <circle id="af1d3ae0-b86a-44f8-8168-f2f371efc620" data-name="Ellipse 44" cx="289.69649" cy="116.37251"
                r="32.262" fill="#5653ff" />
              <path id="b4906b3b-71df-4c7e-84d8-52e6769e6d26-367" data-name="Path 395"
                d="M468.49727,359.94875a3.346,3.346,0,0,1-2.013-.669l-.036-.027-7.582-5.8a3.36939,3.36939,0,1,1,4.1-5.348l4.911,3.766,11.605-15.14a3.368,3.368,0,0,1,4.722-.62372l.001.00074-.072.1.074-.1a3.372,3.372,0,0,1,.623,4.723l-13.65,17.8a3.37,3.37,0,0,1-2.68,1.314Z"
                transform="translate(-181.99777 -231.26924)" fill="#fff" />
              <circle id="eb67bc87-0ab3-400d-9346-8836e9b135a0" data-name="Ellipse 46" cx="77.29649" cy="79.77253"
                r="21.862" fill="#ccc" />
              <path id="a587243e-22a7-4e92-b127-7238b8715868-368" data-name="Path 408"
                d="M257.12726,319.38077a2.268,2.268,0,0,1-1.364-.453l-.024-.018-5.138-3.934a2.283,2.283,0,0,1,2.776-3.624l3.328,2.552,7.864-10.256a2.282,2.282,0,0,1,3.19946-.42242l.00055.00043-.049.066.05-.065a2.285,2.285,0,0,1,.422,3.2l-9.25,12.064a2.283,2.283,0,0,1-1.816.891Z"
                transform="translate(-181.99777 -231.26924)" fill="#fff" />
              <path id="b24c2fd4-5735-4a8b-b246-c12b52e11f5f-369" data-name="Path 410"
                d="M320.72925,362.30178a5.982,5.982,0,0,0-5.468-3.659h-109.161a5.92,5.92,0,0,0-5.917,5.917v138.093a5.92,5.92,0,0,0,5.917,5.917h109.161a5.925,5.925,0,0,0,5.917-5.917v-138.093A5.81028,5.81028,0,0,0,320.72925,362.30178Zm-.865,140.352a4.609,4.609,0,0,1-4.6,4.6h-109.164a4.6,4.6,0,0,1-4.6-4.6h0v-138.094a4.609,4.609,0,0,1,4.6-4.6h109.161a4.626,4.626,0,0,1,4.273,2.9,5.10768,5.10768,0,0,1,.173.519,4.5588,4.5588,0,0,1,.158,1.188Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="f030a160-4767-48dc-a1cd-6e8d6a87c559-370" data-name="Path 411"
                d="M297.94627,391.24677h-32.88a2.63049,2.63049,0,0,1,0-5.261h32.879a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="f0576b91-fb67-46c0-9bc8-b0d78fa4e393-371" data-name="Path 412"
                d="M297.94627,405.05675h-32.88a2.6305,2.6305,0,0,1,0-5.261h32.879a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="bb47cdff-cafb-4bb6-bfa7-d7f244c106f8-372" data-name="Path 413"
                d="M248.19526,412.91976h-24.028a2.962,2.962,0,0,1-2.959-2.959v-28.355a2.962,2.962,0,0,1,2.959-2.959h24.028a2.963,2.963,0,0,1,2.959,2.959v28.355a2.962,2.962,0,0,1-2.959,2.959Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b9c4730d-541e-403a-bfc4-7f597a133cff-373" data-name="Path 414"
                d="M297.71726,433.33276h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b0b4ad5b-1090-4149-ad9f-38443074182d-374" data-name="Path 415"
                d="M297.71726,447.14678h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a36ae105-a865-43f2-a38b-4a3728e49bb5-375" data-name="Path 416"
                d="M297.71726,460.95176h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="bd69ee89-5535-4e79-aba4-c83b03de0083-376" data-name="Path 417"
                d="M297.71726,474.76475h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a075ca9b-1e14-49c6-b2ff-3fb4066a82fb-377" data-name="Path 418"
                d="M297.71726,488.57077h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,1,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b99f005d-5535-4af5-95d9-44a7a4f0c0b8-378" data-name="Path 419"
                d="M182.78925,509.14673h153.434a.787.787,0,0,0,0-1.574h-153.434a.787.787,0,1,0-.00894,1.574Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <circle id="b1c5fac5-a790-403a-b8d0-31ae0bc4b4ba" data-name="Ellipse 46" cx="497.29649" cy="79.77253"
                r="21.862" fill="#ccc" />
              <path id="a5ae1e6c-de0b-426c-a811-1990f7b7ccda-379" data-name="Path 408"
                d="M677.12726,319.38077a2.268,2.268,0,0,1-1.364-.453l-.024-.018-5.138-3.934a2.283,2.283,0,0,1,2.776-3.624l3.328,2.552,7.864-10.256a2.282,2.282,0,0,1,3.19946-.42242l.00055.00043-.049.066.05-.065a2.285,2.285,0,0,1,.422,3.2l-9.25,12.064a2.283,2.283,0,0,1-1.816.891Z"
                transform="translate(-181.99777 -231.26924)" fill="#fff" />
              <path id="e481f25f-f0ef-423d-9e4a-b2d79b6febf5-380" data-name="Path 410"
                d="M740.72925,362.30178a5.982,5.982,0,0,0-5.468-3.659h-109.161a5.92,5.92,0,0,0-5.917,5.917v138.093a5.92,5.92,0,0,0,5.917,5.917h109.161a5.925,5.925,0,0,0,5.917-5.917v-138.093A5.81028,5.81028,0,0,0,740.72925,362.30178Zm-.865,140.352a4.609,4.609,0,0,1-4.6,4.6h-109.164a4.6,4.6,0,0,1-4.6-4.6h0v-138.094a4.609,4.609,0,0,1,4.6-4.6h109.161a4.626,4.626,0,0,1,4.273,2.9,5.10768,5.10768,0,0,1,.173.519,4.5588,4.5588,0,0,1,.158,1.188Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a5b052e7-0a6d-48d5-ba9e-291ff1ca3bfd-381" data-name="Path 411"
                d="M717.94627,391.24677h-32.88a2.63049,2.63049,0,0,1,0-5.261h32.879a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="bd247f09-cb3d-4df5-9c12-787ea9e72b07-382" data-name="Path 412"
                d="M717.94627,405.05675h-32.88a2.6305,2.6305,0,0,1,0-5.261h32.879a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b3d7a89f-9ffc-4079-aa15-40ad611f9a98-383" data-name="Path 413"
                d="M668.19526,412.91976h-24.028a2.962,2.962,0,0,1-2.959-2.959v-28.355a2.962,2.962,0,0,1,2.959-2.959h24.028a2.963,2.963,0,0,1,2.959,2.959v28.355a2.962,2.962,0,0,1-2.959,2.959Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="e1bf4aca-fc8d-43da-a727-289201e2eac7-384" data-name="Path 414"
                d="M717.71726,433.33276h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b286f981-abb8-47cd-9209-f09770ef33ad-385" data-name="Path 415"
                d="M717.71726,447.14678h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="ad15bc08-a918-4115-a327-9a31c97a6753-386" data-name="Path 416"
                d="M717.71726,460.95176h-74.307a2.63049,2.63049,0,0,1,0-5.261h74.307a2.63049,2.63049,0,1,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a508ff04-1989-4f94-915f-90cf16933556-387" data-name="Path 417"
                d="M717.71726,474.76475h-74.307a2.6305,2.6305,0,0,1,0-5.261h74.307a2.6305,2.6305,0,0,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="e65b0501-2d31-4d85-b27f-ea92835ed026-388" data-name="Path 418"
                d="M717.71726,488.57077h-74.307a2.63049,2.63049,0,1,1,0-5.261h74.307a2.63049,2.63049,0,1,1,0,5.261Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="a3e956aa-8abe-48aa-ba4a-17518f20fda0-389" data-name="Path 419"
                d="M602.78925,509.14673h153.434a.787.787,0,0,0,0-1.574h-153.434a.787.787,0,1,0-.00894,1.574Z"
                transform="translate(-181.99777 -231.26924)" fill="#ccc" />
              <path id="b01c4841-6b77-41d3-9bc6-bba4643c130f-390" data-name="Path 420"
                d="M371.00225,613.3281h195a1,1,0,0,0,0-2h-195a1,1,0,0,0,0,2Z"
                transform="translate(-181.99777 -231.26924)" fill="#3f3d56" />
            </svg>
            <p class="uk-margin-remove-bottom">This option will recrawl and update your page contents, and analyse any
              changes reflected on this audit. This costs "0 credits".</p>
          </div>
          <form class="alignCenter">
            <button class="btn btn-primary">Refresh <span uk-icon="arrow-right"></span></button>
          </form>
        </div>
      </div>
    </div>
    <p class="uk-margin-medium-top alignCenter" style="font-weight: 200; font-size: 12px; color: #555;">Note: You wont
      be able to see the current audit until the new audit is refreshed.</p>
  </div>
</div>

<!-- Competitor Setting -->
<div class="p-4 modal-scroll overflow-hidden" *ngIf="title === 'CompetitorSetting' || title==='CompetitorSettingAudit'">
  <div class="position-relative border-bottom pb-3">
    <h1 class="fw-bold m-0 heading">Competitor setting</h1>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="pt-3 overflow-content">
    <table class="hover" [ngClass]="theme ? '': 'dark-table' ">
      <thead class="align-middle">
        <tr class="table-head">
          <th scope="col" class="fw-normal py-0 pe-3 pb-3">Position</th>
          <th scope="col" class="fw-normal py-0 pb-3">Competitor</th>
          <th scope="col" class="fw-normal py-0 w-10 pb-3">{{title === 'CompetitorSetting' ? 'Content score' : 'Page
            Score'}}</th>
          <th scope="col" class="fw-normal py-0 pb-3">Word</th>
          <th scope="col" class="fw-normal py-0 pb-3">State</th>
        </tr>
      </thead>
      <tbody class="align-middle">
        <!-- <tr *ngFor="let data of competitorList;let i = index" [ngClass]="!data.state ? 'disable-row' : ''"> -->
        <tr *ngFor="let data of competitorList;let i = index"
          [ngClass]="competitors.includes(data?.position.toString()) ? '' : 'disable-row'">
          <td class="py-3">
            <div class="index-box d-flex align-items-center justify-content-center">
              {{i+1}}
            </div>
          </td>
          <td>
            <div class="data-heading">{{data?.title?.text[0]}}</div>
            <div class="data-content txt-over w-600">
              {{data.meta_description[0]}}</div>
              <a href="{{data.url}}" class="data-link txt-over w-400 text-decoration-none" target="_blank">{{data.url}}</a>
            <!-- <div class="data-link txt-over w-400">
              {{data.url}}</div> -->
          </td>
          <td class="text-center">
            <div class="rounded-2 px-3 border-0 d-inline-block" [ngClass]="{'chip-red' : (data.page_score > 0 && data.page_score < 3.33 || data.page_score == 0.0),
              'chip-orange' : (data.page_score > 3.34 && data.page_score < 6.67),
              'chip' : (data.page_score > 6.67 && data.page_score < 10)}">
              {{data.page_score}}
            </div>
          </td>
          <td class="text-center data-word">{{data.word_len.body}}</td>
          <td>
            <div class="form-switch ms-3">
              <!-- <input class="form-check-input" type="checkbox" role="button" (change)="changeSwitch(i)"
                [checked]="data.state" id="checkBox"> -->
              <input class="form-check-input" type="checkbox" role="button" (change)="changeSwitch(i)"
                [checked]="data.isChecked" id="checkBox">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex mt-4 justify-content-end">
    <button type="button" class="btn btn-outline-primary me-1 px-5" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary ms-1 px-5" (click)="applyCompetitors()">Apply</button>
  </div>
</div>

<!-- Content Structure -->
<div class="p-4 ContentStructure modal-scroll overflow-hidden" *ngIf="title === 'ContentStructure'">
  <div class="position-relative pb-3 heading-border">
    <h1 class="fw-bold m-0 heading">Content structure setting</h1>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="overflow-content container-fluid">
    <div class="border-bottom py-4">
      <div class="row">
        <div class="col-6 col-xl-4 pt-4 m-0">
          <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
            <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
              <h3 class="m-0 fw-bold heading">Words</h3>
              <a class="text-decoration-none" role="button" (click)="resetValue('defaultValue')">Reset</a>
            </div>
            <!-- <a class="text-decoration-none" role="button" (click)="resetValue('defaultValue')">Reset</a> -->
            <div class="d-flex align-items-center my-3">
              <!-- <label class="Neutral">Value</label> -->
              <div class="d-flex">
                <div class="">
                  <label for="" class="me-1">Min</label>
                  <input type="number" name="min" placeholder="min" [(ngModel)]="defaultValue.min" min="0"
                    class="input me-2 text-end" style="width: 80px;" value="defaultValue">
                </div>
                <div class="">
                  <label for="" class="me-1">Max</label>
                  <input type="number" name="max" placeholder="max" [(ngModel)]="defaultValue.max" min="0"
                    class="input text-end" style="width: 80px;" value="defaultValue">
                </div>
              </div>
              <!-- <div class="icon-btn" role="button" (click)="decrementNumber('defaultValue')">
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="icon-btn ms-2" role="button" (click)="incrementNumber('defaultValue')">
                <i class="bi bi-plus-lg"></i>
              </div> -->
            </div>
            <p class="m-0 fw-normal data-word text-gray fs-14">Competitor value from
              <span class="fw-bold fs-16 data-number Neutral mx-1">{{resetSettings?.body?.min}} </span>to <span
                class="fw-bold fs-16 data-number Neutral mx-1">{{resetSettings?.body?.max}}</span>
            </p>
          </div>

        </div>
        <div class="col-6 col-xl-4 pt-4 m-0">
          <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
            <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
              <h3 class="m-0 fw-bold heading">Paragraphs</h3>
              <a class="text-decoration-none" role="button" (click)="resetValue('paragraphValue')">Reset</a>
            </div>
            <div class="d-flex align-items-center my-3">
              <div class="d-flex">
                <div>
                  <label for="" class="me-1">Min</label>
                  <input type="number" placeholder="min" class="input me-2 text-end" min="0"
                    [(ngModel)]="paragraphValue.min" style="width: 80px;" value="paragraphValue">
                </div>
                <div>
                  <label for="" class="me-1">Max</label>
                  <input type="number" placeholder="max" class="input text-end" min="0" [(ngModel)]="paragraphValue.max"
                    style="width: 80px;" value="paragraphValue">
                </div>
              </div>
              <!-- <div class="icon-btn" role="button" (click)="decrementNumber('paragraphValue')">
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="icon-btn ms-2" role="button" (click)="incrementNumber('paragraphValue')">
                <i class="bi bi-plus-lg"></i>
              </div> -->
            </div>
            <p class="m-0 data-word text-gray fs-14">Competitor value from
              <span class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.paragraph?.min}} </span>to <span
                class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.paragraph?.max}}</span>
            </p>
          </div>
        </div>
        <div class="col-6 col-xl-4 pt-4 m-0">
          <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
            <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
              <h3 class="m-0 fw-bold heading">Images</h3>
              <a class="text-decoration-none" role="button" (click)="resetValue('imageValue')">Reset</a>
            </div>
            <div class="d-flex align-items-center my-3">
              <!-- <label class="Neutral">Value</label> -->
              <div class="d-flex">
                <div>
                  <label for="" class="me-1">Min</label>
                  <input type="number" placeholder="min" class="input me-2 text-end" min="0"
                    min="{{structureSettings?.images?.min}}" style="width: 80px;" [(ngModel)]="imageValue.min"
                    value="imageValue">
                </div>
                <div>
                  <label for="" class="me-1">Max</label>
                  <input type="number" placeholder="max" class="input text-end" min="0"
                    min="{{structureSettings?.images?.min}}" style="width: 80px;" [(ngModel)]="imageValue.max"
                    value="imageValue">
                </div>
              </div>
              <!-- <div class="icon-btn" role="button" (click)="decrementNumber('imageValue')">
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="icon-btn ms-2" role="button" (click)="incrementNumber('imageValue')">
                <i class="bi bi-plus-lg"></i>
              </div> -->
            </div>
            <p class="m-0 data-word fs-14 text-gray">Competitor value from
              <span class="fw-bold data-number fs-16 Neutral mx-1">{{resetSettings?.images?.min}} </span>to <span
                class="fw-bold data-number fs-16 Neutral mx-1">{{resetSettings?.images?.max}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-xl-4 pt-4 m-0">
        <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
          <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 class="m-0 fw-bold heading">H2</h3>
            <a class="text-decoration-none" role="button" (click)="resetValue('h2')">Reset</a>
          </div>
          <div class="d-flex align-items-center my-3">
            <!-- <label class="Neutral">Value</label> -->
            <div class="d-flex">
              <div>
                <label for="" class="me-1">Min</label>
                <input type="number" placeholder="min" class="input me-2 text-end" min="0" [(ngModel)]="h2Value.min"
                  value="headingValue" style="width: 80px;">

              </div>
              <div>
                <label for="" class="me-1">Max</label>
                <input type="number" placeholder="max" class="input text-end" min="0" [(ngModel)]="h2Value.max"
                  value="headingValue" style="width: 80px;">
              </div>
            </div>
            <!-- <div class="icon-btn" role="button" (click)="decrementNumber('headingValue')">
              <i class="bi bi-dash-lg"></i>
            </div>
            <div class="icon-btn ms-2" role="button" (click)="incrementNumber('headingValue')">
              <i class="bi bi-plus-lg"></i>
            </div> -->
          </div>
          <p class="m-0 data-word fs-14 text-gray">Competitor value from
            <span class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h2?.min}} </span>to <span
              class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h2?.max}}</span>
          </p>
        </div>
      </div>
      <div class="col-6 col-xl-4 pt-4 m-0">
        <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
          <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 class="m-0 fw-bold heading">H3</h3>
            <a class="text-decoration-none" role="button" (click)="resetValue('h3')">Reset</a>
          </div>
          <div class="d-flex align-items-center my-3">
            <!-- <label class="Neutral">Value</label> -->
            <div class="d-flex">
              <div>
                <label for="" class="me-1">Min</label>
                <input type="number" placeholder="min" class="input me-2 text-end" min="0" [(ngModel)]="h3Value.min"
                  value="headingValue" style="width: 80px;">

              </div>
              <div>
                <label for="" class="me-1">Max</label>
                <input type="number" placeholder="max" class="input text-end" min="0" [(ngModel)]="h3Value.max"
                  value="headingValue" style="width: 80px;">
              </div>
            </div>
            <!-- <div class="icon-btn" role="button" (click)="decrementNumber('headingValue')">
              <i class="bi bi-dash-lg"></i>
            </div>
            <div class="icon-btn ms-2" role="button" (click)="incrementNumber('headingValue')">
              <i class="bi bi-plus-lg"></i>
            </div> -->
          </div>
          <p class="m-0 data-word fs-14 text-gray">Competitor value from
            <span class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h3?.min}} </span>to <span
              class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h3?.max}}</span>
          </p>
        </div>
      </div>
      <div class="col-6 col-xl-4 pt-4 m-0">
        <div class="w-100 border p-4 rounded-4 d-inline-block bg-light-black">
          <div class="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 class="m-0 fw-bold heading">H4-H6</h3>
            <a class="text-decoration-none" role="button" (click)="resetValue('h46')">Reset</a>
          </div>
          <div class="d-flex align-items-center my-3">
            <!-- <label class="Neutral">Value</label> -->
            <div class="d-flex">
              <div>
                <label for="" class="me-1">Min</label>
                <input type="number" placeholder="min" class="input me-2 text-end" min="0" [(ngModel)]="h46Value.min"
                  value="headingValue" style="width: 80px;">

              </div>
              <div>
                <label for="" class="me-1">Max</label>
                <input type="number" placeholder="max" class="input text-end" min="0" [(ngModel)]="h46Value.max"
                  value="headingValue" style="width: 80px;">
              </div>
            </div>
            <!-- <div class="icon-btn" role="button" (click)="decrementNumber('headingValue')">
              <i class="bi bi-dash-lg"></i>
            </div>
            <div class="icon-btn ms-2" role="button" (click)="incrementNumber('headingValue')">
              <i class="bi bi-plus-lg"></i>
            </div> -->
          </div>
          <p class="m-0 data-word fs-14 text-gray">Competitor value from
            <span class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h46?.min}} </span>to <span
              class="fw-bold data-number Neutral mx-1 fs-16">{{resetSettings?.h46?.max}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mt-4 justify-content-end">
    <button type="button" class="btn btn-outline-primary me-1 px-5" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary ms-1 px-5" (click)="applyStructure()">Apply</button>
  </div>
</div>

<!-- Example -->
<div class="p-4 modal-scroll overflow-hidden" *ngIf="title === 'Example'" style="width: 640px;">
  <div class="position-relative">
    <h1 class="fw-bold m-0 heading">Examples</h1>
    <h3 class="m-0 fs-14 fw-500 heading">Keyword <span
        class="text-primary fs-14 fw-bold light-content">{{keyword.keyword}}</span></h3>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="overflow-content">
    <div class="" *ngFor="let data of keyword.example">
      <div class="example-card mt-4 bg-light-black">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <p class="fs-16 m-0 fw-500 lh-24" [innerHtml]="data.text"></p>
          <h3 class="text-green m-0 fw-bold text-capitalize">{{data.tag}}</h3>
        </div>
        <div class="link">
          <a href="{{data.url}}" target="_blank" class="text-decoration-none fs-16">
            {{data.url}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Term Setting -->
<div class="p-4 term-setting modal-scroll overflow-hidden" *ngIf="title === 'TermSetting'">
  <div class="position-relative heading-border pb-3">
    <h1 class="fw-bold m-0 heading">Terms Setting</h1>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="overflow-content">
    <div class="row mt-3 g-0">
      <div class="col-12">
        <div class="d-flex align-items-center flex-wrap terms-btn">
          <button mat-button role="button" class="btn btn-outline-primary d-flex" [matMenuTriggerFor]="dropDownMenu">
            <i class="bi bi-plus-lg me-1"></i> Add keyword
          </button>

          <button class="btn btn-outline-primary d-flex" (click)="includeKeyword()">
            <i class="bi bi-plus-lg me-1"></i> Include
          </button>
          <mat-menu #dropDownMenu="matMenu" [overlapTrigger]="false">
            <div (click)="$event.stopPropagation()" class="p-3">
              <div class="form-group">
                <input type="text" [(ngModel)]="newKeyword" class="form-control input" placeholder="Enter keyword">
                <button [disabled]="newKeyword.length == 0" class="btn btn-outline-primary mt-3"
                  (click)="addKeyword();">
                  <i class="small bi bi-plus-lg me-1"></i> Add
                </button>
              </div>
            </div>
          </mat-menu>
          <button class="btn btn-outline-primary d-flex" (click)="excludeKeyword()">
            <i class="bi bi-dash-lg me-1"></i> Exclude
          </button>
          <button (click)="mainContentKeyword()" class="btn btn-outline-primary d-flex">
            <span class="me-1">M</span> Main Content
          </button>
          <button (click)="h1Keyword()" class="btn btn-outline-primary d-flex">
            <span class="me-1">H1</span> Heading 1
          </button>
          <button (click)="h2Keyword()" class="btn btn-outline-primary d-flex">
            <span class="me-1">H2</span> Heading 2
          </button>
          <button (click)="titleKeyword()" class="btn btn-outline-primary d-flex">
            <span class="me-1">T</span> Title
          </button>
          <button class="btn btn-outline-primary d-flex" (click)="ignoreKeyword()">
            <i class="bi bi-x-lg me-1"></i> Ignore
          </button>
          <button class="btn btn-outline-primary d-flex" (click)="resetAll()">
            <i class="bi bi-arrow-clockwise me-1"></i> Reset all
          </button>
        </div>
      </div>
      <!-- <div class="col-2">
        <div class="d-flex align-items-center justify-content-end ms-4">
          <label class="me-2 fs-14 fw-500">View</label>
          <mat-form-field appearance="outline" [ngClass]="theme ? '' : 'dark-input'" style="width: 150px !important; font-size: 14px; font-weight: 500;">
            <mat-select value="All" panelClass="{{selectMenu}}">
              <mat-option value="All">All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->


    </div>
    <div class="mt-4">
      <span class="mb-2 fs-12 fw-bold">Included Terms</span>
      <div class="" *ngIf="!isIncludeEmpty">
        <div class="container mt-3 mb-3">
          <div class="card text-center">
            <div class="card-body d-flex justify-content-center align-items-center">
              <h3 class="card-title m-0">There is no included terms</h3>
            </div>
          </div>
        </div>      </div>
      <div class="d-flex align-items-center flex-wrap mt-2 gap-75" *ngIf="isIncludeEmpty">
        <div *ngFor="let data of termsSettings;let i=index">
          <div *ngIf="!data.type || data.type == 'included'" class="bg-gray d-flex align-items-center rounded btn-padd"
            role="button" [ngClass]="data.isSelected ? 'keyWord-border selected-key' : 'border-gray'"
            (click)="getBorder('included',i)">
            <!-- <mat-icon class="text-light-green me-1">add_circle_outline</mat-icon> -->
            <div class="me-2"><img src="../../../assets/icons/plus-circle.svg" /></div>
            <div class="bg-primary text-white txt-round d-flex align-items-center fs-12 me-2 pd-5"
              *ngIf="data.isMainContent">M</div>
            <div class="bg-red text-white txt-round d-flex align-items-center fs-12 me-2 pd-5" *ngIf="data.isH1">H1
            </div>
            <div class="bg-red text-white txt-round d-flex align-items-center fs-12 me-2 pd-5" *ngIf="data.isH2">H2
            </div>
            <div class="bg-success text-white txt-round d-flex align-items-center fs-12 me-2 pd-5" *ngIf="data.isTitle">
              T</div>
            <!-- <img src="../../../assets/icons/Heading mark.svg" *ngIf="data.isH" /> -->
            <span class="fs-14 fw-600" [ngClass]="data.isSelected ? 'text-white' : 'text-dark-purple light-purple'">{{data.keywordName}}</span>
            <!-- <i class="bi bi-h-circle-fill fs-5 text-orange" *ngIf="data.isHAvailable"></i> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <h3 class="mb-2 heading">Excluded Terms</h3>
      <div class="d-flex align-items-center flex-wrap">
        <div class="" *ngIf="!isExcludeEmpty">
          <div class="container mt-3 mb-3">
            <div class="card text-center">
              <div class="card-body d-flex justify-content-center align-items-center">
                <h3 class="card-title m-0">There is no excluded terms</h3>
              </div>
            </div>
          </div>        </div>
        <div class="d-flex align-items-center flex-wrap mt-2" *ngIf="isExcludeEmpty">
          <div *ngFor="let data of termsSettings;let i=index">
            <div *ngIf="data.type && data.type == 'excluded'" class="mb-2">
              <div class="bg-exculde d-flex align-items-center px-2 rounded-3 me-2 cursor-pointer"
                [ngClass]="data.isSelected ? 'keyWord-border selected-key' : 'border-exclude'" style="padding: 6px;"
                (click)="getBorder('excluded',i)" *ngIf="!data.isDisabled">
                <span class="mx-1" [ngClass]="data.isSelected ? 'text-white' : ''">{{data.keywordName}}</span>
              </div>
              <!-- <div class="bg-exculde d-flex align-items-center px-2 rounded-3 me-2 py-1 cursor-not-allowed"
              (click)="getBorder('excluded',i)" *ngIf="data.isDisabled">
              <div class="d-flex align-items-center justify-content-center person-dash-box">
                <i class="bi bi-person-dash-fill"></i>
              </div>
              <span class="me-1 ms-1 text-grey">{{data.keywordName}}</span>
            </div>
          
            </div> -->
            </div>
          </div>
        </div>

      </div>
      <h3 class="mb-2 heading">Ignored Terms</h3>
      <div class="d-flex align-items-center flex-wrap">
        <div class="" *ngIf="!isIgnoreEmpty">
          <div class="container mt-3">
            <div class="card text-center">
              <div class="card-body d-flex justify-content-center align-items-center">
                <h3 class="card-title m-0">There is no ignored terms</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center flex-wrap mt-2" *ngIf="isIgnoreEmpty">
          <div *ngFor="let data of termsSettings;let i=index">
            <div *ngIf="data.type && data.type == 'ignored'" class="mb-2">
              <div class="bg-exculde d-flex align-items-center px-2 rounded-3 me-2 cursor-pointer"
                [ngClass]="data.isSelected ? 'keyWord-border selected-key' : 'border-exclude'" style="padding: 6px;"
                (click)="getBorder('ignored',i)" *ngIf="!data.isDisabled">
                <span class="mx-1" [ngClass]="data.isSelected ? 'text-white' : ''">{{data.keywordName}}</span>
              </div>
              <div class="bg-exculde d-flex align-items-center px-2 rounded-3 me-2 py-1 cursor-not-allowed"
                (click)="getBorder('ignored',i)" *ngIf="data.isDisabled">
                <div class="d-flex align-items-center justify-content-center person-dash-box">
                  <i class="bi bi-person-dash-fill"></i>
                </div>
                <span class="me-1 ms-1"  >{{data.keywordName}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="d-flex mt-4 justify-content-end">
    <button type="button" class="btn btn-outline-primary me-1 px-5" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary ms-1 px-5" (click)="onApplyChnages()">Apply</button>
  </div>
</div>

<!-- Brief Setting -->
<div class="p-4 modal-scroll overflow-hidden" *ngIf="title === 'BriefSetting'" style="width: 944px;">
  <div class="position-relative heading-border pb-3">
    <h1 class="fw-bold m-0 heading">Topic, question & notes setting</h1>
    <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
        class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
  </div>
  <div class="overflow-content">
    <!-- <div class="d-flex align-items-center justify-content-between my-3">
      <h2 class="m-0 fw-bold">Topic & question list</h2>
      <div class="d-flex align-items-center justify-content-end">
        <label class="me-2">View</label>
        <mat-form-field appearance="outline" [ngClass]="theme ? '' : 'dark-input'" style="width: 150px !important;">
          <mat-select value="All" panelClass="{{selectMenu}}">
            <mat-option value="All">All</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->
    <!-- <div class="border rounded-3 p-3 border-2">
      <table class="table hover ">
        <thead>
          <tr class="table-content">
            <th scope="col" class="fw-normal py-3">
              <input class="form-check-input" type="checkbox" (change)="checkAll('Topic & question list')"
                [(ngModel)]="masterSelected" role="button">
            </th>
            <th scope="col" class="fw-bold fs-14 text-gray py-3">Topic and Question</th>
            <th scope="col" class="fw-bold fs-14 text-gray py-3">Type</th>
          </tr>
        </thead>
        <tbody class="align-middle">
          <tr *ngFor="let data of paginateData" [ngClass]="{'checkbox-checked-color' : data.isSelected}">
            <td class="py-3">
              <input class="form-check-input" type="checkbox" role="button" [(ngModel)]="data.isSelected"
                (change)="isAllSelected('Topic & question list')">
            </td>
            <td class="fs-16 fw-bold data-content py-3">{{data.title}}</td>
            <td class="fw-normal fs-14 py-3">
              <div class="text-orange" [ngClass]="{'text-orange' : data.type ==='Competitors',
                              'text-light-green' : data.type==='Strell',
                              'text-purple' : data.type==='People also ask'}">
                {{data.type}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between align-items-center ">
        <div>
          <h3 class="fs-14 fw-normal data-number">Show {{paginateData.length>0 ? totalData : 0}} of
            {{paginateData.length>0 ?
            topicAndQuestionList.length :
            0}} items</h3>
        </div>
        <div [ngClass]="theme ? '': 'dark-pagination' ">
          <ngb-pagination [collectionSize]="paginateData.length>0 ? topicAndQuestionList.length : 0" [maxSize]="3"
            [(page)]="page" [pageSize]="tableSize" [rotate]="true" [boundaryLinks]="true"
            (pageChange)="onTableDataChange()"></ngb-pagination>
        </div>
      </div>
    </div> -->
    <!-- <div class="mt-3">
      <h3 class="m-0 fw-bold mb-2">Custom topic</h3>
      <div class="border rounded-3 p-3">
        <table class="table hover dark-table">
          <thead>
            <tr class="table-content">
              <th scope="col" class="fw-normal">
                <input class="form-check-input" type="checkbox" role="button" (change)="checkAll('Custom topic')"
                  [(ngModel)]="masterSelectedCustomTopic">
              </th>
              <th scope="col" class="fw-bold fs-14">Custom topic</th>
              <th scope="col" class="fw-bold fs-14">Type</th>
            </tr>
          </thead>
          <tbody class="align-middle">
            <tr>
              <td class="border-0">
                <input class="form-check-input checkbox" (change)="isAllSelected('Custom topic')" type="checkbox"
                  role="button">
              </td>
              <td class="border-0">
                <input type="text" class="input w-100"
                  value="She was the type of girl who wanted to live in a luxury house?"
                  placeholder="Enter new topic ...">
              </td>
              <td class="fs-14 fw-normal border-0 data-number">Custom</td>
            </tr>
            <tr>
              <td class="border-0">
                <input class="form-check-input checkbox" type="checkbox" (change)="isAllSelected('Custom topic')"
                  role="button">
              </td>
              <td class="border-0">
                <input type="text" class="input w-100" placeholder="Enter new topic ...">
              </td>
              <td class="fs-14 fw-normal border-0 data-number">Custom</td>
            </tr>
            <tr>
              <td class="border-0">
                <input class="form-check-input checkbox" type="checkbox" (change)="isAllSelected('Custom topic')"
                  role="button">
              </td>
              <td class="border-0">
                <input type="text" class="input w-100" placeholder="Enter new topic ...">
              </td>
              <td class="fs-14 fw-normal border-0 data-number">Custom</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div class="mt-3">
      <h3 class="m-0 fw-bold mb-2 heading">Notes</h3>
      <div class="editor ">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [ngClass]="theme ? '': 'dark-editor' ">
        </ngx-editor-menu>
        <ngx-editor [editor]="editor" [(ngModel)]="htmlContent" [placeholder]="'Leave your note ...'"
          [formControl]="editorContent" class="dark-txt-editor">
        </ngx-editor>
      </div>
    </div>
  </div>
  <div class="d-flex mt-4 justify-content-end">
    <button type="button" class="btn btn-outline-primary me-1 px-5" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary ms-1 px-5" (click)="applyEditor()" [disabled]="!uid">Apply</button>
  </div>
</div>

<!-- Rename Tag -->
<div *ngIf="title==='RenameTag'">
  <div class="p-4">
    <div class="position-relative">
      <h1 class="text-center fw-bold heading">Rename a tag</h1>
      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>
    <div class="position-relative">
      <input type="text" [formControl]="renameTag" class="input w-100 pe-36" placeholder="Enter list name">
      <i class="bi bi-x-circle-fill clear-icon" (click)="this.renameTag.setValue('')" *ngIf="renameTag.value !== ''"
        role="button" style="-webkit-text-stroke:0"></i>
    </div>
    <div class="d-flex mt-4">
      <button type="button" class="btn btn-outline-primary me-1 w-50" (click)="closeModal()">Cancel</button>
      <button type="button" class="btn btn-primary ms-1 w-50" [disabled]="renameTag.value == ''"
        (click)="renameTagData()">Rename</button>
    </div>
  </div>
</div>

<!-- Add Tag -->
<div *ngIf="title==='AddTag'">
  <div class="p-4">
    <div class="position-relative">
      <h1 class="text-center fw-bold heading">Create a tag</h1>
      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>
    <div class="position-relative">
      <input type="text" [formControl]="addTag" class="input w-100 pe-36" placeholder="Enter tag name"
        style="background: transparent;">
      <i class="bi bi-x-circle-fill clear-icon" (click)="this.addTag.setValue('')" *ngIf="addTag.value !== ''"
        role="button" style="-webkit-text-stroke: 0px;"></i>
    </div>
    <div class="d-flex mt-4">
      <button type="button" class="btn btn-outline-primary me-1 w-50" (click)="closeModal()">Cancel</button>
      <button type="button" class="btn btn-primary ms-1 w-50" [disabled]="addTag.value == ''"
        (click)="createTag()">Create</button>
    </div>
  </div>
</div>

<!-- Main Content Keyword -->
<div *ngIf="title==='MainContentKeyword'" class="modal-scroll overflow-hidden" style="width: 944px;">
  <div class="p-4">
    <div class="position-relative border-bottom pb-3">
      <h1 class="fw-bold m-0 heading">Examples</h1>
      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>
    <div class="d-flex align-items-center my-2">
      <h2 class="m-0 light-content">{{examples.keyword}}</h2>
      <span class="chip ms-2 bg-red" *ngIf="!!examples?.isH">Heading</span>
      <span class="chip ms-2 bg-green" *ngIf="!!examples?.isTop3">Top3</span>
      <span class="chip ms-2 bg-blue" *ngIf="!!examples?.isTitle">Title</span>
    </div>
    <div class="pt-3 overflow-content">
      <div class="" *ngFor="let data of examples.example">
        <div class="example-card mt-4 bg-light-black">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <p class="fs-16 m-0 fw-500 lh-24" [innerHtml]="data.text"></p>
            <h3 class="text-green m-0 fw-bold text-capitalize">{{data.tag}}</h3>
          </div>
          <div class="link">
            <a href="{{data.url}}" class="text-decoration-none fs-16" target="_blank">
              {{data.url}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--H2 Tag Keyword-->
<div *ngIf="title==='H2TagKeyword'" class="modal-scroll overflow-hidden" style="width: 944px;">
  <div class="p-4">
    <div class="position-relative border-bottom pb-3">
      <h1 class="fw-bold m-0 heading">Examples</h1>
      <h3 class="m-0 fs-14 fw-500 light-content">Keyword <span
          class="text-primary fs-14 fw-bold">{{examples.keyword}}</span></h3>
      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>

    <div class="overflow-content">
      <div class="" *ngFor="let data of examples.example">
        <div class="example-card mt-4 bg-light-black">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <p class="fs-16 m-0 fw-500 lh-24" [innerHtml]="data.text"></p>
            <h3 class="text-green m-0 fw-bold text-capitalize">{{data.tag}}</h3>
          </div>
          <div class="link">
            <a href="{{data.url}}" target="_blank" class="text-decoration-none fs-16">
              {{data.url}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--H1 Tag Keyword-->
<div *ngIf="title==='H1TagKeyword'" class="modal-scroll overflow-hidden" style="width: 944px;">
  <div class="p-4">
    <div class="position-relative border-bottom pb-3">
      <h1 class="fw-bold m-0 heading">Examples</h1>
      <h3 class="m-0 fs-14 fw-500 light-content">Keyword <span
          class="text-primary fs-14 fw-bold">{{examples.keyword}}</span></h3>
      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>

    <div class="overflow-content">
      <div class="" *ngFor="let data of examples.example">
        <div class="example-card mt-4 bg-light-black">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <p class="fs-16 m-0 fw-500 lh-24" [innerHtml]="data.text"></p>
            <h3 class="text-green m-0 fw-bold text-capitalize">{{data.tag}}</h3>
          </div>
          <div class="link">
            <a href="{{data.url}}" target="_blank" class="text-decoration-none fs-16">
              {{data.url}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Title tag keywords-->
<div *ngIf="title==='titletagkeyword'" class="modal-scroll overflow-hidden" style="width: 944px;">
  <div class="p-4">
    <div class="position-relative border-bottom pb-3">
      <h1 class="fw-bold m-0 heading">Examples</h1>
      <h3 class="m-0 fs-14 fw-500 light-content">Keyword <span
          class="text-primary fs-14 fw-bold">{{examples.keyword}}</span></h3>

      <button type="button" class="position-absolute top-0 end-0 btn-close" aria-label="Close" (click)="closeModal()"><i
          class="bi bi-x-lg fw-bold text-white" *ngIf="!theme"></i></button>
    </div>

    <!-- <div class="pt-3 overflow-content">
      <div class="mb-3" *ngFor="let subData of examples.example">
        <p class="mb-1">
          {{subData.text[0]}}
        </p>
        <a target="_blank" href="{{subData.url}}" class="text-decoration-none">{{subData.url}}</a>
      </div>
    </div> -->
    <div class="overflow-content">
      <div class="" *ngFor="let data of examples.example">
        <div class="example-card mt-4 bg-light-black">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <p class="fs-16 m-0 fw-500 lh-24" [innerHtml]="data.text[0]"></p>
            <h3 class="text-green m-0 fw-bold text-capitalize">{{data.tag}}</h3>
          </div>
          <div class="link">
            <a href="{{data.url}}" target="_blank" class="text-decoration-none fs-16">
              {{data.url}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>