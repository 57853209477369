<div>
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar itemscope itemtype="http://schema.org/SiteNavigationElement">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="uk-navbar-left uk-margin-medium-left">
                <a class="uk-navbar-item uk-logo" href="https://strell.io" alt="strell">
                    <img src="../../assets/images/strellnew.png" alt="strell.io" uk-img height="40px">
                </a>
            </div>
            <div class="d-flex align-items-center mx-4">
                <!-- <span class="uk-float-right uk-margin-right uk-margin-top close" uk-toggle="target: .st-side-nav; animation: uk-animation-slide-left; duration: 300;" uk-icon="icon: close; ratio: 1.5;"></span>
                <a class="first-child" href="">Home</a> -->
                <!-- <a href="">Pricing</a> -->
                <!-- <a href="">News</a> -->
                <a href="https://app.strell.io/login/"><span class="uk-button">Login</span></a>
                <a href="https://app.strell.io/register/"><span class="uk-button uk-button-primary">Sign Up</span></a>
                <!-- <span class="copy">Copyright &copy; 2023</span>
                <span class="copy">Version - v1.0.2</span> -->
            </div>
        </div>
    </nav>
</div>


<div class="uk-flex-center" uk-grid>
    <div class="uk-width-2-5@m">
        <div class="st-login-container uk-margin-large-top uk-margin-large-bottom">
            <div class="d-flex mb-5 justify-content-center">
                <img src="../../assets/Strell Logo/appsumo-logo-150x37.png" alt="">
                <span class="border border-right border-dark"></span>
                <img class="ms-2" src="../../../assets/images/strellnew.png" height="40" width="90" alt="">
            </div>
            <form [formGroup]="registerForm">
                <p>Register with Strell</p>
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-2@m">
                        <input class="uk-input" type="text" placeholder="First Name" formControlName="firstName" name="firstName" >
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['firstName']?.errors?.required">
                        First name is required.
                    </span>
                    </div>
                    <div class="uk-width-1-2@m">
                        <input class="uk-input" type="text" placeholder="Last Name" formControlName="lastName">
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['lastName']?.errors?.required">
                        Last name is required.
                    </span>
                    </div>
                </div>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: mail"></span>
                        <input class="uk-input" type="email" placeholder="Email Address" formControlName="email">
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['email']?.errors?.required">
                        Email is required.
                    </span>
                    <span class="text-danger" *ngIf="isSubmitted && registerForm?.controls['email']?.errors?.email">Email must be a valid email address
                    </span>
                    </div>
                </div>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" placeholder="Password" formControlName="password">
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['password']?.errors?.required">
                        Password is required.
                    </span>
                    </div>
                </div>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="password" placeholder="Confirm Password" formControlName="confirmPassword">
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['confirmPassword']?.errors?.required && registerForm?.controls['confirmPassword']?.value ===''">
                        Confirm password is required.
                    </span>
                    <span class="text-danger"
                    *ngIf="registerForm?.hasError('notSame') && registerForm?.controls['confirmPassword']?.value !=''">
                    Password and confirm password should be same.
                </span>
                    </div>
                </div>
                <div class="uk-margin">
                    <div class="uk-inline">
                        <span class="uk-form-icon" uk-icon="icon: lock"></span>
                        <input class="uk-input" type="text" placeholder="Code" formControlName="sumoCode">
                        <span class="text-danger"
                        *ngIf="isSubmitted && registerForm?.controls['sumoCode']?.errors?.required">
                        SumoCode is required.
                    </span>
                    </div>
                </div>
                <button  (click)="submitForm()" class="uk-button uk-button-primary">Register Now <span uk-icon="arrow-right"></span></button>
            </form>
            <!-- <div class="uk-margin-medium-top">
                <hr>
            </div> -->

        </div>
    </div>
</div>