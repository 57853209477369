import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart, { Tooltip } from 'chart.js/auto';
import { Subject, interval, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-gauge',
  templateUrl: './custom-gauge.component.html',
  styleUrls: ['./custom-gauge.component.scss']
})
export class CustomGaugeComponent implements OnInit, OnChanges, OnDestroy {
  // score =0
  progressChart: any
  dataSetValues = [10, 23, 33, 33]
  dataSetColors = ["rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)"]
  gradient: any;
  @Input() scoreInput: any;
  @Input() theme: any;
  score: any = 0;
  oldScore: number = 0;
  newScore: number = 0;
  count: any = 0;
  ctx: any;
  @ViewChild('mychart') mychart: any;
  canvas: any;
  public myChart!: Chart

  constructor() { }

  ngOnInit(): void {
    this.newScore = Math.round(this.scoreInput);
    // this.score = Math.round(this.score);
    // this.initChart();
    this.progressChange();
    this.dataSetValues = [0, 0, 0, 0]
    setTimeout(() => {
      this.progressChart.update();
    }, 3000);

  }
  ngOnDestroy() {
    this.progressChart?.destroy();
  }
  ngOnChanges() {
    if (!!this.count) {
      clearInterval(this.count);
    }
    this.scoreInput = Math.round(this.scoreInput)
    this.count = setInterval((e: any) => {
      if (this.score < this.scoreInput) {
        this.score++
        this.progressChange();
      } else if (this.score > this.scoreInput) {
        this.score--
        this.progressChange();
      } else if (this.score == this.scoreInput) {
        clearInterval(this.count);
      }
    }, 50)
    this.progressChange();
    this.initChart();
    this.dataSetValues = [0, 0, 0, 0]
    setTimeout(() => {
      this.progressChart?.update()
    }, 3000);
  }
  initChart() {
    var a = <HTMLCanvasElement>document.getElementById("myChart");
    let a2d = a.getContext("2d")
    let gradient = a2d!.createLinearGradient(0, 0, 250, 0);
    gradient.addColorStop(0.05, '#F2265A');
    gradient.addColorStop(0.50, '#8644D4');
    gradient.addColorStop(0.75, '#5271DD');
    gradient.addColorStop(0.95, '#30B28C');
    this.gradient = gradient;
    // this.
    let colorName = this.theme ? '#f7f8fa' : '#111112';
    let needleColor = this.theme ? 'black' : 'white';
    let innerColor = this.theme ? 'white' : "#131314";
    let shadowColorColor = this.theme ? 'grey' : 'transparent';
    let lineColor = this.theme ? '#e6e7eb' : 'rgba(125, 126, 128, 0.15)';


    const gaugeNeedle = {
      id: "gaugeNeedle",
      arguments: this.score==0 ? this.score = 1 : this.score,
      beforeDatasetsDraw(chart: any, args: any, plugins: any) {
        const { ctx, data } = chart;
        ctx.save()
        const xCenter = chart.getDatasetMeta(0).data[0].x
        const yCenter = chart.getDatasetMeta(0).data[0].y
        const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius
        const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius
        const radius = 52;
        const angle = Math.PI / 270;
        let needleValue = 0;
        chart.config._config.data.datasets[0].backgroundColor.forEach((e: string, index: number) => {
          if (e !== "rgba(221, 222, 228, 0)") {
            needleValue = needleValue + chart.config._config.data.datasets[0].data[index]
          }
        })
        const labelProp = needleValue <= 33 ? { text: "Low", color: "#F2265A", textAlign: -4 } : needleValue <= 66 ? { text: "Good", color: "#5271DD", textAlign: -4 } : { text: "Excellent   ", color: "#30B28C", textAlign: 6 }
        const dataTotal = 100
        let circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue
        if (Number.isNaN(circumference)) {
          circumference = 0;
        }
        ctx.translate(xCenter, yCenter)

        // For drawing main transparent circle

        ctx.strokeStyle = colorName;
        ctx.lineWidth = 2
        ctx.fillStyle = "rgba(221, 222, 228, 0)"
        ctx.beginPath();
        ctx.arc(0, 0, outerRadius - 2, 0 * Math.PI, 2 * Math.PI)
        ctx.fill()
        ctx.stroke()

        // For drawing main gray 270

        ctx.strokeStyle =colorName,
        ctx.lineWidth = innerRadius
        ctx.fillStyle = "#f7f8fa"
        ctx.beginPath();
        ctx.arc(0, 0, radius + 10, 0.75 * Math.PI, 0.25 * Math.PI)
        ctx.fill()
        ctx.stroke()


        // For drawing needle
        ctx.rotate(Math.PI * (circumference + 1.245))
        ctx.lineWidth = "1"
        ctx.strokeStyle = needleColor;
        ctx.fillStyle = needleColor;
        ctx.beginPath();
        ctx.moveTo(0 - 40, 0)
        ctx.lineTo(0, 0 - innerRadius + 14)
        ctx.lineTo(0 + 40, 0)
        ctx.stroke()
        ctx.fill()



        // For drawing inner white
        ctx.shadowColor = shadowColorColor;
        ctx.shadowBlur = 30;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.lineWidth = 2
        ctx.strokeStyle = "grey"
        ctx.beginPath();
        ctx.fillStyle = innerColor;
        ctx.arc(0, 0, radius + 20, 0, 360, false)
        ctx.fill()

        // For drawing white circle 2 for gray border
        ctx.shadowBlur = 0;
        ctx.lineWidth = 2
        ctx.strokeStyle = lineColor
        ctx.beginPath();
        ctx.fillStyle = innerColor;
        ctx.arc(0, 0, radius + 20, 0, 360, false)
        ctx.stroke()
        ctx.fill()

        ctx.shadowBlur = 0;
        ctx.lineWidth = 2
        ctx.strokeStyle = lineColor
        ctx.beginPath();
        ctx.fillStyle = innerColor;
        ctx.arc(0, 0, radius + 10, 0, 360, false)
        ctx.stroke()
        ctx.fill()

        // For drawing text
        ctx.beginPath();
        ctx.strokeStyle = needleColor
        ctx.rotate(-(Math.PI * (circumference + 1.245)))
        ctx.fillStyle = needleColor
        ctx.textAlign = "center";
        ctx.font = "italic 900 40px Lato";
        ctx.fillText(needleValue, -4, -15);

        ctx.beginPath();
        ctx.strokeStyle = needleColor
        ctx.fillStyle = labelProp.color
        // ctx.textAlign = "center";
        ctx.font = "italic 800 25px Lato";
        ctx.fillText(labelProp.text, labelProp.textAlign, 20);
        ctx.restore()
      },
      afterDatasetsDraw(chart: any, args: any, plugins: any) {
        const { ctx, data } = chart;
        ctx.save()
        const xCenter = chart.getDatasetMeta(0).data[0].x
        const yCenter = chart.getDatasetMeta(0).data[0].y
        const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius
        const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius
        const radius = 52;
        const angle = Math.PI / 270;
        let needleValue = 0;
        let backgroundColor = ["#dce1e9", "#dce1e9", "#dce1e9", "#dce1e9"]
        chart.config._config.data.datasets[0].backgroundColor.forEach((e: string, index: number) => {
          if (e !== "rgba(221, 222, 228, 0)") {
            needleValue = needleValue + chart.config._config.data.datasets[0].data[index]
          }
        })
        let circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue
        if (Number.isNaN(circumference)) {
          circumference = 0;
        }
        ctx.translate(xCenter, yCenter)

        // For drawing outer white

        let gradient2 = ctx!.createLinearGradient(0, 0, 250, 0);
        if (needleValue < 33) {
          gradient2.addColorStop(needleValue / 100, '#F2265A');
          // backgroundColor = ["#F2265A","#dce1e9","#dce1e9","#dce1e9"]
        } else if (needleValue < 49) {
          gradient2.addColorStop(needleValue / 100, '#8644D4');
          // backgroundColor = ["#F2265A","#8644D4","#dce1e9","#dce1e9"]
        } else if (needleValue < 66) {
          gradient2.addColorStop(needleValue / 100, '#5271DD');
          // backgroundColor = ["#F2265A","#8644D4","#5271DD","#dce1e9"]
        } else {
          gradient2.addColorStop(needleValue / 100, '#30B28C');
          // backgroundColor = ["#F2265A","#8644D4","#5271DD","#30B28C"]
        }

        if (needleValue >= 0) {
          backgroundColor = ["#F2265A", "#dce1e9", "#dce1e9", "#dce1e9"]
        }
        if (needleValue > 33) {
          backgroundColor = ["#F2265A", "#8644D4", "#dce1e9", "#dce1e9"]
        }
        if (needleValue > 66) {
          backgroundColor = ["#F2265A", "#8644D4", "#5271DD", "#dce1e9"]
        }
        if (needleValue == 100) {
          backgroundColor = ["#F2265A", "#8644D4", "#5271DD", "#30B28C"]
        }



        ctx.lineWidth = 2
        ctx.beginPath();
        ctx.rotate((Math.PI * (circumference + 1.245)))
        ctx.fillStyle = gradient2
        ctx.moveTo(0, 0 - innerRadius + 16)
        ctx.arc(0, 0 - outerRadius + 3, 10, 0, 360, false)
        ctx.fill()


        // For drawing outer white
        ctx.lineWidth = 2
        ctx.strokeStyle = "white"
        ctx.beginPath();
        // ctx.rotate((Math.PI * (circumference + 1.245)))
        ctx.fillStyle = "rgba(221, 222, 228, 0)"
        ctx.arc(0, 0 - outerRadius + 3, 5, 0, 360, false)
        ctx.stroke()

        // For drawing 00
        ctx.beginPath();
        ctx.strokeStyle = backgroundColor[0]
        ctx.rotate(-(Math.PI * (circumference)))
        ctx.fillStyle = backgroundColor[0]
        ctx.textAlign = "center";
        ctx.font = "italic 900 17px Lato";
        ctx.fillText("00", 0, 0 - outerRadius - 22);
        ctx.rotate((Math.PI * (circumference)))

        // For drawing 33
        ctx.beginPath();
        ctx.strokeStyle = backgroundColor[1]
        ctx.rotate(-(Math.PI * (circumference + 1.5)))
        ctx.fillStyle = backgroundColor[1]
        ctx.textAlign = "center";
        ctx.font = "italic 900 17px Lato";
        ctx.fillText("33", 0, 0 - outerRadius - 22);
        ctx.rotate((Math.PI * (circumference + 1.5)))

        // For drawing 66
        ctx.beginPath();
        ctx.strokeStyle = backgroundColor[2]
        ctx.rotate(-(Math.PI * (circumference + 1)))
        ctx.fillStyle = backgroundColor[2]
        ctx.textAlign = "center";
        ctx.font = "italic 900 17px Lato";
        ctx.fillText("66", 0, 0 - outerRadius - 22);
        ctx.rotate((Math.PI * (circumference + 1)))

        // For drawing 100
        ctx.beginPath();
        ctx.strokeStyle = backgroundColor[3]
        ctx.rotate(-(Math.PI * (circumference + 0.5)))
        ctx.fillStyle = backgroundColor[3]
        ctx.textAlign = "center";
        ctx.font = "italic 900 17px Lato";
        ctx.fillText("100", 0, 0 - outerRadius - 22);
        ctx.rotate((Math.PI * (circumference + 0.5)))

        ctx.restore()
      }
    }
    this.progressChart?.destroy()//destroy prev chart instance
    this.progressChart = new Chart(a as any, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.dataSetValues,
            backgroundColor: this.dataSetColors,
            borderColor: this.dataSetColors,
            borderWidth: 1,
            // borderRadius:10,
            spacing: 5,
            circumference: 270,
            rotation: 225,
            borderRadius(ctx, options) {
              if (ctx.dataIndex == 0) {
                return {
                  outerStart: 20,
                  outerEnd: 0,
                  innerStart: 20,
                  innerEnd: 0,
                }
              }
              else if (ctx.dataIndex == 2) {
                return {
                  outerStart: 0,
                  outerEnd: 20,
                  innerStart: 0,
                  innerEnd: 20,
                }
              }
              else {
                return {
                  outerStart: 0,
                  outerEnd: 0,
                  innerStart: 0,
                  innerEnd: 0,
                }
              }

            },
          }],
      },
      options: {
        cutout: "90%",
        responsive: false,
        layout: {
          padding: {
            top: -50,
            bottom: 30,
            left: 40,
            right: 30
          }

        },
        aspectRatio:1.3,
        animation: false,
      },
      
      plugins: [
        gaugeNeedle,
      ]
    });
    Chart.defaults.plugins.tooltip.enabled = false;

    // progressChart.data.datasets
    // progressChart.update()
  }
  async calculateDataset(arg?: number) {
    if (this.score <= 33) {
      this.dataSetValues.length = 0;
      [this.score, (33 - this.score), 33, 33].forEach(e => {
        this.dataSetValues.push(e)
      })

      this.dataSetColors.length = 0;
      [this.gradient, "rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)"].forEach(e => {
        this.dataSetColors.push(e)
      })
    } else if (this.score <= 66) {
      this.dataSetValues.length = 0;
      [33, (this.score - 33), (66 - this.score), 33].forEach(e => {
        this.dataSetValues.push(e)
      })
      this.dataSetColors.length = 0;
      [this.gradient, this.gradient, "rgba(221, 222, 228, 0)", "rgba(221, 222, 228, 0)"].forEach(e => {
        this.dataSetColors.push(e)
      })
    } else if (this.score <= 100) {
      this.dataSetValues.length = 0;
      [33, 33, (this.score - 66), (100 - this.score)].forEach(e => {
        this.dataSetValues.push(e)
      })
      this.dataSetColors.length = 0;
      [this.gradient, this.gradient, this.gradient, "rgba(221, 222, 228, 0)"].forEach(e => {
        this.dataSetColors.push(e)
      })
    }
  }

  async progressChange() {
    await this.progressChart?.destroy()
    // this.count = 0;
    // this.isLoading = true
    this.calculateDataset()
    // await this.progressChart.update()
    // this.isLoading = false

    this.initChart()
  }

}
