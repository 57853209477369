import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators as Validator } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Editor, Toolbar, Validators } from 'ngx-editor';
import { KeyWord } from 'src/app/shared/classes/constants';
import { CompetitorSettings } from '../classes/competitor-settings';
import { ThemingService } from '../services/theming.service';
import { ToastrService } from 'ngx-toastr';
import { EditorService } from '../services/editor.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() public title?: string;
  @Input() public keyword?: any;

  @Input() public index?: number;
  @Input() public data?: string;
  @Input() public theme?: boolean;
  @Input() public competitorList?: any;
  @Input() public defaultCompetitor?: any;
  @Input() public competitors?: any;
  @Input() public termsSettings?: any;
  @Input() public termsSettingsBackup?: any;
  @Input() public structureSettings?: any;
  @Input() public note?: any;
  @Input() public resetSettings?: any;

  @Input() public examples?: any;
  @Output() emitService = new EventEmitter();
  htmlContent: any = "";
  get selectMenu() {
    return !this.theme ? 'testClass' : ''
  }

  addUrl = new FormControl('', [Validator.required, Validator.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]);
  renameTag = new FormControl('');
  addTag = new FormControl('');
  listNames: CompetitorSettings[] = [];
  isCreateContentMinimize: boolean = false;
  submitted: boolean = false;
  states = [true, false];
  editor!: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  editorContent = new FormControl(
    { value: '', disabled: false },
    Validators.required()
  )

  newKeyword = "";
  selectedKeyWord: any[] = []
  selectedType = "";
  defaultValue = {min:0,max:0};
  h2Value = {min:0,max:0};
  h3Value = {min:0,max:0};
  h46Value = {min:0,max:0};
  imageValue = {min:0,max:0};
  paragraphValue = {min:0,max:0};
  topicAndQuestionList: any[] = [];
  paginateData: any[] = [];
  tableSize: number = 10;
  page = 1;
  count: number = 4;
  totalData: string = "0";
  masterSelected: boolean = false;
  masterSelectedCustomTopic: boolean = false;
  public event: EventEmitter<any> = new EventEmitter();
  uid:string = "";
  isIncludeEmpty:boolean = false;
  isExcludeEmpty:boolean = false;
  isIgnoreEmpty:boolean = false;

  // @ViewChild(DashboardComponent) _child: any; 
  constructor(
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
    private themingService: ThemingService,
    private editorService: EditorService,
    private toastr: ToastrService,
    private cookieService:CookieService
  ) {
    this.themingService.getThemeName().subscribe((res: any) => {
      this.theme = res
    })
    // this.themingService.nameKeeper.subscribe(val => this.theme = val);
  }

  ngOnInit(): void {
    this.defaultValue.min = this.structureSettings?.body?.min;
    this.paragraphValue.min = this.structureSettings?.paragraph?.min;
    this.imageValue.min = this.structureSettings?.images?.min;
    this.h2Value.min = this.structureSettings?.h2?.min;
    this.h3Value.min = this.structureSettings?.h3?.min;
    this.h46Value.min = this.structureSettings?.h46?.min;
    this.defaultValue.max = this.structureSettings?.body?.max;
    this.paragraphValue.max = this.structureSettings?.paragraph?.max;
    this.imageValue.max = this.structureSettings?.images?.max;
    this.h2Value.max = this.structureSettings?.h2?.max;
    this.h3Value.max = this.structureSettings?.h3?.max;
    this.h46Value.max = this.structureSettings?.h46?.max;
    this.uid = this.cookieService.get('uid')
    if (this.title === 'BriefSetting') {
      this.htmlContent = this.note;
    }
    if (this.title === 'Example') {
      this.keyword.example.forEach((e: any) => {
        e.text = e.text.replaceAll(this.keyword.keyword, "<mark>" + this.keyword.keyword + "</mark>");
        if (e.tag === "paragraph") {
          e.tag = "p"
        } else if (e.tag === "title") {
          e.tag = "t"
        }
      });
    }
    if (this.title === 'titletagkeyword') {
      this.examples.example.forEach((e: any) => {
        e.text[0] = e.text[0].toLowerCase().replaceAll(this.examples.keyword.toLowerCase(), "<mark>" + this.examples.keyword.toLowerCase() + "</mark>");
        if (e.tag === "paragraph") {
          e.tag = "p"
        } else if (e.tag === "title") {
          e.tag = "t"
        }
      });
    }
    if (this.title === 'H1TagKeyword' || this.title === 'H2TagKeyword' || this.title === 'MainContentKeyword') {
      this.examples.example.forEach((e: any) => {
        e.text = e.text.toLowerCase().replaceAll(this.examples.keyword.toLowerCase(), "<mark>" + this.examples.keyword.toLowerCase() + "</mark>");
      });
    }
    // if (this.title === 'H2TagKeyword') {
    //   this.examples.example = this.examples.example.splice(0, 2);
    //   this.examples.example.forEach((e: any) => {
    //     e.text = e.text.toLowerCase().replaceAll(this.examples.keyword.toLowerCase(), "<mark>" + this.examples.keyword.toLowerCase() + "</mark>");
    //   });
    // }
    this.tableData();
    this.onTableDataChange();
    this.editor = new Editor();
    this.renameTag.patchValue(this.data || '');
    let arr: any = [];
    this.competitorList = Object.values(this.competitorList).forEach((el: any) => {
      if (!!this.competitors.includes(el.position.toString())) {
        el['isChecked'] = true;

      } else {
        el['isChecked'] = false;
      }
      arr.push(el);
    });
    this.competitorList = arr;
    // this.termsSettings.forEach((el: any) => {
    //   el['type'] = "included";
    // })
   this.checkStatus();
  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }
  getLinkPreviewDatas() {
    //   getLinkPreview("https://www.youtube.com/watch?v=MejbOFk7H6c",{
    //     imagesPropertyType: "og", // fetches only open-graph images
    // headers: {
    //   "user-agent": "googlebot", // fetches with googlebot crawler user agent
    //   "Accept-Language": "fr-CA", // fetches site for French language
    //   // ...other optional HTTP request headers
    // },
    // timeout: 1000
    //   }).then((data:any) =>
    //   console.debug(data)
    // );
  }



  closeModal() {
    this.modal.dismissAll();
    this.topicAndQuestionList.forEach(e => e.isSelected = false)
  }

  onApply(arg: string) {
    switch (arg) {
      case 'url':
        this.submitted = true;
        this.activeModal.close(this.addUrl);
        break;
      case 'hardRefresh':
        this.activeModal.close(this.addUrl);
        break;

      default:
        break;
    }
  }
  resetAll() {
    this.termsSettings = this.termsSettingsBackup;
  }


  tableData() {
    let name = KeyWord.nameData;
    for (let i = 0; i < 20; i++) {
      let data = {
        "competitorTitle": 'Why choose a theme that looks good with WooCommerce',
        "competitorDescrption": 'Lorem ipsum dolor sit amet consectetur. Fames dui erat duis nisl. Sit lobortis velit aliquam dui habitasse celsiu duilas kui',
        "competitorLink": 'https://www.myretail.com/products/smart-chasy-honor-phone',
        "contentScore": Math.floor(Math.random() * 100),
        "word": Math.floor(Math.random() * 9999),
        "state": this.states[Math.floor(Math.random() * this.states.length)]
      }
      this.listNames.push(data);
    }
    for (let i = 0; i < 111; i++) {
      KeyWord.TopicAndQuestionList.forEach(e => {
        this.topicAndQuestionList.push(e);
      })
      this.topicAndQuestionList.splice(1107, 3)
    }
  }
  onTableDataChange() {
    const lastIndex = this.page * 10;
    const firstIndex = lastIndex + 1 - 10
    const pageCount = this.topicAndQuestionList.length / this.tableSize;
    this.count = Math.ceil(pageCount);
    if (this.page == this.count) {
      this.totalData = firstIndex + "-" + this.topicAndQuestionList.length
    } else {
      this.totalData = firstIndex + "-" + lastIndex
    }

    this.paginateData = this.topicAndQuestionList
      .slice((this.page - 1) * this.tableSize, (this.page - 1) * this.tableSize + this.tableSize);
  }
  getBorder(arg: string, index: number) {
    if (arg && arg === 'included') {
      this.selectedType = "INCLUDE"
      this.termsSettings[index].isSelected = !this.termsSettings[index].isSelected
      this.termsSettings.forEach((e: any) => {
        if (e.type !== "included" && e.type) {
          e.isSelected = false;
        }
      })

    } else if (arg && arg === 'excluded') {
      this.selectedType = "EXCLUDE"
      this.termsSettings[index].isSelected = !this.termsSettings[index].isSelected
      this.termsSettings.forEach((e: any) => {
        if (e.type !== "excluded") {
          e.isSelected = false;
        }
      })
    } else {
      this.selectedType = "IGNORE"
      this.termsSettings[index].isSelected = !this.termsSettings[index].isSelected
      this.termsSettings.forEach((e: any) => {
        if (e.type !== "ignored") {
          e.isSelected = false;
        }
      })
    }
    this.checkStatus();

  }

  excludeKeyword() {
    let changedArray: any = [];
    this.termsSettings = this.termsSettings.filter((e: any, index: number) => {
      if (e.isSelected) {
        e["type"] = "excluded"
        e["isSelected"] = false;
        changedArray.push(e);
        return false;
      } else {
        return true
      }
    })
    this.termsSettings = this.termsSettings.concat(changedArray);
    this.checkStatus();

  }

  addKeyword() {
    this.termsSettings.push(
      {
        keywordName: this.newKeyword,
        type: "included",
        isSelected: false,
        isMainContent: true
      }
    )
    this.newKeyword = "";
  }

  includeKeyword() {
    let changedArray: any = [];
    this.termsSettings = this.termsSettings.filter((e: any) => {
      if (e.isSelected) {
        e["type"] = "included"
        e["isSelected"] = false;
        changedArray.push(e);
        return false;
      } else {
        return true
      }
    })
    this.termsSettings.unshift(...changedArray);
    this.checkStatus();

    // this.termsSettings = this.termsSettings.concat(changedArray);
  }
  checkStatus(){
    this.isIncludeEmpty = this.termsSettings.some((e:any)=>{return e.type==='included'})
    this.isExcludeEmpty = this.termsSettings.some((e:any)=>{return e.type==='excluded'})
    this.isIgnoreEmpty = this.termsSettings.some((e:any)=>{return e.type==='ignored'})
  }
  mainContentKeyword() {
    let checkedArr = this.termsSettings.filter((el:any)=>{return el.isSelected}).every((e:any)=>{return e.isSelected && e["isMainContent"]==true});
    this.termsSettings.forEach((e: any) => {
      if (e.isSelected) {
        // e["isMainContent"] = e.isMainContent ? !e.isMainContent : true;
        e["isMainContent"] = !!checkedArr ? false : true;
        // e["isSelected"] = false;
      }
    })
  }

  titleKeyword() {
    let checkedArr = this.termsSettings.filter((el:any)=>{return el.isSelected}).every((e:any)=>{return e.isSelected && e["isTitle"]==true});

    this.termsSettings.forEach((e: any) => {
        if (e.isSelected) {
          // e["isTitle"] = e.isTitle ? !e.isTitle : true;
          e["isTitle"] = !!checkedArr ? false : true; 

          // e["isSelected"] = false;
        }
    })
  }

  h1Keyword() {
    let checkedArr = this.termsSettings.filter((el:any)=>{return el.isSelected}).every((e:any)=>{return e.isSelected && e["isH1"]==true});
    this.termsSettings.forEach((e: any) => {
      if (e.isSelected) {
        // e["isH1"] = e.isH1 ? !e.isH1 : true;
        e["isH1"]  = !!checkedArr ? false : true; 
        // e["isSelected"] = false;
      }
    })
  }

  h2Keyword() {
    let checkedArr = this.termsSettings.filter((el:any)=>{return el.isSelected}).every((e:any)=>{return e.isSelected && e["isH2"]==true});
    this.termsSettings.forEach((e: any) => {
      if (e.isSelected) {
        e["isH2"]  = !!checkedArr ? false : true; 
        // e["isH2"] = e.isH2 ? !e.isH2 : true;
        // e["isSelected"] = false;
      }
    })
  }

  ignoreKeyword() {
    let changedArray: any = [];
    this.termsSettings = this.termsSettings.filter((e: any) => {
      if (e.isSelected) {
        e["type"] = "ignored";
        e["isSelected"] = false;
        changedArray.push(e);
        return false;
      } else {
        return true
      }
    })
    this.termsSettings = this.termsSettings.concat(changedArray);
    this.checkStatus();

  }
  // incrementNumber(arg: string) {
  //   switch (arg) {
  //     case 'defaultValue':
  //       this.defaultValue = this.defaultValue + 1;
  //       break;
  //     case 'headingValue':
  //       this.headingValue = this.headingValue + 1;
  //       break;
  //     case 'imageValue':
  //       this.imageValue = this.imageValue + 1;
  //       break;
  //     case 'paragraphValue':
  //       this.paragraphValue = this.paragraphValue + 1;
  //       break;

  //     default:
  //       break;
  //   }
  // }
  // decrementNumber(arg: string) {
  //   switch (arg) {
  //     case 'defaultValue':
  //       if (this.defaultValue > this.structureSettings?.body?.min) {
  //         this.defaultValue = this.defaultValue - 1;
  //       }
  //       break;
  //     case 'headingValue':
  //       if (this.headingValue > this.structureSettings?.headings?.min) {
  //         this.headingValue = this.headingValue - 1;
  //       }
  //       break;
  //     case 'imageValue':
  //       if (this.imageValue > this.structureSettings?.images?.min) {
  //         this.imageValue = this.imageValue - 1;
  //       }
  //       break;
  //     case 'paragraphValue':
  //       if (this.paragraphValue > this.structureSettings?.paragraph?.min) {
  //         this.paragraphValue = this.paragraphValue - 1;
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }
  resetValue(arg: string) {
    switch (arg) {
      case 'defaultValue':
        this.defaultValue.min = this.resetSettings?.body?.min;
        this.defaultValue.max = this.resetSettings?.body?.max;
        break;
      case 'h2':
        // this.headingValue = "";
        this.h2Value.min = this.resetSettings?.h2?.min;
        this.h2Value.max = this.resetSettings?.h2?.max;
        break;
        case 'h3':
        // this.headingValue = "";
        this.h3Value.min = this.resetSettings?.h3?.min;
        this.h3Value.max = this.resetSettings?.h3?.max;
        break;
        case 'h46':
        // this.headingValue = "";
        this.h46Value.min = this.resetSettings?.h46?.min;
        this.h46Value.max = this.resetSettings?.h46?.max;
        break;
      case 'imageValue':
        // this.imageValue = "";
        this.imageValue.min = this.resetSettings?.images?.min;
        this.imageValue.max = this.resetSettings?.images?.max;
        break;
      case 'paragraphValue':
        // this.paragraphValue = "";
        this.paragraphValue.min = this.resetSettings?.paragraph?.min;
        this.paragraphValue.max = this.resetSettings?.paragraph?.max;
        break;
      default:
        break;
    }
  }
  checkAll(arg: string) {
    if (arg === "Topic & question list") {
      this.topicAndQuestionList.forEach(e => {
        e.isSelected = this.masterSelected;
      })
    } else {
      const getAllCheckBoxes: any = document.getElementsByClassName('checkbox');
      for (var i = 0; i < getAllCheckBoxes.length; i++) {
        getAllCheckBoxes[i].checked = this.masterSelectedCustomTopic
      }
    }
  }
  isAllSelected(arg: string) {
    if (arg === "Topic & question list") {
      this.masterSelected = this.topicAndQuestionList.every(e => {
        return e.isSelected === true;
      })
    } else {
      const getAllCheckBoxes: any = document.getElementsByClassName('checkbox');
      var elementsArray = [].slice.call(getAllCheckBoxes);
      this.masterSelectedCustomTopic = elementsArray.every((e: any) => e.checked === true);
    }

  }
  changeSwitch(index: number) {
    this.competitorList[index].isChecked = !this.competitorList[index].isChecked
  }
  renameTagData() {
    let obj = {
      data: this.renameTag.value,
      index: this.index
    }
    this.activeModal.close(obj);
  }
  createTag() {
    this.activeModal.close(this.addTag.value)
  }
  applyCompetitors() {
    let arr = this.competitorList.filter((e: any) => {
      return e.isChecked == true;

    }).map((el: any) => { return el.position.toString() });
    if (arr.length >= 3 && arr.length <= 20) {
      this.activeModal.close({ arr, title: "compititor modal" });

    } else {
      this.toastr.error("Please choose between 3 to 20  competitors")
    }
  }
  onApplyChnages() {
    this.emitService.next(this.termsSettings);
    this.closeModal()
  }
  applyStructure() {
    this.defaultValue = {min:Number(this.defaultValue.min),max:Number(this.defaultValue.max)},
    this.paragraphValue = {min:Number(this.paragraphValue.min),max:Number(this.paragraphValue.max)}
    this.imageValue = {min:Number(this.imageValue.min),max:Number(this.imageValue.max)}
    this.h2Value = {min:Number(this.h2Value.min),max:Number(this.h2Value.max)}
    this.h3Value = {min:Number(this.h3Value.min),max:Number(this.h3Value.max)}
    this.h46Value = {min:Number(this.h46Value.min),max:Number(this.h46Value.max)}

    if ((this.defaultValue.min > this.defaultValue.max) || ( this.paragraphValue.min > this.paragraphValue.max) || (this.imageValue.min > this.imageValue.max) || (this.h2Value.min > this.h2Value.max) || (this.h3Value.min > this.h3Value.max) || (this.h46Value.min > this.h46Value.max)) {
      this.toastr.error("min value should not be greater than max value");
    } 
    // else if(this.defaultValue.min==0 ||  this.defaultValue.max==0 || this.paragraphValue.min==0 ||  
    //   this.paragraphValue.max==0 || this.imageValue.min==0 || 
    //   this.imageValue.max==0 ||  this.h2Value.min==0 || this.h2Value.max==0 || 
    //   this.h3Value.min==0 || this.h3Value.max==0 || 
    //   this.h46Value.min==0 || this.h46Value.max==0) {
    //   this.toastr.error("Value should not be zero");
    // }
    else{
      this.activeModal.close({ body: this.defaultValue, paragraph: this.paragraphValue, image: this.imageValue, h2: this.h2Value,h3: this.h3Value,h46: this.h46Value });
    }
  }
  applyEditor() {
    // let html = this.htmlContent;
    // var oParser = new DOMParser();
    // var oDOM = oParser.parseFromString(html, "text/html");
    // var text = oDOM.body.innerText;
    this.activeModal.close(this.htmlContent)
  }
}
