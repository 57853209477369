import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorService } from '../../shared/services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appsumo-register',
  templateUrl: './appsumo-register.component.html',
  styleUrls: ['./appsumo-register.component.scss']
})
export class AppsumoRegisterComponent implements OnInit {
  uid : any = ''
    
  registerForm:FormGroup  = this.formBuilder.group({})
  isSubmitted:boolean = false;
  constructor(
      private formBuilder: FormBuilder,
      private editorServ: EditorService,
      private toster: ToastrService,
      private cookieServ: CookieService,
      private router: Router,
    ) {
    this.uid = this.cookieServ.get("uid");
    if(this.uid && localStorage.getItem('auth_token') ){
      this.router.navigate([`dashboard`])
    }

    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm(){
      this.registerForm = this.formBuilder.group({
          firstName:["",[Validators.required]],
          lastName:["",[Validators.required]],
          // email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],          
          email: ['', [Validators.required, Validators.email,Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],          
          password:["",[Validators.required]],
          confirmPassword:["",[Validators.required]],
          sumoCode:["",[Validators.required]]
      },{ validators: this.checkPasswords })
    }

    submitForm(){
      this.isSubmitted = true;
      if(this.registerForm.valid){
        let Obj = {
          "first_name": this.registerForm.controls["firstName"].value,
          "last_name": this.registerForm.controls["lastName"].value,
          "email": this.registerForm.controls["email"].value,
          "password": this.registerForm.controls["password"].value,
          "confirm_password": this.registerForm.controls["confirmPassword"].value,
          "app_sumo_code": this.registerForm.controls["sumoCode"].value,
      }
        this.editorServ.appSumoRegistration(Obj).subscribe((res:any)=>{
          try {
            if(res.success){
              this.toster.success(res.success)
              this.registerForm.reset();
            }
            if(res.error){
              this.toster.success(res.error)
            }
          } catch (error:any) {
            this.toster.error(error.message)
          }
        });
        this.isSubmitted = false;
        // API call for register
      }
    }
    checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
      let pass = group.get('password')?.value;
      let confirmPass = group.get('confirmPassword')?.value
      return pass === confirmPass ? null : { notSame: true }
    }
  }
  